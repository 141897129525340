import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect } from 'react';

export const useGeocode = (
  pos: google.maps.LatLngLiteral,
  onGetAddress?: (address: { description: string; place_id: string }) => void
) => {
  const geocode = useMapsLibrary('geocoding');

  useEffect(() => {
    if (!geocode) {
      return;
    }
    void new geocode.Geocoder().geocode({ location: pos }, (results, status) => {
      if (status === 'OK') {
        onGetAddress?.({
          description: results?.[0].formatted_address ?? '',
          place_id: results?.[0].place_id ?? '',
        });
      }
    });
  }, [geocode, onGetAddress, pos]);
};
