import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mandatUrl } from '@/apis/location';
import { useLeasingDealInfo } from '@/apis/location/hooks';
import { ArrowRight } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button, InfoCard, SepaSignature } from '@/components/ui';
import { useLocalProject } from '@/services/project';

const MandatSignature = () => {
  const { t } = useTranslation();
  const { data: localProjectData } = useLocalProject();
  const { data: locationProjectInfo } = useLeasingDealInfo(localProjectData?._id ?? '', {
    enabled: Boolean(localProjectData?._id),
  });
  const navigate = useNavigate();

  const isPaymentRequired = locationProjectInfo?.isPaymentRequired ?? true;
  const isAlreadySignedMandate = locationProjectInfo?.isAlreadySignedMandate ?? false;
  const isAlreadyPaid = locationProjectInfo?.isAlreadyPaid ?? false;

  const submit = () => {
    navigate(`/location/step-5`);
  };

  return (
    <AnimatedPage>
      <h1 className="text-xl font-semibold text-black">{t('do-warranty-deposit')}</h1>
      <p className="text-sm text-ghost">{t('do-warranty-deposit-subtitle')}</p>
      <InfoCard level="warning" border={false}>
        <p className="text-sm font-medium text-black">{t('warranty-deposit-info')}</p>
      </InfoCard>

      <SepaSignature
        signatureStatus={
          locationProjectInfo?.isAlreadySignedMandate === undefined
            ? undefined
            : locationProjectInfo?.isAlreadySignedMandate
              ? 'signed'
              : 'not-signed'
        }
        depositStatus={locationProjectInfo?.isAlreadyPaid ? 'deposited' : 'not-deposited'}
        mandatUrl={() =>
          (window.location = mandatUrl(localProjectData?._id ?? '') as unknown as Location)
        }
      />

      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={() => {
            navigate('/location/step-3');
          }}
        />
        <Button
          label={t('next-step')}
          className={'mt-4 bg-primary text-white'}
          isDisabled={
            !isAlreadySignedMandate || (isPaymentRequired ? !isAlreadyPaid : isAlreadyPaid)
          }
          onPress={() => {
            submit();
          }}
          PostIcon={(props) => <ArrowRight {...props} />}
        />
      </div>
    </AnimatedPage>
  );
};
export default MandatSignature;
