import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as enTrans from './en/index';
import * as frTrans from './fr/index';

export const resources = {
  en: enTrans,
  fr: frTrans,
};
void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    compatibilityJSON: 'v3',
    lng: 'fr', // default language to use.
    interpolation: {
      escapeValue: false,
    },
  });
