import { type SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M7.84 3.7a1.11 1.11 0 0 0-1.829.406l-3.69 10.152a1.113 1.113 0 0 0 1.037 1.492c.131 0 .261-.025.384-.07l10.151-3.692a1.111 1.111 0 0 0 .407-1.828L7.84 3.7Zm-.586 9.508-2.461-2.461.925-2.546L9.8 12.282l-2.546.925Zm-3.868 1.406.985-2.701 1.719 1.72-2.704.98Zm7.583-2.757L6.143 7.032l.914-2.522 6.428 6.429-2.516.92Zm.281-6.794c.01-.38.103-.754.27-1.096.373-.745 1.076-1.155 1.98-1.155.471 0 .774-.16.96-.506.098-.194.154-.406.165-.623a.563.563 0 0 1 1.125.004c0 .905-.599 2.25-2.25 2.25-.471 0-.773.162-.96.507a1.547 1.547 0 0 0-.165.623.563.563 0 0 1-1.125-.005Zm-1.687-2.25V1.125a.563.563 0 0 1 1.125 0v1.688a.562.562 0 1 1-1.125 0Zm7.148 5.789a.564.564 0 0 1-.614.917.564.564 0 0 1-.182-.122L14.79 8.272a.563.563 0 0 1 .796-.796l1.125 1.126Zm.342-3.006-1.688.563a.563.563 0 0 1-.355-1.068l1.687-.562a.563.563 0 0 1 .356 1.067Z"
    />
  </svg>
);
export default SvgComponent;
