import { Route, Routes as ReactRoutes } from 'react-router-dom';
import Results from './Results';
import {
  AutoConsumptionPayment,
  MandatSignature,
  Owners,
  PdfSignature,
  Seniority,
} from './location';
import Final from './location/Final';
import {
  Address,
  Consumption,
  Covert,
  ElectricInstallation,
  Equipments,
  HomeDescription,
  Map,
  Situation,
  Switchboard,
} from './main';
import PersonalInfos from './main/PersonalInfos';
import { ErrorBoundary } from '@/components';
import {
  MainLayout,
  ResultLayout,
  LocationLayout,
  LocationFinalLayout,
} from '@/components/layouts';
import { useLocalProject } from '@/services/project';

const Routes = () => {
  const { data } = useLocalProject();
  const hasLeasingSolution = (data?.solarSolutions?.[0]?.leasingSolutions?.length ?? 0) > 0;
  const hasCashSolution = (data?.solarSolutions?.[0]?.cashSolutions?.length ?? 0) > 0;
  const hasCarportSolution = (data?.solarSolutions?.[0]?.carPortSolutions?.length ?? 0) > 0;
  const canSeeResults = hasLeasingSolution || hasCashSolution || hasCarportSolution;
  return (
    <ReactRoutes>
      <Route path="/" element={<MainLayout />} errorElement={<ErrorBoundary />}>
        <Route path="error" element={<ErrorBoundary />} />
        <Route index element={<Situation nextRoute="/step-2" previousRoute="/" />} />
        <Route path="*" element={<Situation nextRoute="/step-2" previousRoute="/" />} />
        <Route path="step-1" element={<Situation nextRoute="/step-2" previousRoute="/" />} />
        <Route path="step-2" element={<Address nextRoute="/step-3" previousRoute="/step-1" />} />
        <Route path="step-3" element={<Map nextRoute="/step-4" previousRoute="/step-2" />} />
        <Route
          path="step-4"
          element={<PersonalInfos nextRoute="/step-5" previousRoute="/step-3" />}
        />
        <Route
          path="step-5"
          element={
            <Consumption nextRoute1={'/step-6'} nextRoute2={'/step-8'} previousRoute="/step-4" />
          }
        />
        <Route
          path="step-6"
          element={<HomeDescription nextRoute="/step-7" previousRoute="/step-5" />}
        />
        <Route path="step-7" element={<Equipments nextRoute="/step-8" previousRoute="/step-6" />} />
        <Route
          path="step-8"
          element={
            <Covert nextRoute="/step-9" previousRoute1={'/step-7'} previousRoute2={'/step-5'} />
          }
        />
        <Route
          path="step-9"
          element={<Switchboard nextRoute="/step-10" previousRoute="/step-8" />}
        />

        <Route
          path="step-10"
          element={<ElectricInstallation nextRoute="/results" previousRoute="/step-9" />}
        />
      </Route>
      {canSeeResults && (
        <Route path="/results" element={<ResultLayout />}>
          <Route index element={<Results />} />
        </Route>
      )}
      <>
        <Route path="/location" element={<LocationLayout />} errorElement={<ErrorBoundary />}>
          <Route index element={<Owners />} />
          <Route path="error" element={<ErrorBoundary />} />
          <Route path="step-1" element={<Owners />} />
          <Route path="step-2" element={<AutoConsumptionPayment />} />
          <Route path="step-3" element={<PdfSignature />} />
          <Route path="step-4" element={<MandatSignature />} />
          <Route path="step-5" element={<Seniority />} />
        </Route>
        <Route
          path="/location/step-6"
          element={<LocationFinalLayout />}
          errorElement={<ErrorBoundary />}>
          <Route index element={<Final />} />
        </Route>
      </>
    </ReactRoutes>
  );
};

export default Routes;
