/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/unbound-method */
import { Map as GoogleMap } from '@vis.gl/react-google-maps';
import { useEffect } from 'react';
import { useCalculateTravelFromSoleriel } from './useCalculateTravelFromSoleriel';
import { useCenter } from './useCenter';
import { useGeocode } from './useGeocode';

const Map = ({
  placeId,
  defaultCenter,
  onGetCenter,
  onGetAddress,
  onCalculateTravel,
}: {
  placeId?: string;
  defaultCenter?: google.maps.LatLngLiteral;
  onGetCenter?: (center: google.maps.LatLngLiteral) => void;
  onGetAddress?: (address: { description: string; place_id: string }) => void;
  onCalculateTravel?: (props: { travelDistance: number; travelDuration: number }) => void;
}) => {
  // Return the center of the map
  const center = useCenter(placeId, defaultCenter);
  useEffect(() => {
    if (center) {
      onGetCenter?.(center);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);
  // Return the address of the current position
  useGeocode(center ?? { lat: 0, lng: 0 }, onGetAddress);
  useCalculateTravelFromSoleriel(center, onCalculateTravel);

  return center ? (
    <>
      <GoogleMap
        key={`${center.lat}-${center.lng}`}
        className="size-full min-h-96 rounded-xl"
        defaultCenter={center}
        defaultZoom={20}
        disableDefaultUI={true}
        onCenterChanged={(ev) => {
          onGetCenter?.(ev.detail.center);
        }}
        mapTypeId={'hybrid'}
        styles={[
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
        ]}
        mapTypeControl={false}
        keyboardShortcuts={false}
        tilt={0}
        zoomControl={true}
        scrollwheel={false}
        disableDoubleClickZoom={true}
        fullscreenControl={false}></GoogleMap>
    </>
  ) : null;
};

export default Map;
