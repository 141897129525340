import { type E164Number } from 'libphonenumber-js/core';
import { forwardRef, type ComponentProps } from 'react';
import { TextField as AriaTextField, Label } from 'react-aria-components';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import { cn } from '@/helpers';

const PhoneField = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof AriaTextField> & {
    placeholder: string;
    unit?: string;
    onChange: (value?: E164Number | undefined) => void;
  }
>((props, ref) => (
  <div className="relative flex flex-1 flex-col gap-y-2 text-sm">
    {props['aria-label'] && (
      <Label className="font-medium">
        {props['aria-label']}
        {props.isRequired && <span className="text-red-500">*</span>}
      </Label>
    )}

    <AriaTextField
      {...props}
      ref={ref}
      className={cn(
        'flex w-full gap-x-2 rounded-lg border border-grey2 bg-white px-3 py-[10px] text-black',
        {
          'border-red-500': props?.['aria-errormessage'],
        }
      )}>
      <PhoneInput
        country="FR"
        name={props.name}
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={props.onChange}
        placeholder={props?.placeholder}
        className="flex-1 border-none no-underline outline-none placeholder:text-ghost"
      />
    </AriaTextField>
    {props?.['aria-errormessage'] && (
      <span className="absolute top-full ml-1 text-xxs leading-none text-red-500">
        {props?.['aria-errormessage']}
      </span>
    )}
  </div>
));
export default PhoneField;
