import * as React from 'react';

function useClickOutside(handler: (e?: any) => void) {
  const ref = React.useRef<HTMLInputElement | null>(null);
  React.useEffect(() => {
    const listener = (event: any): void => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      if (ref.current === null || ref?.current?.contains(event.target)) return;

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
  return ref;
}

export default useClickOutside;
