import { type ComponentProps } from 'react';
import { Label, Radio, RadioGroup as AriaRadioGroup } from 'react-aria-components';
import { cn } from '@/helpers';

const RadioGroup = (
  props: ComponentProps<typeof AriaRadioGroup> & { items: Array<{ value: string; label: string }> }
) => (
  <AriaRadioGroup {...props} className="flex flex-col gap-2 text-sm">
    {props?.['aria-label'] && <Label className="font-medium">{props?.['aria-label']}</Label>}
    <div className="flex flex-row gap-x-4">
      {props.items.map((item) => (
        <Radio key={item.value} value={item.value} className="flex flex-row items-center gap-x-2">
          {({ isSelected }) => (
            <>
              <div
                data-key={item.value}
                className={cn('size-4 rounded-full border-4 bg-white', {
                  'border-primary bg-grey2': isSelected,
                  'border-grey2': !isSelected,
                })}></div>
              {item.label}
            </>
          )}
        </Radio>
      ))}
    </div>
  </AriaRadioGroup>
);
export default RadioGroup;
