import { cn } from '@/helpers';

const ProgressBar = ({ totalSteps, currentStep }: { totalSteps: number; currentStep: number }) => (
  <div className="flex w-full flex-row gap-x-2">
    {Array.from({ length: totalSteps }).map((_, index) => {
      const isCompleted = index <= currentStep;
      return (
        <div
          key={index}
          className={cn('flex-1 h-2 rounded-full', {
            'bg-primary': isCompleted,
            'bg-grey3': !isCompleted,
          })}
        />
      );
    })}
  </div>
);
export default ProgressBar;
