const formatPrice: (price: number, currencyCode: string, locale?: string) => string = (
  price,
  currencyCode,
  locale = 'fr-FR'
) => {
  try {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      maximumFractionDigits: 0,
    }).format(price);
  } catch {
    return '';
  }
};

export default formatPrice;
