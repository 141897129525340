import { useMapsLibrary } from '@vis.gl/react-google-maps';

export const useGetAzimuth = () => {
  const geometry = useMapsLibrary('geometry');

  return (points: google.maps.LatLngLiteral[]) => {
    if (!geometry || points.length !== 2) {
      return;
    }

    // Calculate azimut of perpendicular line to the ridge line. Express in degrees form 0 to 360
    const heading = geometry?.spherical.computeHeading(points[0], points[1]);
    // Azimut as it is on a compass (0 is North, 90 is East, 180 is South, 270 is West)
    const azimuth360 = ((heading < 0 ? heading + 360 : heading) + 90) % 360;
    // Azimut as it is specified in PVGIS (180 is North, -90 is East, 0 is South, 90 is West)
    let azimuth = azimuth360 - 180;
    if (azimuth > 180) {
      azimuth -= 360;
    } else if (azimuth < -180) {
      azimuth += 360;
    }
    // Describe azimuth in cardinal points (ex: NO, SO, SE, NE)
    const cardinalPoints = ['N', 'NE', 'E', 'SE', 'S', 'SO', 'O', 'NO'];
    const index = Math.round(azimuth360 / 45);
    const azimuthCardinal = cardinalPoints[index % 8];
    return { azimuth, azimuthCardinal, heading };
  };
};
