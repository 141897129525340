import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Civility, type Person } from '@/apis';
import { ArrowRight } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button, PhoneField, RadioGroup, TextField } from '@/components/ui';
import { useLocalProject } from '@/services/project';

type PersonalInfosForm = Omit<Person, 'birthDate'>;

const getDefaultValues = (personData?: Person) => ({
  civility: personData?.civility ?? Civility.MR,
  firstName: personData?.firstName ?? '',
  lastName: personData?.lastName ?? '',
  email: personData?.email ?? '',
  mobilePhone: personData?.mobilePhone ?? '',
  owner: true,
});

const PersonalInfos = ({
  nextRoute,
  previousRoute,
}: {
  nextRoute: string;
  previousRoute: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, installationData, mutate } = useLocalProject();
  const defaultValues = getDefaultValues(data?.persons?.[0]);

  const { watch, setValue, formState, handleSubmit } = useForm<PersonalInfosForm>({
    defaultValues,
    values: defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        civility: yup.string().required(t('required')).oneOf(Object.values(Civility)),
        firstName: yup.string().required(t('required')),
        lastName: yup.string().required(t('required')),
        email: yup.string().email(t('email-must-be-valid')).required(t('required')),
        mobilePhone: yup
          .string()
          .required(t('required'))
          // Check if phone number is valid and if it is a french mobile phone number (06 or 07)
          .test('is-phone', t('phone-must-be-valid'), (value) => isValidPhoneNumber(value))
          .test(
            'is-french-mobile-phone',
            t('phone-must-be-mobile-phone'),
            (value) => value.startsWith('+336') || value.startsWith('+337')
          ),
        owner: yup.boolean().required(t('required')),
      })
    ),
  });

  const onSubmit = async (formData: PersonalInfosForm) => {
    if (!installationData) {
      return;
    }
    mutate({
      persons: [
        {
          ...formData,
        } as Person,
      ],
    });
    navigate(nextRoute);
  };

  return (
    <AnimatedPage>
      <form className="flex w-full flex-col gap-y-4">
        <h1 className="text-lg font-semibold text-black">{t('personal-informations')}</h1>
        <RadioGroup
          name="civility"
          aria-label={t('civility')}
          items={[
            { value: Civility.MR, label: t('mr') },
            { value: Civility.MRS, label: t('mrs') },
            { value: Civility.MISS, label: t('miss') },
          ]}
          defaultValue={watch('civility')}
          onChange={(id) => {
            setValue('civility', id as PersonalInfosForm['civility'], {
              shouldValidate: true,
            });
          }}
        />

        <TextField
          name="firstName"
          aria-label={t('first-name')}
          placeholder={t('first-name')}
          defaultValue={watch('firstName')}
          autoComplete="given-name"
          onChange={(value) => {
            setValue('firstName', value, { shouldValidate: true });
          }}
          aria-errormessage={formState?.errors?.firstName?.message}
        />
        <TextField
          name="lastName"
          aria-label={t('last-name')}
          placeholder={t('last-name')}
          defaultValue={watch('lastName')}
          autoComplete="family-name"
          onChange={(value) => {
            setValue('lastName', value, { shouldValidate: true });
          }}
          aria-errormessage={formState?.errors?.lastName?.message}
        />
        <TextField
          name="email"
          aria-label={t('email')}
          placeholder={t('email')}
          defaultValue={watch('email')}
          type="email"
          autoComplete="email"
          onChange={(value) => {
            setValue('email', value, { shouldValidate: true });
          }}
          aria-errormessage={formState?.errors?.email?.message}
        />
        <PhoneField
          name="mobilePhone"
          aria-label={t('phone-number')}
          placeholder={t('phone-number')}
          value={watch('mobilePhone')}
          autoComplete="tel"
          type="tel"
          onChange={(value) => {
            setValue('mobilePhone', value as string, { shouldValidate: true });
          }}
          aria-errormessage={formState?.errors?.mobilePhone?.message}
        />

        <div className="flex w-full justify-between">
          <Button
            label={t('previous')}
            className={'mt-4 self-end bg-grey3 text-black'}
            onPress={() => {
              navigate(previousRoute);
            }}
          />
          <Button
            label={t('next-step')}
            className={'mt-4 self-end bg-primary text-white'}
            onPress={() => {
              void handleSubmit(onSubmit)();
            }}
            PostIcon={(props) => <ArrowRight {...props} />}
          />
        </div>
      </form>
    </AnimatedPage>
  );
};
export default PersonalInfos;
