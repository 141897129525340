import * as Sentry from '@sentry/react';
import { Analytics } from '@vercel/analytics/react';
import TagManager from 'react-gtm-module';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    dataLayer?: any[];
  }
}

const tagManagerArgs = {
  gtmId: 'GTM-5QR5MQG',
};

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  environment: import.meta.env.NEXT_PUBLIC_VERCEL_ENV,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

TagManager.initialize(tagManagerArgs);

export default function AnalyticsProvider({ children }: { children: React.ReactNode }) {
  return (
    <>
      {children}
      <Analytics />
    </>
  );
}
