import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SqsPdfAction } from '@/apis';
import { signatureUrl } from '@/apis/location';
import { ArrowRight } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button, ContractSignature } from '@/components/ui';
import { usePdf } from '@/services/pdf';
import { useLocalProject } from '@/services/project';

const PdfSignature = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useLocalProject();

  const { pdfUrl, isPdfSigned } = usePdf(data?._id ?? '', SqsPdfAction.GENERATE_LEASING_PDF);

  const [isLoading, setIsLoading] = useState(false);

  const handleBtnClick = () => {
    setIsLoading(true);
    window.location = signatureUrl(data?._id ?? '') as unknown as Location;
  };

  const submit = () => {
    navigate(`/location/step-4`);
  };

  return (
    <AnimatedPage>
      <h1 className="text-xl font-semibold text-black">{t('sign-rent-contract')}</h1>
      <p className="text-sm text-ghost">{t('sign-rent-contract-subtitle')}</p>

      <ContractSignature
        isContractLoading={!pdfUrl}
        isBtnLoading={isLoading}
        onDocClick={pdfUrl ? () => window.open(pdfUrl as unknown as string, '_blank') : undefined}
        status={isPdfSigned === undefined ? undefined : isPdfSigned ? 'signed' : 'not-signed'}
        onBtnClick={handleBtnClick}
      />
      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={() => {
            navigate('/location/step-2');
          }}
        />

        <Button
          label={t('next-step')}
          className={'mt-4 bg-primary text-white'}
          isDisabled={!isPdfSigned}
          onPress={() => {
            submit();
          }}
          PostIcon={(props) => <ArrowRight {...props} />}
        />
      </div>
    </AnimatedPage>
  );
};
export default PdfSignature;
