import { type PropsWithChildren } from 'react';
import {
  Modal as AriaModal,
  Button as AriaButton,
  Dialog,
  DialogTrigger,
  ModalOverlay,
} from 'react-aria-components';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components';

const InfoModal = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();

  return (
    <DialogTrigger>
      <AriaButton
        aria-label="!"
        className="flex size-4 shrink-0 items-center justify-center rounded-full bg-success text-center text-xs text-white">
        !
      </AriaButton>
      <ModalOverlay
        className={({ isEntering, isExiting }) => `
    fixed inset-0 z-10 overflow-y-auto bg-black/30 flex min-h-full items-start justify-center p-4 text-center backdrop-blur-sm
    ${isEntering ? 'animate-in fade-in duration-300 ease-out' : ''}
    ${isExiting ? 'animate-out fade-out duration-200 ease-in' : ''}
  `}>
        <AriaModal>
          <Dialog
            role="alertdialog"
            className="relative flex max-w-[400px] flex-col gap-4 rounded-md bg-white p-4 outline-none">
            {({ close }) => (
              <>
                {children}
                <div className="flex flex-row justify-end">
                  <Button
                    label={t('i-understand')}
                    onPress={() => {
                      close();
                    }}
                    className="bg-primary text-white"
                  />
                </div>
              </>
            )}
          </Dialog>
        </AriaModal>
      </ModalOverlay>
    </DialogTrigger>
  );
};

export default InfoModal;
