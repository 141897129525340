import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ElectricType, ElectricalSubscriptionType } from '@/apis';
import { counter, linky } from '@/assets';
import { ListSelect } from '@/components';
import { ArrowRight, LoaderCircle } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button, InfoModal, LoadingModal } from '@/components/ui';
import { type Simulation, useLocalProject, useSubmitProject } from '@/services/project';

type ElectricInstallationForm = {
  electricType: Simulation['electricInstallation']['electricType'];
  subscriptionPower: Simulation['electricInstallation']['subscriptionPower'];
  typeOfContract: Simulation['electricInstallation']['typeOfContract'];
};

const getDefaultValues = (installationData?: Simulation) => ({
  electricType: installationData?.electricInstallation?.electricType,
  subscriptionPower: installationData?.electricInstallation?.subscriptionPower,
  typeOfContract: installationData?.electricInstallation?.typeOfContract,
});

const ElectricInstallation = ({
  nextRoute,
  previousRoute,
}: {
  nextRoute: string;
  previousRoute: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { submit, isPending, isSubmited } = useSubmitProject();
  const { installationData, mutateInstallationData } = useLocalProject();
  const defaultValues = getDefaultValues(installationData);

  const { watch, setValue, formState, handleSubmit } = useForm<ElectricInstallationForm>({
    defaultValues,
    values: defaultValues as ElectricInstallationForm,
    resolver: yupResolver(
      yup.object().shape({
        electricType: yup
          .string()
          .required(t('required-select'))
          .oneOf(Object.values(ElectricType)),
        subscriptionPower: yup.number().required(t('required-select')).positive().integer(),
        typeOfContract: yup
          .string()
          .required(t('required-select'))
          .oneOf(Object.values(ElectricalSubscriptionType)),
      })
    ),
  });

  const onSubmit = async (data: ElectricInstallationForm) => {
    if (!installationData) {
      return;
    }
    const newInstallationData = {
      ...installationData,
      electricInstallation: {
        ...installationData.electricInstallation,
        ...data,
      },
    };
    mutateInstallationData(newInstallationData);
    await submit({
      simulationInput: newInstallationData,
    }).catch((error) => {
      navigate('/error');
      console.error(error);
    });
  };

  useEffect(() => {
    if (isSubmited && !isPending) {
      navigate(nextRoute);
    }
  }, [isPending, isSubmited, nextRoute, navigate]);

  return (
    <AnimatedPage>
      <LoadingModal isOpen={isPending}>
        <h1 className="text-lg font-semibold text-primary">
          {t('creating-your-personalized-project')}
        </h1>
        <h2 className="mt-2 text-sm font-medium text-secondary">
          {t('it-may-take-a-few-moments')}
        </h2>
      </LoadingModal>
      <h1 className="text-lg font-semibold text-black">{t('your-current-installation')}</h1>
      <h2 className="mt-2 flex gap-2 text-sm font-medium text-black">
        {t('what-type-of-installation-do-you-have')}
        <InfoModal>
          <p className="text-left text-sm">{t('your-current-installation-description')}</p>
          <img src={counter} alt="counter" className=" w-60" />
        </InfoModal>
      </h2>

      <ListSelect
        aria-label={t('what-type-of-installation-do-you-have')}
        items={[
          { name: t('mono'), description: t('mono-description'), id: ElectricType.MONOPHASED },
          { name: t('tri'), description: t('tri-description'), id: ElectricType.TRIPHASED },
          { name: t('cant-find-my-circuit-breaker'), id: ElectricType.UNKNOWN },
        ]}
        selectedKey={watch('electricType')}
        onSelect={(id: string) => {
          setValue('electricType', id as ElectricInstallationForm['electricType'], {
            shouldValidate: true,
          });
        }}
        errorMessage={formState.errors.electricType?.message}
      />

      <h2 className="mt-2 flex gap-2 text-sm font-medium text-black">
        {t('what-is-the-power-of-your-installation')}
        <InfoModal>
          <p className="text-left text-sm">{t('your-power-description')}</p>
          <img src={linky} alt="linky" className="w-60" />
        </InfoModal>
      </h2>
      <ListSelect
        aria-label={t('what-type-of-roof-covering')}
        // 6, 9, 12, 18, 24 and more
        items={[
          { name: '6kVA', id: '6' },
          { name: '9kVA', id: '9' },
          { name: '12kVA', id: '12' },
          { name: '18kVA', id: '18' },
          { name: t('more-than-kva', { count: 24 }), id: '24' },
        ]}
        selectedKey={String(watch('subscriptionPower'))}
        onSelect={(id: string) => {
          setValue('subscriptionPower', Number(id), { shouldValidate: true });
        }}
        errorMessage={formState.errors.subscriptionPower?.message}
      />

      <h2 className="mt-2 flex gap-2 text-sm font-medium text-black">
        {t('what-is-your-subscription-type')}
        <InfoModal>
          <p className="text-left text-sm">{t('your-subscription-description')}</p>
        </InfoModal>
      </h2>
      <ListSelect
        aria-label={t('what-is-your-subscription-type')}
        items={[
          {
            name: t('base'),
            description: t('base-description'),
            id: ElectricalSubscriptionType.BASE,
          },
          {
            name: t('off-peak'),
            description: t('off-peak-description'),
            id: ElectricalSubscriptionType.OFF_PEAK_HOURS,
          },
        ]}
        selectedKey={watch('typeOfContract')}
        onSelect={(id: string) => {
          setValue('typeOfContract', id as ElectricInstallationForm['typeOfContract'], {
            shouldValidate: true,
          });
        }}
        errorMessage={formState.errors.typeOfContract?.message}
      />

      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={() => {
            navigate(previousRoute);
          }}
        />
        <Button
          label={t('next-step')}
          className={'mt-4 self-end bg-primary text-white'}
          onPress={() => {
            void handleSubmit(onSubmit)();
          }}
          isDisabled={isPending || isSubmited}
          PostIcon={(props) =>
            isPending ? <LoaderCircle {...props} /> : <ArrowRight {...props} />
          }
        />
      </div>
    </AnimatedPage>
  );
};
export default ElectricInstallation;
