import { forwardRef, type ComponentProps } from 'react';
import { TextField as AriaTextField, Label, Input } from 'react-aria-components';
import { cn } from '@/helpers';

const TextField = forwardRef<
  HTMLInputElement,
  ComponentProps<typeof AriaTextField> & { placeholder: string; unit?: string }
>((props, ref) => (
  <div className="relative flex flex-1 flex-col gap-y-2 text-sm">
    {props['aria-label'] && (
      <Label className="font-medium">
        {props['aria-label']}
        {props.isRequired && <span className="text-red-500">*</span>}
      </Label>
    )}

    <AriaTextField
      {...props}
      className={cn(
        'flex w-full gap-x-2 rounded-lg border border-grey2 bg-white px-3 py-[10px] text-black',
        {
          'border-red-500 border-2': props?.['aria-errormessage'],
        }
      )}>
      <Input
        ref={ref}
        placeholder={props?.placeholder}
        value={props?.defaultValue}
        className="flex-1 border-none no-underline outline-none placeholder:text-ghost"
      />
      {props.unit && <span className="text-black">{props.unit}</span>}
    </AriaTextField>
    {props?.['aria-errormessage'] && (
      <span className="absolute top-full ml-1 text-xxs leading-none text-red-500">
        {props?.['aria-errormessage']}
      </span>
    )}
  </div>
));
export default TextField;
