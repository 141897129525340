import { type SVGProps } from 'react';
const ArrowRightUp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M11.17 6.776 5.43 12.514l-.942-.943 5.737-5.738H5.17V4.5h7.334v7.333h-1.334V6.776Z"
    />
  </svg>
);
export default ArrowRightUp;
