import { useEffect, useMemo, useState } from 'react';
import { CircleMarker } from './CircleMarker';
import { Line } from './Line';
import { Polygon } from './Polygon';
import { useGetAzimuth } from './useGetAzimuth';

const useGetAzimuthValues = (points: google.maps.LatLngLiteral[], defaultAzimuth: number) => {
  const getAzimut = useGetAzimuth();
  const lines = [
    [points[1], points[0]],
    [points[2], points[1]],
    [points[3], points[2]],
    [points[0], points[3]],
  ];

  const azimuths = lines.map((line) => {
    const azimuth = getAzimut(line);
    return azimuth;
  });

  const defaultSelected = azimuths.findIndex((azimuth) => azimuth?.azimuth === defaultAzimuth);

  return {
    lines,
    azimuths,
    defaultSelected,
  };
};

/** User should select the ridge line of the roof
 * We compute the length and large of the roof
 **/
export const RidgePoints = ({
  roofPoints,
  defaultAzimuth,
  onCalculateOrientation,
}: {
  roofPoints: google.maps.LatLngLiteral[];
  defaultAzimuth: number;
  onCalculateOrientation?: ({
    azimuth,
    azimuthCardinal,
  }: {
    azimuth: number;
    azimuthCardinal: string;
  }) => void;
}) => {
  const [showRidgeLine, setShowRidgeLine] = useState(false);
  const [selected, setSelected] = useState<number>(0);
  const { azimuths, lines, defaultSelected } = useGetAzimuthValues(roofPoints, defaultAzimuth);

  const ridgePoints = useMemo(() => {
    if (roofPoints.length === 4) {
      const middle1 = {
        lat: (roofPoints[0]?.lat + roofPoints[1]?.lat) / 2,
        lng: (roofPoints[0]?.lng + roofPoints[1]?.lng) / 2,
      };
      const middle2 = {
        lat: (roofPoints[1]?.lat + roofPoints[2]?.lat) / 2,
        lng: (roofPoints[1]?.lng + roofPoints[2]?.lng) / 2,
      };
      const middle3 = {
        lat: (roofPoints[2]?.lat + roofPoints[3]?.lat) / 2,
        lng: (roofPoints[2]?.lng + roofPoints[3]?.lng) / 2,
      };
      const middle4 = {
        lat: (roofPoints[3]?.lat + roofPoints[0]?.lat) / 2,
        lng: (roofPoints[3]?.lng + roofPoints[0]?.lng) / 2,
      };
      return [middle1, middle2, middle3, middle4];
    }
  }, [roofPoints]);

  useEffect(() => {
    if (!azimuths[selected]) return;
    onCalculateOrientation?.(azimuths[selected] as { azimuth: number; azimuthCardinal: string });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (!defaultSelected || defaultSelected === -1) return;
    setSelected(defaultSelected);
    setShowRidgeLine(true);
  }, [defaultSelected]);

  return (
    <>
      <Polygon path={roofPoints} />

      {ridgePoints?.[0] && (
        <CircleMarker
          center={ridgePoints[0]}
          onClick={() => {
            setShowRidgeLine(true);
            setSelected(0);
          }}
        />
      )}
      {ridgePoints?.[1] && (
        <CircleMarker
          center={ridgePoints[1]}
          onClick={() => {
            setShowRidgeLine(true);
            setSelected(1);
          }}
        />
      )}
      {ridgePoints?.[2] && (
        <CircleMarker
          center={ridgePoints[2]}
          onClick={() => {
            setShowRidgeLine(true);
            setSelected(2);
          }}
        />
      )}
      {ridgePoints?.[3] && (
        <CircleMarker
          center={ridgePoints[3]}
          onClick={() => {
            setShowRidgeLine(true);
            setSelected(3);
          }}
        />
      )}
      {showRidgeLine && ridgePoints && <Line path={lines[selected]} />}
    </>
  );
};
