import { type PropsWithChildren } from 'react';
import { Modal, ModalOverlay } from 'react-aria-components';
import { useTranslation } from 'react-i18next';
import LottieAnimation from '../LottieAnimation/LottieAnimation';

const LoadingModal = ({ isOpen, children }: PropsWithChildren<{ isOpen: boolean }>) => {
  const { t } = useTranslation();

  return (
    <ModalOverlay
      isOpen={isOpen}
      className={({ isEntering, isExiting }) => `
    fixed inset-0 z-10 overflow-y-auto bg-black/30 flex min-h-full items-center justify-center p-4 text-center backdrop-blur-sm
    ${isEntering ? 'animate-in fade-in duration-300 ease-out' : ''}
    ${isExiting ? 'animate-out fade-out duration-200 ease-in' : ''}
  `}>
      <Modal
        aria-label={t('loading')}
        className="relative flex max-w-2xl flex-col items-center justify-center gap-4 rounded-md bg-white p-8 outline-none">
        <LottieAnimation />
        {children}
      </Modal>
    </ModalOverlay>
  );
};

export default LoadingModal;
