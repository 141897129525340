/**
 * This is manually filled out. It describe each response and parameter type for each API endpoint.
 * It is used to generate the API documentation.
 * Note that the imported types are not taken into account by the documentation generator. All theses types are imported by the generateTypes.js script.
 * Note that type must be exported to be taken into account by the documentation generator.
 */



export type LocationProjectResponse = {
  isAlreadyPaid: boolean,
  isAlreadySignedContract: boolean,
  isAlreadySignedMandate: boolean,
  isPaymentRequired: boolean
}

export enum FunnelOperation {
  CONTRACT_SIGNATURE = "CONTRACT_SIGNATURE",
  PAYMENT_ONLY = "PAYMENT_ONLY",
  PAYMENT_FULL = "PAYMENT_FULL",
  MANDATE_ONLY = "MANDATE_ONLY",
  SOLVENCY_CHECK = "SOLVENCY_CHECK"
}
