import { Outlet, useLocation } from 'react-router-dom';
import { houseBg } from '@/assets';
import { Header, Hero, ProgressBar } from '@/components';

const getStepByUrl = (url: string) => {
  switch (url) {
    case '/step-1':
      return 0;
    case '/step-2':
      return 1;
    case '/step-3':
      return 2;
    case '/step-4':
      return 3;
    case '/step-5':
      return 4;
    case '/step-6':
      return 5;
    case '/step-7':
      return 6;
    case '/step-8':
      return 7;
    case '/step-9':
      return 8;
    case '/step-10':
      return 9;
    case '/error':
      return undefined;
    default:
      return 0;
  }
};

const MainLayout = () => {
  const location = useLocation();
  const currentStep = getStepByUrl(location.pathname);
  return (
    <>
      <div className="flex h-screen flex-col overflow-y-scroll lg:hidden  ">
        <Header />
        <div
          className="flex flex-1 flex-col p-2"
          style={{
            backgroundImage: `url(${houseBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}>
          <div className=" flex flex-col gap-y-4 overflow-y-scroll rounded-2xl bg-white px-4 py-6">
            {currentStep !== undefined && <ProgressBar totalSteps={10} currentStep={currentStep} />}
            <Outlet />
          </div>
        </div>
      </div>
      <div className="hidden h-screen flex-row lg:flex">
        <div
          className="relative flex h-full w-2/3 flex-col items-center justify-start pt-52"
          style={{
            backgroundImage: `url(${houseBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}>
          <Header className="absolute inset-x-0 top-0" />
          <Hero />
        </div>
        <div className="flex h-full flex-1 shrink flex-col gap-y-4 overflow-y-scroll rounded-2xl bg-white px-4 py-6">
          {currentStep !== undefined && <ProgressBar totalSteps={10} currentStep={currentStep} />}
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default MainLayout;
