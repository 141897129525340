import { type SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M26.667 26.667A1.333 1.333 0 0 1 25.333 28H6.667a1.333 1.333 0 0 1-1.334-1.333v-12h-4L15.103 2.15a1.333 1.333 0 0 1 1.794 0l13.77 12.517h-4v12ZM10 17.334a6 6 0 0 0 12 0h-2.667a3.333 3.333 0 0 1-6.666 0H10Z"
    />
  </svg>
);
export default SvgComponent;
