import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseMutationOptions,
  type UseMutationResult,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';
import {
  SqsPdfAction,
  type CreateProjectRequest,
  type CreateProjectResponse,
  type GetPdfGenerationStatusResponse,
  type Project,
  type UpdateProjectLeasingContractRequest,
  type UpdateProjectLeasingContractResponse,
  type UpdateProjectPersonsRequest,
  type UpdateProjectPersonsResponse,
} from '@/apis/simulation/models';
import {
  createProject,
  getPdfGenerationStatus,
  getProject,
  getQueryKey,
  updateProjectLeasingContract,
  updateProjectPersons,
} from '@/apis/simulation/queries';

// Hook for creating a project
export const useCreateProject = (
  options?: UseMutationOptions<CreateProjectResponse, unknown, CreateProjectRequest>
): UseMutationResult<CreateProjectResponse, unknown, CreateProjectRequest> =>
  useMutation<CreateProjectResponse, unknown, CreateProjectRequest>({
    mutationFn: async (data: CreateProjectRequest) => await createProject(data),

    ...options,
  });

// Hook for getting a project
export const useGetProject = (
  projectId: string,
  options?: Partial<UseQueryOptions<Project>>
): UseQueryResult<Project> =>
  useQuery<Project>({
    queryKey: getQueryKey('getProject', projectId),
    queryFn: async () => await getProject(projectId),
    ...options,
  });

// Hook for updating project persons
export const useUpdateProjectPersons = (
  options?: UseMutationOptions<
    UpdateProjectPersonsResponse,
    unknown,
    { projectId: string; data: UpdateProjectPersonsRequest }
  >
): UseMutationResult<
  UpdateProjectPersonsResponse,
  unknown,
  { projectId: string; data: UpdateProjectPersonsRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation<
    UpdateProjectPersonsResponse,
    unknown,
    { projectId: string; data: UpdateProjectPersonsRequest }
  >({
    mutationFn: async ({ projectId, data }) => await updateProjectPersons(projectId, data),
    onMutate: async ({ projectId, data }) => {
      queryClient.setQueryData(getQueryKey('getProject', projectId), (oldData: Project) => {
        const newData: Project = {
          ...oldData,
          persons: data.persons,
        };
        return newData;
      });
    },
    onSettled: async (project) => {
      if (!project) {
        return;
      }
      queryClient.setQueryData(getQueryKey('getProject', project._id), project);
    },
    ...options,
  });
};

// Hook for updating project leasing contract
export const useUpdateProjectLeasingContract = (
  options?: UseMutationOptions<
    UpdateProjectLeasingContractResponse,
    unknown,
    { projectId: string; data: UpdateProjectLeasingContractRequest }
  >
): UseMutationResult<
  UpdateProjectLeasingContractResponse,
  unknown,
  { projectId: string; data: UpdateProjectLeasingContractRequest }
> => {
  const queryClient = useQueryClient();
  return useMutation<
    UpdateProjectLeasingContractResponse,
    unknown,
    { projectId: string; data: UpdateProjectLeasingContractRequest }
  >({
    mutationFn: async ({ projectId, data }) => await updateProjectLeasingContract(projectId, data),
    onMutate: async ({ projectId, data }) => {
      queryClient.setQueryData(getQueryKey('getProject', projectId), (oldData: Project) => {
        const newData: Project = {
          ...oldData,
          leasingContract: {
            ...oldData?.leasingContract,
            ...data,
          },
        };
        return newData;
      });
    },
    onSettled: async (project) => {
      if (!project) {
        return;
      }
      queryClient.setQueryData(getQueryKey('getProject', project._id), project);
    },
    ...options,
  });
};

// Hook for getting PDF generation status
export const useGetPdfGenerationStatus = (
  projectId: string,
  action: SqsPdfAction,
  options?: Partial<UseQueryOptions<GetPdfGenerationStatusResponse>>
): UseQueryResult<GetPdfGenerationStatusResponse> => {
  const queryClient = useQueryClient();
  return useQuery<GetPdfGenerationStatusResponse>({
    queryKey: getQueryKey('getPdfGenerationStatus', projectId, action),
    queryFn: async () =>
      await getPdfGenerationStatus(projectId, { action }).then((response) => {
        if (response.pdfGenerated) {
          queryClient.setQueryData(getQueryKey('getProject', projectId), (oldData: Project) => {
            if (action === SqsPdfAction.GENERATE_LEASING_PDF) {
              const newData: Project = {
                ...oldData,
                leasingContract: {
                  ...oldData?.leasingContract,
                  contractUrl: response.pdfGenerated,
                },
              };
              return newData;
            } else {
              const newData: Project = {
                ...oldData,
                cashContract: {
                  ...oldData?.leasingContract,
                  contractUrl: response.pdfGenerated,
                },
              };
              return newData;
            }
          });
        }
        return response;
      }),
    ...options,
  });
};
