import { type SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M8.5 15.166a6.666 6.666 0 1 1 0-13.333 6.666 6.666 0 0 1 0 13.333Zm0-1.333a5.333 5.333 0 1 0 0-10.667 5.333 5.333 0 0 0 0 10.667Zm-.667-8.667h1.333V6.5H7.833V5.166Zm0 2.667h1.333v4H7.833v-4Z"
    />
  </svg>
);
export default SvgComponent;
