/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Clock, Party } from '@/components/icons';
import { cn } from '@/helpers';

const Step = ({
  selected,
  step,
  title,
  nbOfSteps,
  duration,
  customColor,
  CustomIcon,
}: {
  selected?: boolean;
  step: number;
  title: string;
  nbOfSteps?: string;
  duration?: string;
  customColor?: string;
  CustomIcon?: (props: any) => ReactNode;
}) => (
  <div
    className={cn('flex items-center gap-2', {
      'opacity-50': !selected,
    })}>
    <div
      className={cn(
        'flex size-8 items-center justify-center whitespace-nowrap rounded-full text-sm font-semibold',
        { 'bg-secondary text-white': selected, 'bg-grey2 text-ghost': !selected },
        customColor
      )}>
      {CustomIcon ? <CustomIcon className="size-4" /> : step}
    </div>
    <div className="flex flex-col">
      <div className="text-base font-medium text-black">{title}</div>
      {nbOfSteps || duration ? (
        <div className="flex gap-1 text-sm text-ghost">
          <div>{nbOfSteps ?? ''}</div>
          {nbOfSteps && duration && <div>|</div>}
          <div className="flex items-center gap-0.5">
            <Clock className="size-4" />
            <div>{duration}</div>
          </div>
        </div>
      ) : null}
    </div>
  </div>
);

const LocationStepsTile = ({ selected }: { selected: number }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-8 rounded-xl border border-grey2 bg-white p-6">
      <h1 className="text-xl font-semibold">{t('the-four-steps-of-your-project')}</h1>
      <Step
        step={1}
        selected={selected === 1}
        title={t('creation-of-your-rent-file')}
        nbOfSteps={t('step-count', { count: 2 })}
        duration={t('minutes-count', { count: 5 })}
      />
      <Step
        step={2}
        selected={selected === 2}
        title={t('signature-contract-and-mandat')}
        nbOfSteps={t('step-count', { count: 3 })}
        duration={t('minutes-count', { count: 5 })}
      />
      <Step
        step={3}
        selected={selected === 3}
        title={t('sending-of-the-documents')}
        nbOfSteps={t('step-count', { count: 1 })}
        duration={t('minutes-count', { count: 5 })}
      />
      <Step
        step={4}
        selected={selected === 4}
        title={t('technical-visit')}
        duration={t('three-to-five-days')}
      />
      <Step
        step={5}
        selected={selected === 5}
        title={t('put-in-service-of-your-solar-installation')}
        customColor="bg-warning"
        CustomIcon={() => <Party className="size-4 text-white" />}
      />
      <span className="text-xxs text-ghost">{t('technical-visit-annotation')}</span>
    </div>
  );
};
export default LocationStepsTile;
