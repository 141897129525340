import { type SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230 230" {...props}>
    <path
      fill="currentColor"
      d="M115 19.166c52.929 0 95.834 42.905 95.834 95.833 0 6.967-.738 13.752-2.157 20.298L191.6 118.21a76.666 76.666 0 1 0-41.927 65.176 38.305 38.305 0 0 0 14.902 13.637A95.415 95.415 0 0 1 115 210.833c-52.928 0-95.833-42.905-95.833-95.834 0-52.928 42.905-95.833 95.833-95.833Zm67.084 116.648 13.55 13.551a19.162 19.162 0 0 1 4.293 20.555 19.168 19.168 0 0 1-35.111 1.318 19.176 19.176 0 0 1-1.735-10.818 19.178 19.178 0 0 1 4.474-10.001l.978-1.054 13.551-13.551ZM115 143.749c14.049 0 26.69 6.047 35.459 15.688l-9.057 8.242c-7.57-3.019-16.646-4.763-26.402-4.763s-18.83 1.754-26.402 4.753l-9.056-8.241A47.795 47.795 0 0 1 115 143.749ZM81.459 95.833a14.375 14.375 0 1 1 0 28.75 14.375 14.375 0 0 1 0-28.75Zm67.083 0a14.377 14.377 0 0 1 14.375 14.375 14.376 14.376 0 1 1-14.375-14.375Z"
    />
  </svg>
);
export default SvgComponent;
