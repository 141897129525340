import { type SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M3.333 12.667h.943l6.21-6.21-.943-.942-6.21 6.21v.942ZM14 14H2v-2.829l8.957-8.956a.667.667 0 0 1 .942 0L13.785 4.1a.667.667 0 0 1 0 .942l-7.623 7.624H14V14Zm-3.515-9.428.943.943.943-.943-.943-.943-.943.943Z"
    />
  </svg>
);
export default SvgComponent;
