import { yupResolver } from '@hookform/resolvers/yup';
import { type TFunction } from 'i18next';
import {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Civility, type Person } from '@/apis';
import { useStartFunnel, useWarmup } from '@/apis/location/hooks';
import { useUpdateProjectPersons } from '@/apis/simulation/hooks';
import { AddUser, ArrowRight, LoaderCircle } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button, DateField, PhoneField, RadioGroup, TextField } from '@/components/ui';
import { useLocalProject } from '@/services/project';

const ownerSchema = (t: TFunction, others?: Array<Required<Person>>) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(t('required'))
      .test('unique', t('owners-must-be-unique'), (value) => {
        if (!value) return true;
        return !others?.find((owner) => owner.firstName === value);
      }),
    lastName: yup.string().required(t('required')),
    email: yup
      .string()
      .email()
      .required(t('required'))
      .test('unique', t('email-must-be-unique'), (value) => {
        if (!value) return true;
        return !others?.find((owner) => owner.email === value);
      }),
    mobilePhone: yup
      .string()
      .required(t('required'))
      // Check if phone number is valid and if it is a french mobile phone number (06 or 07)
      .test('is-phone', t('phone-must-be-valid'), (value) => isValidPhoneNumber(value))
      .test(
        'is-french-mobile-phone',
        t('phone-must-be-mobile-phone'),
        (value) => value.startsWith('+336') || value.startsWith('+337')
      ),
    birthDate: yup.string().required(t('required')),
    civility: yup.string().required(t('required')).oneOf(Object.values(Civility)),
    owner: yup.boolean().required(t('required')),
  });

const PersonForm = forwardRef(
  (
    {
      index,
      defaultValue,
      onChange,
      onDelete,
      otherPersons,
    }: {
      index: number;
      defaultValue?: Person;
      onChange: (data: Person, isValid: boolean) => void;
      onDelete?: () => void;
      otherPersons?: Array<Required<Person>>;
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { watch, setValue, formState, register, handleSubmit, trigger } = useForm<
      Required<Person>
    >({
      defaultValues: defaultValue,
      values: defaultValue,
      shouldFocusError: true,
      resolver: yupResolver(ownerSchema(t, otherPersons ?? [])),
    });
    useEffect(() => {
      void trigger();
      onChange(watch(), formState.isValid);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.isValidating]);

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    return (
      <AnimatedPage>
        {index === 0 ? (
          <>
            <h1 className="text-xl font-semibold text-black">{t('owner-informations')}</h1>
            <p className="text-sm text-ghost">{t('owner-informations-subtitle')}</p>
          </>
        ) : (
          <h1 className="flex justify-between text-lg font-semibold text-black">
            {t('owner-number', { number: index + 1 })}
            {onDelete && (
              <button
                className="text-sm text-black/30"
                onClick={() => {
                  onDelete();
                }}>
                X
              </button>
            )}
          </h1>
        )}
        <RadioGroup
          name="civility"
          aria-label={t('civility')}
          items={[
            { value: Civility.MR, label: t('mr') },
            { value: Civility.MRS, label: t('mrs') },
            { value: Civility.MISS, label: t('miss') },
          ]}
          defaultValue={watch('civility')}
          onChange={(id) => {
            setValue('civility', id as Person['civility'], {
              shouldValidate: true,
            });
          }}
        />
        <div className="flex flex-col flex-wrap gap-4 lg:flex-row">
          <TextField
            {...register('firstName')}
            isRequired={true}
            aria-label={t('first-name')}
            placeholder={t('first-name')}
            defaultValue={watch('firstName')}
            onChange={(value) => {
              setValue('firstName', value, { shouldValidate: true });
            }}
            aria-errormessage={formState.errors.firstName?.message}
          />
          <TextField
            {...register('lastName')}
            isRequired={true}
            defaultValue={watch('lastName')}
            aria-label={t('last-name')}
            placeholder={t('last-name')}
            onChange={(value) => {
              setValue('lastName', value, { shouldValidate: true });
            }}
            aria-errormessage={formState.errors.lastName?.message}
          />
          <DateField
            {...register('birthDate')}
            isRequired={true}
            defaultValue={watch('birthDate')}
            aria-label={t('birth-date')}
            onChange={(value) => {
              setValue('birthDate', value, { shouldValidate: true, shouldTouch: true });
            }}
            aria-errormessage={formState.errors.birthDate?.message ?? ''}
          />
        </div>
        <div className="flex flex-col gap-4 lg:flex-row">
          <TextField
            {...register('email')}
            isRequired={true}
            aria-label={t('email')}
            placeholder={t('email')}
            defaultValue={watch('email')}
            onChange={(value) => {
              setValue('email', value, { shouldValidate: true });
            }}
            aria-errormessage={formState.errors.email?.message}
          />
          <PhoneField
            {...register('mobilePhone')}
            isRequired={true}
            aria-label={t('phone-number')}
            placeholder={t('phone-number')}
            value={watch('mobilePhone')}
            onChange={(value) => {
              setValue('mobilePhone', value as string, { shouldValidate: true });
            }}
            aria-errormessage={formState.errors.mobilePhone?.message}
          />
        </div>
      </AnimatedPage>
    );
  }
);

const Owners = () => {
  const { t } = useTranslation();
  const { data: localProjectData, mutate } = useLocalProject();
  const { mutateAsync: updatePersons } = useUpdateProjectPersons();
  const owner = localProjectData?.persons[0];
  // Array of ref
  const refs = useRef<any>([]);
  const [owners, setOwners] = useState([owner!]);
  const [canSubmit, setCanSubmit] = useState(false);
  const navigate = useNavigate();
  const { mutateAsync: startFunnel } = useStartFunnel();
  const { mutateAsync: warmup } = useWarmup();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      mutate({
        ...localProjectData,
        persons: owners,
      });
      await Promise.all([
        startFunnel(localProjectData?._id ?? ''),
        warmup(),
        updatePersons({
          projectId: localProjectData?._id ?? '',
          data: {
            persons: owners,
          },
        }),
      ]);

      navigate('/location/step-2');
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      navigate('/location/error');
    }
  }, [localProjectData?._id, navigate, owners, startFunnel, updatePersons, warmup]);

  return (
    <AnimatedPage>
      <PersonForm
        index={0}
        ref={refs.current[0]}
        otherPersons={owners?.slice(1)}
        defaultValue={{
          civility: owner?.civility ?? Civility.MR,
          firstName: owner?.firstName ?? '',
          lastName: owner?.lastName ?? '',
          email: owner?.email ?? '',
          mobilePhone: owner?.mobilePhone ?? '',
          // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
          birthDate: owner?.birthDate as string,
          owner: owner?.owner ?? false,
        }}
        onChange={(data, isValid) => {
          setCanSubmit(isValid);
          setOwners([data, ...owners.slice(1)]);
        }}
      />
      {owners?.map(
        (_, index) =>
          index > 0 && (
            <Fragment key={index}>
              <div className="my-4 h-px w-full bg-grey3" />
              <PersonForm
                ref={refs.current[index]}
                otherPersons={owners.filter((_, i) => i !== index)}
                defaultValue={owners[index]}
                index={index}
                onChange={(data, isValid) => {
                  setCanSubmit(isValid);
                  setOwners(owners.map((owner, i) => (i === index ? { ...data } : owner)));
                }}
                onDelete={() => {
                  const _owners = owners.filter((_, i) => i !== index);
                  setOwners(_owners);
                  setCanSubmit(true);
                  _owners.forEach((owner) => {
                    if (!ownerSchema(t, []).isValidSync(owner)) {
                      setCanSubmit(false);
                    }
                  });
                }}
              />
            </Fragment>
          )
      )}

      <div className="mt-8 flex flex-row gap-4 rounded-xl bg-grey3 p-4">
        <div className="flex size-5 items-center justify-center rounded-full bg-warning p-2 text-white">
          !
        </div>
        <div className="flex flex-col gap-y-2">
          <p className="text-sm text-black">{t('to-validate-we-must-have-all-owners-agreement')}</p>
          <span className="text-sm text-ghost">{t('we-will-sent-to-each-owner')}</span>
          <Button
            PreIcon={(props) => <AddUser {...props} />}
            className={'self-start bg-black text-white'}
            label={t('add-owner')}
            onPress={() => {
              setOwners([...owners, { owner: true } as Required<Person>]);
            }}
          />
        </div>
      </div>

      <div className="flex w-full justify-end">
        <Button
          label={t('next-step')}
          className={'mt-4 self-end bg-primary text-white'}
          isDisabled={isLoading}
          onPress={() => {
            void (canSubmit && onSubmit());
          }}
          PostIcon={(props) =>
            isLoading ? <LoaderCircle {...props} /> : <ArrowRight {...props} />
          }
        />
      </div>
    </AnimatedPage>
  );
};
export default Owners;
