import { Outlet } from 'react-router-dom';
import { houseBg } from '@/assets';
import { Header, LocationStepsTile, ProgressBar, QuestionTile, RentResultTile } from '@/components';

const LocationFinalLayout = () => (
  <>
    <div className="flex h-screen flex-col items-center overflow-y-scroll bg-grey3 pb-12 lg:gap-y-8 ">
      <Header />
      <div className="hidden w-full flex-row justify-center gap-4 px-4 lg:flex">
        <div className="flex w-2/3 flex-col gap-y-4 overflow-y-scroll">
          <div className="flex flex-col gap-y-4  overflow-hidden rounded-2xl border border-grey2 bg-white">
            <div
              className="h-[176px] w-full"
              style={{
                backgroundImage: `url(${houseBg})`,
                backgroundSize: 'cover',
                backgroundPosition: '0 70%',
              }}
            />
            <div className="px-6 pb-6">
              <Outlet />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <LocationStepsTile selected={3} />
          <QuestionTile
            className="bg-secondary text-white"
            btnClassName="bg-primary text-white"
            showNumber
          />
        </div>
      </div>
      <div className="flex w-full flex-col gap-y-4 overflow-y-scroll lg:hidden">
        <RentResultTile />
        <div className="flex flex-col gap-y-4 px-2">
          <div className="flex flex-col gap-y-4  rounded-2xl border border-grey2 bg-white p-6">
            <ProgressBar totalSteps={5} currentStep={5} />
            <Outlet />
          </div>
          <LocationStepsTile selected={3} />
          <QuestionTile />
        </div>
      </div>
    </div>
  </>
);
export default LocationFinalLayout;
