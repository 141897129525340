import { type SVGProps } from 'react';

const Phone = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none" {...props}>
    <path
      fill="currentColor"
      d="M5.463 6.231A6.158 6.158 0 0 0 7.77 8.537l.515-.723a.583.583 0 0 1 .755-.172c.825.45 1.736.722 2.674.795a.584.584 0 0 1 .537.582v2.603a.583.583 0 0 1-.524.58 9.042 9.042 0 0 1-9.928-9.928.583.583 0 0 1 .58-.524h2.603a.583.583 0 0 1 .582.537c.073.938.344 1.849.795 2.674a.583.583 0 0 1-.172.754l-.723.516Zm-1.47-.383L5.1 5.056a7.822 7.822 0 0 1-.64-2.14H2.922a7.874 7.874 0 0 0 8.16 8.16V9.54a7.823 7.823 0 0 1-2.138-.64l-.792 1.109a7.264 7.264 0 0 1-.926-.438l-.034-.02a7.324 7.324 0 0 1-2.743-2.742l-.02-.034a7.26 7.26 0 0 1-.437-.926Z"
    />
  </svg>
);
export default Phone;
