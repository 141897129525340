import { type SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M25.334 29.334H6.667a4 4 0 0 1-4-4V4A1.333 1.333 0 0 1 4 2.667h18.667A1.333 1.333 0 0 1 24 4v9.334h5.334v12a4 4 0 0 1-4 4ZM24 16v9.334a1.334 1.334 0 0 0 2.667 0V16H24ZM6.667 8v8h8V8h-8Zm0 9.334V20H20v-2.666H6.667Zm0 4V24H20v-2.666H6.667Zm2.667-10.667H12v2.667H9.334v-2.667Z"
    />
  </svg>
);
export default SvgComponent;
