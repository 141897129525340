import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { type LeasingContract, type Project } from '@/apis';
import { solvencyUrl } from '@/apis/location';
import { useUpdateProjectLeasingContract } from '@/apis/simulation/hooks';
import { ArrowRight, LoaderCircle } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button, InfoCard, ListSelect } from '@/components/ui';
import { useLocalProject } from '@/services/project';

type Step5Form = {
  bankSeniority: NonNullable<Project['leasingContract']['bankSeniority']>;
  ownershipSeniority: NonNullable<Project['leasingContract']['ownershipSeniority']>;
  workSeniority: NonNullable<Project['leasingContract']['workSeniority']>;
};

const getDefaultValues = (data?: Project['leasingContract']) => ({
  bankSeniority: data?.bankSeniority ?? 0,
  ownershipSeniority: data?.ownershipSeniority ?? 0,
  workSeniority: data?.workSeniority ?? 0,
});

const Seniority = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { data, mutate } = useLocalProject();
  const defaultValues = getDefaultValues(data?.leasingContract);
  const { mutateAsync: updateLeasingContract } = useUpdateProjectLeasingContract();

  const { watch, setValue, formState, handleSubmit, register } = useForm<Step5Form>({
    mode: 'onTouched',
    defaultValues,
    values: defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        bankSeniority: yup.number().required(t('required')).positive().integer(),
        ownershipSeniority: yup.number().required(t('required')).positive().integer(),
        workSeniority: yup.number().required(t('required')).positive().integer(),
      })
    ),
  });

  const onSubmit = async (formData: Step5Form) => {
    setIsLoading(true);
    await updateLeasingContract({
      projectId: data?._id ?? '',
      data: {
        leasingContract: {
          bankSeniority: formData.bankSeniority,
          ownershipSeniority: formData.ownershipSeniority,
          workSeniority: formData.workSeniority,
        },
      },
    });
    mutate({
      leasingContract: {
        ...data?.leasingContract,
        bankSeniority: formData.bankSeniority,
        ownershipSeniority: formData.ownershipSeniority,
        workSeniority: formData.workSeniority,
      } as LeasingContract,
    });
    window.location = solvencyUrl(data?._id ?? '') as unknown as Location;
  };

  return (
    <AnimatedPage>
      <h1 className="text-xl font-semibold text-black">{t('finalize-your-folder')}</h1>
      <p className="text-sm text-ghost">{t('finalize-your-folder-subtitle')}</p>
      <div className="mb-8 flex flex-col flex-wrap gap-8 lg:flex-row">
        <div className="flex-1">
          <h2 className="mt-2 text-sm font-medium text-black ">{t('how-old-is-your-house')}</h2>
          <ListSelect
            {...register('bankSeniority')}
            aria-label={t('how-old-is-your-house')}
            // Less than 5, from 5 to 20, from 20 to 40, more than 40
            items={[
              { name: t('less-than-5-years'), id: '5' },
              { name: t('from-5-to-20-years'), id: '20' },
              { name: t('from-20-to-40-years'), id: '40' },
              { name: t('more-than-40-years'), id: '41' },
            ]}
            selectedKey={watch('bankSeniority')?.toString()}
            onSelect={(id) => {
              setValue('bankSeniority', Number(id), {
                shouldValidate: true,
              });
            }}
            aria-errormessage={formState.errors.bankSeniority?.message}
            aria-details={t('bank-seniority-details')}
          />
        </div>
        <div className="flex-1">
          <h2 className="mt-2 text-sm font-medium text-black ">{t('ownership-seniority')}</h2>

          <ListSelect
            {...register('ownershipSeniority')}
            className="flex-1 shrink-0"
            aria-label={t('ownership-seniority')}
            items={[
              { name: t('less-than-5-years'), id: '5' },
              { name: t('from-5-to-20-years'), id: '20' },
              { name: t('from-20-to-40-years'), id: '40' },
              { name: t('more-than-40-years'), id: '41' },
            ]}
            selectedKey={watch('ownershipSeniority')?.toString()}
            onSelect={(id) => {
              setValue('ownershipSeniority', Number(id), {
                shouldValidate: true,
              });
            }}
            aria-errormessage={formState.errors.ownershipSeniority?.message}
            aria-details={t('ownership-seniority-details')}
          />
        </div>
      </div>
      <h2 className="mt-2 text-sm font-medium text-black ">{t('work-seniority')}</h2>
      <ListSelect
        {...register('workSeniority')}
        className="lg:self-start"
        aria-label={t('work-seniority')}
        items={[
          { name: t('less-than-5-years'), id: '5' },
          { name: t('from-5-to-20-years'), id: '20' },
          { name: t('from-20-to-40-years'), id: '40' },
          { name: t('more-than-40-years'), id: '41' },
        ]}
        selectedKey={watch('workSeniority')?.toString()}
        onSelect={(id) => {
          setValue('workSeniority', Number(id), {
            shouldValidate: true,
          });
        }}
        aria-errormessage={formState.errors.workSeniority?.message}
        aria-details={t('work-seniority-details')}
      />

      <InfoCard level="warning" border={false}>
        <p className="text-sm font-medium text-black">{t('finalizing-accept-policy')}</p>
      </InfoCard>

      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={() => {
            navigate('/location/step-4');
          }}
        />
        <Button
          label={t('next-step')}
          className={'mt-4 bg-primary text-white'}
          isDisabled={!formState?.isValid}
          onPress={() => {
            void handleSubmit(onSubmit)();
          }}
          PostIcon={(props) =>
            isLoading ? <LoaderCircle {...props} /> : <ArrowRight {...props} />
          }
        />
      </div>
    </AnimatedPage>
  );
};
export default Seniority;
