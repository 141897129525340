import { GoogleMapsContext } from '@vis.gl/react-google-maps';
import { useContext, useEffect, useRef } from 'react';

export function Arrow(props: { path: google.maps.LatLngLiteral[] }) {
  const map = useContext(GoogleMapsContext)?.map;
  const arrow = useRef<google.maps.Polyline>(
    new google.maps.Polyline({
      path: [
        { lat: 22.291, lng: 153.027 },
        { lat: 18.291, lng: 153.027 },
      ],
      icons: [
        {
          icon: {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          },
          offset: '100%',
        },
      ],
      // Yellow
      strokeColor: ' #f39200',
      map,
    })
  );
  useEffect(() => {
    const { current } = arrow;
    current.setMap(map!);

    return () => {
      current.setMap(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    arrow.current.setPath(props.path);
  }, [props.path]);

  return null;
}
