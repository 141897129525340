import {
  useMutation,
  useQuery,
  type UseQueryResult,
  type UseQueryOptions,
  type UseMutationResult,
  type UseMutationOptions,
} from '@tanstack/react-query';
import { type LocationProjectResponse } from '@/apis/location/models';
import {
  createDeal,
  warmup,
  startFunnel,
  contractGenerated,
  leasingDealInfo,
  signatureUrl,
  mandatUrl,
  solvencyUrl,
  mortgage,
  getQueryKey,
} from '@/apis/location/queries';

// Hook for creating a deal
export const useCreateDeal = (
  options?: UseMutationOptions<LocationProjectResponse, unknown, string>
): UseMutationResult<LocationProjectResponse, unknown, string> =>
  useMutation<LocationProjectResponse, unknown, string>({
    mutationFn: async (projectId: string) => await createDeal(projectId),
    ...options,
  });

// Hook for warming up
export const useWarmup = (
  options?: UseMutationOptions<any, unknown, void>
): UseMutationResult<any, unknown, void> =>
  useMutation<any, unknown>({
    mutationFn: async () => {
      await warmup();
    },
    ...options,
  });

// Hook for starting a funnel
export const useStartFunnel = (
  options?: UseMutationOptions<LocationProjectResponse, unknown, string>
): UseMutationResult<LocationProjectResponse, unknown, string> =>
  useMutation<LocationProjectResponse, unknown, string>({
    mutationFn: async (projectId: string) => await startFunnel(projectId),
    ...options,
  });

// Hook for telling the location backend that the contract is generated
export const useContractGenerated = (
  options?: UseMutationOptions<LocationProjectResponse, unknown, string>
): UseMutationResult<LocationProjectResponse, unknown, string> =>
  useMutation<LocationProjectResponse, unknown, string>({
    mutationFn: async (projectId: string) => await contractGenerated(projectId),
    ...options,
  });

// Hook for getting the leasing deal information
export const useLeasingDealInfo = (
  projectId: string,
  options?: Partial<UseQueryOptions<LocationProjectResponse>>
): UseQueryResult<LocationProjectResponse> =>
  useQuery<LocationProjectResponse>({
    queryKey: getQueryKey('leasingDealInfo', projectId),
    queryFn: async () => await leasingDealInfo(projectId),
    ...options,
  });

// Hook for getting the signature URL
export const useSignatureUrl = (
  projectId: string,
  options?: UseQueryOptions<string>
): UseQueryResult<string> =>
  useQuery<string>({
    queryKey: getQueryKey('signatureUrl', projectId),
    queryFn: () => signatureUrl(projectId),
    ...options,
  });

// Hook for getting the mandat URL
export const useMandatUrl = (
  projectId: string,
  options?: UseQueryOptions<string>
): UseQueryResult<string> =>
  useQuery<string>({
    queryKey: getQueryKey('mandatUrl', projectId),
    queryFn: () => mandatUrl(projectId),
    ...options,
  });

// Hook for getting the solvency URL
export const useSolvencyUrl = (
  projectId: string,
  options?: UseQueryOptions<string>
): UseQueryResult<string> =>
  useQuery<string>({
    queryKey: getQueryKey('solvencyUrl', projectId),
    queryFn: () => solvencyUrl(projectId),
    ...options,
  });

// Hook for getting mortgage information
export const useMortgage = (
  queryString: string,
  options?: UseQueryOptions<LocationProjectResponse>
): UseQueryResult<LocationProjectResponse> =>
  useQuery<LocationProjectResponse>({
    queryKey: getQueryKey('mortgage', queryString),
    queryFn: async () => await mortgage(queryString),
    ...options,
  });
