import { type SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M9.5 17a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15Zm-.748-4.5 5.303-5.303-1.06-1.06-4.243 4.242L6.63 8.257l-1.06 1.06L8.752 12.5Z"
    />
  </svg>
);
export default SvgComponent;
