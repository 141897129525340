import Lottie from 'lottie-react';
import animationData from './loader.json';

const LottieAnimation = () => (
  <div>
    <Lottie animationData={animationData} loop={true} autoPlay={true} className="size-20" />
  </div>
);

export default LottieAnimation;
