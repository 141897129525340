import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { SelfConsumptionPayment, type Project } from '@/apis';
import { useUpdateProjectLeasingContract } from '@/apis/simulation/hooks';
import { ArrowRight, LoaderCircle } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button, InfoCard, ListSelect } from '@/components/ui';
import { useLocalProject } from '@/services/project';

type Step2Form = {
  selfConsumptionPayment: NonNullable<Project['leasingContract']['selfConsumptionPayment']>;
};

const AutoConsumptionPayment = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: localProjectData, mutate } = useLocalProject();
  const { mutateAsync: updateLeasingContract, isPending: isUpdatingLeasingContract } =
    useUpdateProjectLeasingContract();
  const isLoading = isUpdatingLeasingContract;
  const { watch, setValue, formState, handleSubmit } = useForm<Step2Form>({
    mode: 'onTouched',
    defaultValues: localProjectData?.leasingContract,
    resolver: yupResolver(
      yup.object().shape({
        selfConsumptionPayment: yup
          .string()
          .required(t('required'))
          .oneOf(Object.values(SelfConsumptionPayment)),
      })
    ),
  });

  const onSubmit = async (data: {
    selfConsumptionPayment: Project['leasingContract']['selfConsumptionPayment'];
  }) => {
    if (!localProjectData) {
      return;
    }
    try {
      await updateLeasingContract({
        projectId: localProjectData?._id ?? '',
        data: {
          leasingContract: {
            selfConsumptionPayment: data.selfConsumptionPayment,
          },
        },
      });
      mutate({
        leasingContract: {
          ...localProjectData?.leasingContract,
          selfConsumptionPayment: data.selfConsumptionPayment,
        },
      });
      navigate('/location/step-3');
    } catch (error) {
      console.error(error);
      navigate('/location/error');
    }
  };

  return (
    <AnimatedPage>
      <h1 className="text-xl font-semibold text-black">{t('autoconsomation-payment-choice')}</h1>
      <p className="text-sm text-ghost">{t('autoconsomation-payment-choice-subtitle')}</p>
      <InfoCard>
        <p className="text-sm font-medium">{t('autoconsomation-payment-choice-description')}</p>
      </InfoCard>

      <ListSelect
        selectedBgColor="bg-secondary"
        selectedBorderColor="border-secondary"
        aria-label={t('choose-an-option')}
        items={[
          {
            name: t('one-time-payment'),
            description: t('one-time-payment-description'),
            id: SelfConsumptionPayment.ANNUAL,
          },
          {
            name: t('twelve-times-payment'),
            description: t('twelve-times-payment-description'),
            id: SelfConsumptionPayment.MONTHLY,
          },
        ]}
        selectedKey={watch('selfConsumptionPayment')}
        onSelect={(id: string) => {
          setValue('selfConsumptionPayment', id as Step2Form['selfConsumptionPayment'], {
            shouldValidate: true,
          });
        }}
        errorMessage={formState.errors.selfConsumptionPayment?.message}
      />

      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={() => {
            navigate('/location/step-1');
          }}
        />
        <Button
          label={t('next-step')}
          className={'mt-4 self-end bg-primary text-white'}
          onPress={() => {
            void handleSubmit(onSubmit)();
          }}
          isDisabled={isLoading}
          PostIcon={(props) =>
            isLoading ? <LoaderCircle {...props} /> : <ArrowRight {...props} />
          }
        />
      </div>
    </AnimatedPage>
  );
};
export default AutoConsumptionPayment;
