import { useTranslation } from 'react-i18next';
import { logo } from '@/assets';
import { Button } from '@/components';
import { Phone } from '@/components/icons';
import { cn } from '@/helpers';

const Header = ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        'flex w-full flex-row items-center justify-between bg-white px-4 py-3',
        className
      )}>
      <a href="https://www.soleriel.fr" target="_blank" rel="noreferrer">
        <img src={logo} alt="Logo" className="h-5" />
      </a>
      <Button
        className="bg-primary text-white"
        label="09 80 80 40 57"
        PreIcon={(props) => <Phone {...props} />}
        onPress={() => window.open(`tel:${t('soleriel-phone-number')}`)}
      />
    </div>
  );
};
export default Header;
