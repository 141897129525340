import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import Document from '../Document/Document';
import {
  ArrowRightUp,
  CheckCircle,
  CloseCircle,
  HomeSmile,
  LoaderCircle,
} from '@/components/icons';

const ContractSignature = ({
  status,
  isContractLoading,
  isBtnLoading,
  onDocClick,
  onBtnClick,
}: {
  status: 'signed' | 'not-signed' | undefined;
  isContractLoading?: boolean;
  isBtnLoading?: boolean;
  onDocClick?: () => void;
  onBtnClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col items-center justify-center gap-10 rounded-lg border border-grey2 bg-grey3 lg:flex-row">
      <div
        role="button"
        tabIndex={0}
        onKeyDown={onDocClick}
        onClick={onDocClick}
        className="flex max-h-40 justify-center overflow-hidden pt-4 lg:max-h-none
lg:w-1/2">
        <Document Icon={HomeSmile} title={t('rent-contract')} />
      </div>
      <div className="flex  flex-col items-center justify-center gap-1 p-4 ">
        <h2 className="text-base font-medium text-black/60">{t('rent-contract')}</h2>
        {isContractLoading ? (
          <>
            <LoaderCircle className="size-5 text-primary" />
            <span className="self-center text-center text-primary">
              {t('your-contract-is-being-generated')}
            </span>
            <span className="self-center text-xs text-grey1">{t('it-may-take-a-few-moments')}</span>
          </>
        ) : status === 'signed' ? (
          <>
            <p className="flex items-center gap-1 text-base font-medium text-[#52C100]">
              {t('contract-signed')}
              <CheckCircle className="size-5 text-[#52C100]" />
            </p>
            <p className="max-w-[233px] text-center text-sm text-black/30">
              {t('contract-signed-desc')}
            </p>
          </>
        ) : status === 'not-signed' ? (
          <>
            <p className="flex items-center gap-1 text-base font-medium text-[#FF2020]">
              {t('contract-not-signed')}
              <CloseCircle className="size-5 text-[#FF2020]" />
            </p>

            <Button
              className="mt-4 w-full bg-black text-center text-white"
              label={t('sign-contract')}
              PostIcon={(props) =>
                isBtnLoading ? <LoaderCircle {...props} /> : <ArrowRightUp {...props} />
              }
              onPress={onBtnClick}
            />
          </>
        ) : (
          <>
            <LoaderCircle className="size-5 text-primary" />
          </>
        )}
      </div>
    </div>
  );
};
export default ContractSignature;
