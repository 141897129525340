import React, { type ReactNode } from 'react';
import { trackError } from '@/services/error';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorWrapper extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    void trackError('Rendering issue', {
      error: JSON.stringify(error),
      info: JSON.stringify(info),
    });
  }

  render() {
    return this.props.children;
  }
}

export default ErrorWrapper;
