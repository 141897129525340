import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { InverterPosition, SwitchboardPosition } from '@/apis';
import { inverter } from '@/assets';
import { ListSelect } from '@/components';
import { ArrowRight } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button, InfoModal } from '@/components/ui';
import { type Simulation, useLocalProject } from '@/services/project';

type SwitchboardForm = {
  switchboardPosition: Simulation['electricInstallation']['switchboardPosition'];
  inverterWantedPosition: Simulation['electricInstallation']['inverterWantedPosition'];
};

const getDefaultValues = (installationData?: Simulation) => ({
  switchboardPosition: installationData?.electricInstallation?.switchboardPosition,
  inverterWantedPosition: installationData?.electricInstallation?.inverterWantedPosition,
});

const Switchboard = ({
  nextRoute,
  previousRoute,
}: {
  nextRoute: string;
  previousRoute: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { installationData, mutateInstallationData } = useLocalProject();
  const defaultValues = getDefaultValues(installationData);

  const { watch, setValue, formState, handleSubmit } = useForm<SwitchboardForm>({
    defaultValues,
    values: defaultValues as SwitchboardForm,
    resolver: yupResolver(
      yup.object().shape({
        switchboardPosition: yup
          .string()
          .required(t('required-select'))
          .oneOf(Object.values(SwitchboardPosition)),
        inverterWantedPosition: yup
          .string()
          .required(t('required-select'))
          .oneOf(Object.values(InverterPosition)),
      })
    ),
  });

  const onSubmit = (data: SwitchboardForm) => {
    mutateInstallationData({
      electricInstallation: {
        ...installationData?.electricInstallation,
        ...data,
      } as Simulation['electricInstallation'],
    });
    navigate(nextRoute);
  };
  return (
    <AnimatedPage>
      <h1 className="text-lg font-semibold text-black">{t('your-switchboard')}</h1>
      <h2 className="mt-2 flex gap-2 text-sm font-medium text-black">
        {t('where-is-your-switchboard')}
      </h2>

      <ListSelect
        aria-label={t('where-is-your-switchboard')}
        items={[
          { name: t('garage'), id: SwitchboardPosition.GARAGE },
          { name: t('entrance'), id: SwitchboardPosition.ENTRANCE },
          { name: t('kitchen'), id: SwitchboardPosition.KITCHEN },
          { name: t('other'), id: SwitchboardPosition.OTHER },
        ]}
        selectedKey={watch('switchboardPosition')}
        onSelect={(id: string) => {
          setValue('switchboardPosition', id as SwitchboardForm['switchboardPosition'], {
            shouldValidate: true,
          });
        }}
        errorMessage={formState.errors.switchboardPosition?.message}
      />

      <h2 className="mt-2 flex gap-2 text-sm font-medium text-black">
        {t('choose-the-location-of-your-inverter')}
        <InfoModal>
          <p className="text-left text-sm">{t('inverter-position-description')}</p>
          <img src={inverter} alt="onduleur" className="w-60" />
        </InfoModal>
      </h2>
      <ListSelect
        aria-label={t('choose-the-location-of-your-inverter')}
        items={[
          { name: t('near-switchboard'), id: InverterPosition.NEAR_SWITCHBOARD },
          { name: t('other'), id: InverterPosition.OTHER },
        ]}
        selectedKey={String(watch('inverterWantedPosition'))}
        onSelect={(id) => {
          setValue('inverterWantedPosition', id as SwitchboardForm['inverterWantedPosition'], {
            shouldValidate: true,
          });
        }}
        errorMessage={formState.errors.inverterWantedPosition?.message}
      />

      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={() => {
            navigate(previousRoute);
          }}
        />
        <Button
          label={t('next-step')}
          className={'mt-4 self-end bg-primary text-white'}
          onPress={() => {
            void handleSubmit(onSubmit)();
          }}
          PostIcon={(props) => <ArrowRight {...props} />}
        />
      </div>
    </AnimatedPage>
  );
};
export default Switchboard;
