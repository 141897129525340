import { useRef, useState, useEffect, Dispatch, SetStateAction } from 'react';

/**
 * This hook initialize a value with async data at mount time and no update after
 */
export const useInitialize = <T>(data: T) => {
  const initialized = useRef(false);
  const [initializedData, setInitializedData] = useState<T | null>(null);
  useEffect(() => {
    if (!initialized.current) {
      setInitializedData(data);
      initialized.current = true;
    }
  }, [data]);
  return [initializedData, setInitializedData] as [T, Dispatch<SetStateAction<T>>];
};
