// This file is generated by generateTypeFile.js, do not update it directly
// @ts-nocheck
export interface DefaultVariableCalculators {
}




export interface HandlerUtils {
}

export enum CarPortModel {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}



export enum ElectricPurposeKey {
  AUTO_CONSUMPTION = "AUTO_CONSUMPTION",
  RESELL_ONLY = "RESELL_ONLY"
}
export enum EquipmentKey {
  FREEZER = "FREEZER",
  SWIMMING_POOL = "POOL",
  AIR_CONDITIONING = "AIR_CONDITIONING",
  HOUSE_APPLIANCES = "HOUSE_APPLIANCES",
  HOUSE_HEATING = "HOUSE_HEATING",
  WATER_HEATING = "WATER_HEATING"
}
export enum HeatingSystemKey {
  ELECTRIC = "ELECTRIC",
  HEAT_PUMP = "HEAT_PUMP",
  MIXED = "MIXED",
  OTHER = "OTHER"
}
export enum InverterModelKey {
  MICRO = "MICRO",
  CENTRALISED = "CENTRALISED"
}
export enum MaintenanceCostKey {
  MAINTENANCE_3000 = "MAINTENANCE_3000",
  MAINTENANCE_9000 = "MAINTENANCE_9000",
  MAINTENANCE_36000 = "MAINTENANCE_36000",
}
export enum OfferKey {
  STANDARD_CASH_V1 = "STANDARD_CASH_V1",
  STANDARD_LEASING_V1 = "STANDARD_LEASING_V1",
  CAR_PORT_CASH_V1 = "CAR_PORT_CASH_V1",
}
export enum PanelModelKey {
  LONGI = "LONGI",
  DUONERGY = "DUONERGY"

}


export enum WaterHeatingSystemKey {
  ELECTRIC = "ELECTRIC",
  THERMODYNAMIC = "THERMODYNAMIC",
  OTHER = "OTHER"
}
export interface IEnum extends IObject {
  [IEnum]: boolean;
  key: string | number;
}
export interface XEnumManager {
  enumClassName: string;
  enumClass: Class<TEnum>;
}

export interface XEnumValue extends XObject {
  key: string | number;
  [IEnum]: boolean;
}

export interface Address {
  city: string;
  countryCode: string;
  latitude: number;
  longitude: number;
  postalCode: string;
  street: string;
  number?: string;
  description: string;
  placeId: string;
}

export interface CashContract {
  csvUrl: string | undefined;
  contractUrl: string | undefined;
  offerKitType: OfferKitType | undefined;
}

export interface Economy {
  producibleKwhPerKwc: number;
  installationData: InstallationData;
  offerKit: OfferKit<CashOfferKit | CarPortKit>;
  electricityResalePriceEur: number;
  electricityPriceEur: number;
  productionWcByYear: number;
  co2: number;
  co230years: number;
  selfConsumptionRate: number;
  autonomyRate: number;
  forecastProductionByMonth: number[];
  productionTable30years: number[];
  totalProduction30years: number;
  selfConsumptionTable30years: number[];
  selfConsumption30years: number;
  selfConsumptionTable30yearsEco: number[];
  selfConsumption30yearsEco: number;
  electricitySold30years: number[];
  electricitySold: number;
  electricitySold30yearsEco: number[];
  electricitySoldEco: number;
  earningTable: number[];
  totalEarning: number;
  earningTableEco: number[];
  totalEarningEco: number;
  yearlyMaintenanceCost: number;
  totalMaintenanceCost: number;
  earningTableLessMaintenanceCost: number[];
  totalEarningLessMaintenanceCost: number;
  earningTableLessMaintenanceCostEco: number[];
  totalEarningLessMaintenanceCostEco: number;
  potentialSaving: number[];
  potentialSavingEco: number[];
  totalPotentialSaving: number;
  totalPotentialSavingEco: number;
  economy30years: number;
  economy30yearsWithEcoGesture: number;
  economy30yearsTable: number[];
  economy30yearsWithEcoGestureTable: number[];
  billSavings: number;
  billSavingsWithEcoGesture: number;
  billSavingsTable: number[];
  billSavingsWithEcoGestureTable: number[];
  energyPaybackTime: number;
}

export enum ConsumptionUnit {
  EURO = "EURO",
  KWH = "KWH"
}
export interface ElectricConsumption {
  consumptionUnit: ConsumptionUnit;
  annualElectricConsumption: number;
}

export interface GeoPoint {
  latitude: number;
  longitude: number;
}
export interface HomeDescription {
  nbrPersons: number;
  houseAgeY: number;
  equipmentKeys: EquipmentKey[];
  heatingSystemKey: HeatingSystemKey;
  waterHeatingSystemKey: WaterHeatingSystemKey;
  garden: boolean;
  heatedSurfaceM2: number;
}

export interface IBaseEntity {
  _id: string;
}
export interface IContractDocument {
  csvUrl?: string;
  contractUrl?: string;
  offerKitKey?: string;
}
export interface ISurface {
  lengthM: number;
  widthM: number;
}
export interface InstallationData {
  source: SourceKey | null | undefined;
  address: Address;
  solarSurface: SolarSurface;
  homeDescription: HomeDescription;
  electricPurpose: ElectricPurposeKey;
  supportBuilding: SupportBuilding;
  electricInstallation: ElectricInstallation;
  electricConsumption: ElectricConsumption;
}

export interface LeasingContract {
  csvUrl: string | undefined;
  contractUrl: string | undefined;
  tempContractUrl: string | undefined;
  offerKitType: OfferKitType | undefined;
  selfConsumptionPayment: SelfConsumptionPayment | undefined;
  contractSignatureDate: Date | undefined;
  warrantyDepositDate: Date | undefined;
  directDebitAuthorizationDate: Date | undefined;
  bankSeniority: number | undefined;
  ownershipSeniority: number | undefined;
  workSeniority: number | undefined;
}

export interface LeasingOfferKitImportRequest {
  fileData: string[];
  formattedDate: Date;
  panelModelKey: PanelModelKey;
  offerKey: OfferKey;
  inverterModelKey: InverterModelKey;
  date: string;
}

export interface LeasingOfferKitImportResponse {
  result: string;
}

export interface Person {
  birthDate: string;
  civility: Civility;
  email: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  owner: boolean;
}

export interface Project {
  _id: string;
  installationData: InstallationData;
  solarSolutions: SolarSolution[];
  persons: Person[];
  leasingContract: LeasingContract;
  cashContract: CashContract;
  createdAt: Date;
  updatedAt: Date;
}

export interface SolarExposure {
  latitude: number;
  longitude: number;
  panelNbr: number;
  azimutDegree: number;
  inclinationDegree: number;
  producibleKwhPerKwc: number;
  producibleMonthly: number[];
}

export interface SolarSolution {
  _id: string;
  projectId: string;
  quotations: Quotation[];
  simulationInput: InstallationData;
  createdAt: Date;
  cashSolutions: Quotation[];
  leasingSolutions: Quotation[];
  carPortSolutions: Quotation[];
}

export interface SolarSurface extends SolarExposure {
  points: GeoPoint[];
  columnNbr: number;
  lineNbr: number;
  roofSurfaceM2: number;
}

export interface SqsMessageBase {
  action: SqsAction;
}
export interface GeneratePdfMessage extends SqsMessageBase {
  action: SqsPdfAction;
  projectId: string;
}
export interface GenerateCsvMessage extends SqsMessageBase {
  action: SqsCsvAction;
  projectId: string;
}
export interface WaitForPDFsMessage extends SqsMessageBase {
  action: SqsPdfAction.WAIT_FOR_PDFS;
  projectId: string;
}
export interface SendMailMessage extends SqsMessageBase {
  action: SqsEmailAction;
  projectId: string;
  email: EmailKey;
}
export interface CreatePipePersonMessage {
  action: 'createPipePerson';
  projectId: string;
}
export interface UpdatePipeDealMessage {
  action: 'updatePipeDeal';
  projectId: string;
  offerKitType: OfferKitType;
}
export type SqsMessage = GeneratePdfMessage | GenerateCsvMessage | SendMailMessage | CreatePipePersonMessage | UpdatePipeDealMessage;
export interface ISupportBuilding {
  roofType: RoofType;
  covertTypeKey: CovertType;
  model: CarPortModel;
}
export interface SupportBuilding {
  roofType: RoofType;
  covertTypeKey: CovertType;
  model: CarPortModel;
  productType: ProductType;
  covertType: CovertTypeReference | null | undefined;
}

export interface SystemManagerConfig {
  MONGODB_URI: string;
  BREVO_API_KEY: string;
  ADMIN_EMAIL: string;
  PIPEDRIVE_API_KEY: string;
}

export interface ICarPortKit extends IOfferKit, IReferenceRaw {
  shelterCostHt: number;
  panelInstallationCostHt: number;
  surfaceM2: number;
  dimX: number;
  dimY: number;
  size: CarPortModel;
  singleFacadeCladdingTTC: number;
  doubleFacadeCladdingTTC: number;
  southFacadeCladdingTTC: number;
  northFacadeCladdingTTC: number;
  groundAnchoringTTC: number;
  inverterUnderShelterTTC: number;
}
export interface CarPortKit extends OfferKit {
  key: string | number;
  shelterCostHt: number;
  panelInstallationCostHt: number;
  surfaceM2: number;
  dimX: number;
  dimY: number;
  size: CarPortModel;
  singleFacadeCladdingTTC: number;
  doubleFacadeCladdingTTC: number;
  southFacadeCladdingTTC: number;
  northFacadeCladdingTTC: number;
  groundAnchoringTTC: number;
  inverterUnderShelterTTC: number;
  "[unknown]": boolean;
}

export interface CarPortModelManager extends XReferenceBaseManager {
  enumToStringTransformer: any;
}

export interface ICashOfferKit extends IReferenceRaw, IOfferKit {
  totalCostHT: number;
  integrationIntoBuilding: number;
}
export interface CashOfferKit extends OfferKit {
  key: string | number;
  totalCostHT: number;
  integrationIntoBuilding: number;
  "[unknown]": boolean;
}

export interface CashOfferKitManager extends XReferenceBaseManager {
  enumToStringTransformer: any;
}

export enum Civility {
  MISS = 'Miss',
  MR = 'Mr',
  MRS = 'Mrs'
}
export enum CovertType {
  TUILES = "TUILES",
  TUILES_PLATES = "TUILES_PLATES",
  ARDOISES = "ARDOISES",
  BACS_ACIER = "BACS_ACIER",
  PLAQUES_FIBRO_CIMENT = "PLAQUES_FIBRO_CIMENT"
}
export interface IEnumData {
  key: string | number;
}
export interface IDepartment extends IEnumData {
  inclination: string;
  coeffPente: number;
}
export interface Department extends XEnumValue {
  inclination: string;
  coeffPente: number;
}

export interface DepartmentManager extends XEnumManager {
  enumToStringTransformer: any;
}

export enum ElectricalSubscriptionType {
  BASE = "BASE",
  OFF_PEAK_HOURS = "OFF_PEAK_HOURS"
}
export enum InverterPosition {
  NEAR_SWITCHBOARD = "NEAR_SWITCHBOARD",
  OTHER = "OTHER"
}
export interface ElectricInstallation {
  electricType: ElectricType;
  subscriptionPower: number;
  typeOfContract: ElectricalSubscriptionType;
  switchboardPosition: SwitchboardPosition;
  inverterWantedPosition: InverterPosition;
}

export enum ElectricType {
  MONOPHASED = "MONOPHASED",
  TRIPHASED = "TRIPHASED",
  UNKNOWN = "UNKNOWN"
}
export enum EmailKey {
  SEND_SIMULATION_RESULTS_TO_ADMIN = "sendSimulationResultsToAdmin",
  SEND_SIMULATION_RESULTS_TO_CLIENT = "sendSimulationResultsToClient",
  SEND_LEASING_PDF_TO_CLIENT = "sendLeasingPdfToClient",
}
export interface IEquipment extends IReferenceRaw {
  consumptionDuringSunProductionPercent: number;
  avgConsumptionPowerWh: number;
}
export interface Equipment extends XReferenceValue {
  consumptionDuringSunProductionPercent: number;
  avgConsumptionPowerWh: number;
}

export interface EquipmentManager extends XReferenceBaseManager {
  enumToStringTransformer: any;
}

export enum FinancingType {
  CASH = "CASH",
  LEASING = "LEASING"
}
export interface IHeatingSystem extends IEnumData {
  electricConsumptionPercent: number
}
export interface HeatingSystem extends XEnumValue {
  electricConsumptionPercent: number;
}

export interface HeatingSystemManager extends XEnumManager {
  enumToStringTransformer: any;
}

export interface IInverterModel extends IReferenceRaw {
  name: string;
}
export interface InverterModelReference extends XReferenceValue {
  name: string;
}

export interface InverterModelManager extends XReferenceBaseManager {
  enumToStringTransformer: any;
}

export interface ILeasingOfferKit extends IReferenceRaw, IOfferKit, IBaseEntity {
  producibleKwh: number;
  rent: number;
  economy30years: number;
  economy30yearsWithEcoActions: number;
  annualCreditRate: number;
}
export interface LeasingOfferKit extends OfferKit {
  _id: string;
  key: string | number;
  producibleKwh: number;
  rent: number;
  economy30years: number;
  economy30yearsWithEcoActions: number;
  annualCreditRate: number;
  "[unknown]": boolean;
}

export interface IMaintenanceCosts extends IReferenceRaw {
  installationPowerWc: number;
  turpeTaxEur: number;
  insuranceEur: number;
  inverterEur: number;
}
export interface MaintenanceCost extends XReferenceValue {
  installationPowerWc: number;
  turpeTaxEur: number;
  insuranceEur: number;
  inverterEur: number;
  total: number;
}

export interface MaintenanceCostsManager extends XReferenceBaseManager {
  enumToStringTransformer: any;
}

export interface IOffer extends IEnumData {
  name: string;
  productType: ProductType,
  financingType: FinancingType,
  panelModelKey: PanelModelKey,
  electricPurposeKeys: ElectricPurposeKey[],
  startDateRaw: Date | null;
  endDateRaw: Date | null;
}
export interface Offer extends XReferenceTimeIntervalValue {
  name: string;
  productType: ProductType;
  financingType: FinancingType;
  panelModelKey: PanelModelKey;
  electricPurposeKeys: ElectricPurposeKey[];
  startDateRaw: Date | null;
  endDateRaw: Date | null;
  startDate: Date;
  endDate: Date;
  panelModel: PanelModelReference;
}

export interface OfferModelManager extends XReferenceBaseManager {
  enumToStringTransformer: any;
}

export interface IOfferKit extends IEnumData {
  offerKey: OfferKey;
  panelModelKey: PanelModelKey;
  inverterModelKey: InverterModelKey;
  panelNbr: number;
  startDateRaw: Date | null;
  endDateRaw: Date | null;
}
export interface OfferKit extends XReferenceOfferKitValue {
  key: string | number;
  name: string;
  offerKey: OfferKey;
  panelModelKey: PanelModelKey;
  inverterModelKey: InverterModelKey;
  panelNbr: number;
  startDateRaw: Date | null;
  endDateRaw: Date | null;
  financingType: FinancingType;
  offer: Offer;
  panelModel: PanelModelReference;
  powerWc: number;
  powerKwc: number;
}

export enum OfferKitType {
  LEASING_OFFER_KIT = "LeasingOfferKit",
  CAR_PORT_KIT = "CarPortKit",
  CASH_OFFER_KIT = "CashOfferKit"
}
export interface IPanelModel extends IEnumData, ISurface {
  name: string;
  powerWc: number;
  lengthM: number;
  widthM: number;
}
export interface PanelModelReference extends XEnumValue {
  name: string;
  powerWc: number;
  lengthM: number;
  widthM: number;
}

export interface PanelModelManager extends XEnumManager {
  enumToStringTransformer: any;
}


export enum ProductType {
  STANDARD = "STANDARD",
  CAR_PORT = "CAR_PORT"
}
export interface IResidualValueCoefficientReference extends IReferenceRaw {
  powerWc: number;
  valuesArray: number[];
}
export interface ResidualValueCoefficientReference extends XReferenceValue {
  powerWc: number;
  valuesArray: number[];
}

export interface ResidualValueCoefficientReferenceBaseManager extends XReferenceBaseManager {
  enumToStringTransformer: any;
}

export enum RoofType {
  SLOPED_ROOF = "SLOPED_ROOF",
  FLAT_ROOF = "FLAT_ROOF"
}
export enum SelfConsumptionPayment {
  MONTHLY = 'MONTHLY',
  ANNUAL = 'ANNUAL',
}
export enum SourceKey {
  SOLERIEL = "SOLERIEL"
}
export enum SqsPdfAction {
  GENERATE_LEASING_PDF = 'generateLeasingPdf',
  GENERATE_CASH_PDF = 'generateCashPdf',
  GENERATE_TEMPORARY_LEASING_PDF = 'generateTemporaryLeasingPdf',
  WAIT_FOR_PDFS = 'waitForPdfs'
}
export enum SqsEmailAction {
  SEND_MAIL = 'sendMail'
}
export enum SqsCsvAction {
  GENERATE_CSV = 'generateCsv'
}
export enum SqsPipedriveAction {
  CREATE_PIPE_PERSON = 'createPipePerson',
  UPDATE_PIPE_DEAL = 'updatePipeDeal'
}
export type SqsAction = SqsPdfAction | SqsEmailAction | SqsCsvAction | SqsPipedriveAction;
export enum SupportType {
  SHELTER = "SHELTER",
  ROOF = "ROOF"
}
export enum SwitchboardPosition {
  GARAGE = "GARAGE",
  ENTRANCE = "ENTRANCE",
  KITCHEN = "KITCHEN",
  OTHER = "OTHER"
}
export interface IWaterHeatingSystem extends IEnumData {
  electricConsumptionPercent: number
}
export interface WaterHeatingSystem extends XEnumValue {
  electricConsumptionPercent: number;
}

export interface WaterHeatingSystemManager extends XEnumManager {
  enumToStringTransformer: any;
}

export interface XReferenceOfferKitValue extends XObject {
  key: string | number;
  [IsIReference]: boolean;
}

export enum PipedriveLabel {
  RELANCE_LABEL_ID = 111
}
export interface PipeDealViews {
}

export interface PipeDeal extends PipeObject {
  id: string | null;
  title: string;
  value: number | null;
  currency: string;
  userId: number | null;
  personId: string | null;
  pipelineId: number | null;
  stageId: PipeStageId;
  status: PipeStatus;
  addTime: Date | null;
  address: string | null;
  department: string | null;
  gpsCoordinates: string | null;
  pdfLink: string | null;
  projectId: string | null;
  nextStepUrl: string | null;
  purchaseOrderNumber: string | null;
  installedPowerKwc: number | null;
  label: PipedriveLabel | null;
  preprod: PipePreprodOptions | null;
  postponedPayment: PipePostponedPayment | null;
}

export enum PipeFields {
  PERSON_CIVILITY = 'ba7abc436d1edb34ec08b56a3209fa6b53396d3e',
  PERSON_ADDRESS = 'f29f43bf132edc6d63c7725154c6b3325cb59a7a',
  PERSON_GPS_COORDINATES = 'fedcebcf73f386794a60530994a6d7c67402b2da', //useless for facturation address ?
  PERSON_DEPARTMENT_NUMBER = '72b548231488f1d90bd437c1c18a7480c796f582', //usefull ? use this one or the one in Deal ? => I think useless, because pipedrive determine itself with address ?
  PERSON_PDF_LINK = '8b220fa9a27d71a4b9861e987849c29ef91be4fc', // use this one or the one in Deal ?
  PERSON_PURCHASE_ORDER_NUMBER = 'bfbde2a9d004482068ba3230f3a0f92cfd25796c',
  DEAL_PDF_LINK = '16e92e3950cda6dc35fe779860215715351014ed', // use this one or the one in Deal ?
  DEAL_DEPARTMENT_NAME = '682d3b344f3f39934390306f69ca89247b82ff76', //varchar
  DEAL_ADDRESS = '47f16618055c35140798676afa40b85b8c2978fb',
  DEAL_GPS_COORDINATES = 'a6f5037b251980b22d814f04748c241577016c82',
  DEAL_PURCHASE_ORDER_NUMBER = 'b13fc1efba4b2376f499b0eae71095cf1c42c4ac', // use this one or the one in Deal ?
  DEAL_INSTALLED_POWER_KWC = 'e5336c765f05ac640d940d9c26101f2359c9b592',
  DEAL_STATUS = 'b00f499ad730e095233a637de1a35332582f2ae2',
  DEAL_STATUS_DETAIL = 'd424fc7ad5f9718b3016fe087dce4ee2bce4b31b',
  DEAL_NEXT_STEP_URL = 'f068221fb76ee9c0a09ea0f64ce52229a8d0a555',
  DEAL_FIRST_PAYMENT_AMOUNT_EUR = 'a5fa9eae39b2a45cc899bfe57adc63f981e7a104',
  // currency, info à pousser ?? a5fa9eae39b2a45cc899bfe57adc63f981e7a104_currency
  DEAL_SOLVENCY_NOTE = '787063acd4a647a8414ac3da4a3d656872512426',
  DEAL_PAYMENT_COMPLETED_ID = 'd638bb40d9abe07bb063c852f6383f0ce8b103c8',
  DEAL_MANDATE_UNIQUE_REFERENCE = '57d65d45280d66528a6450d2eb0956e84b264851',
  DEAL_CONTRACT_SIGNATURE_DATE = 'f6e66fc4e070bea0c4fc7d34354fdbc7e975eea9',
  DEAL_PROJECT_ID = '9980edf8a31b13c6d0932dfcd4c50f076b03080c',
  DEAL_IS_PREPROD = '148f367460984c4acf10e662acd5f2ec75dfebe9',
  POSTPONED_PAYMENT = 'e3e3616f007d699b455fac1800313cc99def766e'
}
export enum PipePreprodOptions {
  TRUE = '119',
  FALSE = '120'
}
export enum PipePostponedPayment {
  TRUE = '121',
  FALSE = '122'
}
export enum PipelineId {
  SALES = 1
}
export enum PipeMarketingStatus {
  NO_CONSENT = 'no_consent',
  UNSUBSCRIBED = 'unsubscribed',
  SUBSCRIBED = 'subscribed',
  ARCHIVED = 'archived'
}
export interface PipeNote extends PipeObject {
  id: string | null;
  content: string;
  dealId: number;
}

export interface PipeObject {
}

export interface PipePerson extends PipeObject {
  id: string | null;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  civility: Civility;
  address: string;
  gpsCoordinates: string | null;
  department: number;
  pdfLink: string | null;
  purchaseOrderNumber: string | null;
  marketingStatus: PipeMarketingStatus;
}

export enum PipeStageId {
  //Pipe LOC - vente
  STAGE_ID_NEW_SIMULATION = 50,
  STAGE_ID_START_FUNNEL = 41,
  STAGE_ID_PDF_GENERATED = 51,
  STAGE_ID_CONTRACT_SIGNED = 40,
  STAGE_ID_PAYMENT_OK = 42,
  STAGE_ID_MANDAT_SIGNED = 43,
  STAGE_ID_SOLVENCY_OK = 44,
  STAGE_ID_SOLVENCY_REFUSED = 49,
  STAGE_ID_PROJECT_VALIDATED = 45,

  //Pipe ventes
  STAGE_ID_CONTACT_ENTRANT_DEMANDE_RAPPEL = 22,
  STAGE_ID_CONTACT_ENTRANT = 1
}
export enum PipeStatus {
  OPEN = 'open',
  WON = 'won',
  LOST = 'lost',
  DELETED = 'deleted'
}
export interface ProducibleRequest {
  latitude: number;
  longitude: number;
  azimutDegree: number;
  inclinationDegree: number;
}

export interface BonusQuotationEntry extends QuotationEntry {
  bonus: number;
  powerWc: number;
}

export interface CashQuotationEntry extends QuotationEntry {
  quantity: number;
  unitPriceEuro: number;
  isOption: boolean;
}

export enum ChargeKey {
  COVERT_TYPE = "COVERT_TYPE",
  COVERT_DEPOSE = "COVERT_DEPOSE",
  COVERT_WATERPROOFING = "COVERT_WATERPROOFING",
  SCAFFOLDING = "SCAFFOLDING",
  SCAFFOLDING_HEIGHT_SUPP = "SCAFFOLDING_HEIGHT_SUPP",
  TRIPHASE = "TRIPHASE",
  EQUIPMENT = "EQUIPMENT",
  INSTALLATION_COST_HT = "INSTALLATION_COST_HT",
  RENT = "RENT",
  INVESTMENT_BONUS = "INVESTMENT_BONUS",
  OFFER_KIT = "OFFER_KIT",
  PANEL_MODEL = "PANEL_MODEL",
  ENEDIS_CONNECTION = "ENEDIS_CONNECTION",
}
export interface ICovertTypeReference extends IReferenceRaw {
  depose: number;
  etancheite: number;
  suppAdditionalCostPerPanelEur: number;
}
export interface CovertTypeReference extends XReferenceValue {
  depose: number;
  etancheite: number;
  suppAdditionalCostPerPanelEur: number;
}

export interface CovertTypeManager extends XReferenceBaseManager {
}

export interface IElectricPurpose extends IReferenceRaw {
  installationPurpose: ElectricPurposeKey;
  installationPowerWc: number;
  resaleSurplusEur: number;
  investmentBonusEur: number;
  aerialEnedisConnectionCostHT: number;
  // tva: number;
}
export interface ElectricPurposeReference extends XReferenceValue {
  installationPurpose: ElectricPurposeKey;
  installationPowerWc: number;
  resaleSurplusEur: number;
  investmentBonusEur: number;
  aerialEnedisConnectionCostHT: number;
}

export interface ElectricPurposeReferenceBaseManager extends XReferenceBaseManager {
}

export interface LeasingQuotationEntry extends QuotationEntry {
  monthlyPriceEur: number;
}

export interface OfferKitEntry extends QuotationEntry {
}

export interface Quotation {
  offerKit: OfferKit<CashOfferKit & LeasingOfferKit & CarPortKit>;
  offerKitKey: string;
  offerKitType: OfferKitType;
  quotationEntries: QuotationEntry<BonusQuotationEntry | CashQuotationEntry | LeasingQuotationEntry | OfferKitEntry>[];
  economy: Economy;
  option: QuotationOption;
  economy30years: number;
  economy30yearsWithEco: number;
  tva: number;
  totalTva: number;
  totalHT: number;
  totalTTC: number;
  shelterCostHt: number;
  shelterCostTTC: number;
  monthlyRate: number;
  deposit: number;
  autoConsumptionBonus: number;
  panelNbr: number;
  installationPowerWc: number;
  manpowerAndAdministrativeCost: number;
  ecoParticipationCost: number;
  wasteManagementCost: number;
  enedisConnectionTTC: number;
  residualValueCoefficient: number[];
  carPortSurfaceM2: number;
  carPortDimY: number;
  carPortDimX: number;
}

export interface IQuotationEntry {
  chargeKey: string;
  resolutionContext: Object;
  className: string;
}
export interface QuotationEntry {
  className: string;
  chargeKey: string;
  resolutionContext: Object;
}

export interface QuotationOption {
  installationData: InstallationData;
  offerKit: OfferKit<CashOfferKit | CarPortKit>;
  quotationEntries: QuotationEntry<BonusQuotationEntry | CashQuotationEntry | LeasingQuotationEntry | OfferKitEntry>[];
  integrationIntoBuildingTTC: number;
  singleFacadeCladdingTTC: number;
  doubleFacadeCladdingTTC: number;
  southFacadeCladdingTTC: number;
  northFacadeCladdingTTC: number;
  groundAnchoringTTC: number;
  inverterUnderShelterTTC: number;
  inverterWarrantyExtensionTTC: number;
  electricVehicleChargingPointTTC: number;
  earthSpikeTTC: number;
}

export interface IScaffholdingReference extends IReferenceRaw {
  minHeightM: number;
  maxHeightM: number;
  fixCostEurPerOverM: number;
}
export interface ScaffholdingReference extends XReferenceValue {
  minHeightM: number;
  maxHeightM: number;
  fixCostEurPerOverM: number;
}

export interface ScaffholdingReferenceBaseManager extends XReferenceBaseManager {
}

export interface BooleanSerializer {
}

export interface DateSerializer {
}

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T[P] extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : DeepPartial<T[P]>
}
export interface NumberSerializer {
}

export interface UrlSerializer {
}

export interface AssertToolbox {
}

export interface IObject {
  [IIsObject]: boolean;
  className: string;
}
export interface ResolutionContext {
  parameters: Map<string, any>;
  value: TValue | null;
}

export interface XObject {
  className: string;
  [IIsObject]: boolean;
}

export interface IReference extends IObject, IReferenceRaw {
  [IsIReference]: boolean;
}
export interface IReferenceRaw {
  key: string | number;
}
export interface XReferenceBaseManager {
  referenceClassName: string;
  referenceClass: Class<TReference>;
}

export interface XReferenceTimeIntervalValue extends XTimeInterval {
  key: string | number;
  [IsIReference]: boolean;
}

export interface XReferenceValue extends XObject {
  key: string | number;
  [IsIReference]: boolean;
}

export interface CsvService {
}

export interface EconomyService {
}

export interface ElectricConsumptionService {
}

export interface LeasingOfferKitService {
}

export interface MailingService {
}

export interface OfferService {
}

export interface ProjectService {
}

export interface QuotationGenerator {
  evaluators: IQuotationEvaluator[];
}

export interface QuotationOptionService {
}

export interface QuotationService {
}

export interface SystemManagerService extends AwsConfigService {
}

export interface BucketService {
}

export interface CloudFrontService {
}

export interface SqsService {
}

export interface PdfService {
  bucketService: BucketService;
  cloudFrontService: CloudFrontService;
}




















export interface PipeDriveRawService {
}

export interface PipeDealFields {
  id: number | null;
  key: string;
  order_nr: number | undefined;
  name: string;
}

export interface PipeDriveService {
}

export interface IProducible {
  producible: number;
  monthlyProducible: number[];
}
export interface PvgisRawService extends HttpService {
}

export interface PvgisService {
}

export interface CoverTypeEvaluator {
}

export interface ElectricInstallationEvaluator {
}

export interface ElectricPurposeEvaluator {
}

export interface IQuotationEvaluator {
  getQuotationEntries(installationData: InstallationData, offerKit: OfferKit<CashOfferKit | LeasingOfferKit | CarPortKit>): Promise<QuotationEntry<OfferKitEntry>[]> | QuotationEntry<CashQuotationEntry | LeasingQuotationEntry | BonusQuotationEntry>[];
}
export interface OfferKitEvaluator {
}

export interface BasicObjectMapper {
  mapping: Record<string, string>;
}

export interface BasicValueMapper {
  mapping: Record<TFrom, TTo>;
}

export interface CollectionToolbox {
}

export interface IsoToolbox {
}

export interface JsonToolbox {
}

export interface ObjectToolbox {
}

export interface StringEnumConverter {
}

export interface StringToolbox {
}

export interface ValidationToolbox {
}

export interface AwsConfigService {
}

export interface AwsToolbox {
}

export interface DatabaseToolbox {
}

export interface LeasingOfferKitRepository extends RepositoryMongoose {
}

export interface ProjectRepository extends RepositoryMongoose {
}

export interface RepositoryMongoose {
}

export interface SolarSolutionRepository extends RepositoryMongoose {
}

export interface AlreadyExistError {
}

export interface InvalidObjectError {
  validationErrors: ValidationError[];
  object: Object | undefined;
}

export interface NotAlreadyPaidError {
}

export interface NotFoundError {
}

export interface CsvFileToolbox {
}

export interface FileToolbox {
}

export interface HttpClient {
  axiosClient: AxiosInstance;
}

export interface HttpResponse {
  axiosResponse: AxiosResponse<any, any>;
}

export interface HttpService {
}

export interface HttpTokenProvider {
  getToken(): Promise<string | null>;
}
export interface PublicNoTokenProvider {
}

export enum DateMonth {
  JANUARY = 0,
  FEBRUARY = 1,
  MARCH = 2,
  APRIL = 3,
  MAY = 4,
  JUNE = 5,
  JULY = 6,
  AUGUST = 7,
  SEPTEMBER = 8,
  OCTOBER = 9,
  NOVEMBER = 10,
  DECEMBER = 11,
}
export interface DateToolbox {
}

export interface ITimeInterval {
  startDate: Date;
  endDate: Date;
  overlaps(otherTimeInterval: ITimeInterval): boolean;
}
export interface Month extends XObject {
  year: number;
  monthFrom0: number;
  startDate: Date;
  endDate: Date;
}

export interface TimeIntervalDefault extends XTimeInterval {
  startDate: Date;
  endDate: Date;
}

export interface XTimeInterval extends XObject {
  startDate: Date;
  endDate: Date;
}


