import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { CarPortModel, ProductType, RoofType } from '@/apis';
import { carport, flatRoof, roof, slopingRoof } from '@/assets';
import { ListSelect } from '@/components';
import { ArrowRight } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button, ImageListSelect, InfoModal } from '@/components/ui';
import { type Simulation, useLocalProject } from '@/services/project';

type SituationForm = {
  productType?: Simulation['supportBuilding']['productType'];
  roofType?: Simulation['supportBuilding']['roofType'];
  model?: Simulation['supportBuilding']['model'];
};

const useDefaultValues: () => SituationForm = () => {
  const { data: defaultValues } = useLocalProject();
  const { installationData } = defaultValues ?? {};
  return {
    productType: installationData?.supportBuilding?.productType,
    roofType: installationData?.supportBuilding?.roofType,
    model: installationData?.supportBuilding?.model,
  };
};

const Situation = ({ nextRoute }: { nextRoute: string; previousRoute: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateInstallationData } = useLocalProject();

  const defaultValues = useDefaultValues();

  const { watch, setValue, formState, handleSubmit } = useForm<SituationForm>({
    defaultValues,
    values: defaultValues,
    // @ts-expect-error - cant make it work
    resolver: yupResolver(
      yup.object().shape({
        productType: yup
          .string()
          .required(t('required-select'))
          .oneOf(Object.values(ProductType) as ProductType[]),
        roofType: yup
          .string()
          .oneOf(Object.values(RoofType) as RoofType[])
          .when('productType', {
            is: ProductType.STANDARD,
            then: (schema) => schema.required(t('required-select')),
          }),
        model: yup
          .string()
          .nullable()
          .oneOf(Object.values(CarPortModel))
          .when('productType', {
            is: ProductType.CAR_PORT,
            then: (schema) => schema.required(t('required-select')),
          }),
      })
    ),
  });

  const onSubmit = (data: SituationForm) => {
    mutateInstallationData({
      supportBuilding: {
        productType: data.productType,
        model: data.model,
        roofType: data.roofType,
      },
    });
    navigate(nextRoute);
  };

  return (
    <AnimatedPage>
      <h1 className="text-lg font-semibold text-black">{t('situation-title')}</h1>
      <h2 className="text-sm font-medium text-black ">{t('productType-title')}</h2>

      <ImageListSelect
        aria-label={t('productType-title')}
        items={[
          { name: t('productType-standard'), id: ProductType.STANDARD, img: roof },
          { name: t('productType-car-port'), id: ProductType.CAR_PORT, img: carport },
        ]}
        selectedKey={watch('productType')}
        onSelect={(id: string) => {
          setValue('productType', id as SituationForm['productType'], { shouldValidate: true });
          setValue('model', undefined, { shouldValidate: true });
        }}
        errorMessage={formState?.errors.productType?.message}
      />

      {watch('productType') === ProductType.STANDARD ? (
        <>
          <h2 className="mt-2 flex gap-2 text-sm font-medium text-black ">
            {t('roofType-title')}
            <InfoModal>
              <p className="text-xs font-medium text-black">
                {t('your-can-install-panels-if-tenant')}
              </p>
            </InfoModal>
          </h2>
          <ImageListSelect
            aria-label={t('roofType-title')}
            items={[
              { name: t('flat-roof'), id: RoofType.FLAT_ROOF, img: flatRoof },
              { name: t('sloping-roof'), id: RoofType.SLOPED_ROOF, img: slopingRoof },
            ]}
            selectedKey={watch('roofType') as string}
            onSelect={(id: string) => {
              setValue('roofType', id as SituationForm['roofType'], { shouldValidate: true });
            }}
            errorMessage={formState?.errors.roofType?.message}
          />
        </>
      ) : null}
      {watch('productType') === ProductType.CAR_PORT ? (
        <>
          <h2 className="flex gap-2 text-sm font-medium text-black">
            {t('what-type-of-shelter')}
            <InfoModal>
              <p className="text-xs font-medium text-black">
                {t('your-can-install-panels-if-tenant')}
              </p>
            </InfoModal>
          </h2>
          <ListSelect
            aria-label={t('what-type-of-shelter')}
            items={[
              {
                name: t('model-20'),
                description: t('model-20-description'),
                id: CarPortModel.SMALL,
              },
              {
                name: t('model-30'),
                description: t('model-30-description'),
                id: CarPortModel.MEDIUM,
              },
              {
                name: t('model-40'),
                description: t('model-40-description'),
                id: CarPortModel.LARGE,
              },
            ]}
            selectedKey={watch('model') as string}
            onSelect={(id: string) => {
              setValue('model', id as SituationForm['model'], { shouldValidate: true });
            }}
            errorMessage={formState?.errors.model?.message}
          />
        </>
      ) : null}

      <Button
        label={t('next-step')}
        className={'mt-4 self-end bg-primary text-white'}
        onPress={() => {
          void handleSubmit(onSubmit)();
        }}
        PostIcon={(props) => <ArrowRight {...props} />}
      />
    </AnimatedPage>
  );
};
export default Situation;
