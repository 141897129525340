import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { InfoCard, ListSelect } from '@/components';
import { ArrowRight } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button } from '@/components/ui';
import { type Simulation, useLocalProject } from '@/services/project';

type HomeDescriptionForm = {
  nbrPersons: Simulation['homeDescription']['nbrPersons'];
  houseAgeY: Simulation['homeDescription']['houseAgeY'];
};

const getDefaultValues = (installationData?: Simulation) => ({
  nbrPersons: installationData?.homeDescription?.nbrPersons,
  houseAgeY: installationData?.homeDescription?.houseAgeY,
});

const HomeDescription = ({
  nextRoute,
  previousRoute,
}: {
  nextRoute: string;
  previousRoute: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { installationData, mutateInstallationData } = useLocalProject();
  const defaultValues = getDefaultValues(installationData);

  const { watch, setValue, formState, handleSubmit } = useForm<HomeDescriptionForm>({
    defaultValues,
    values: defaultValues as HomeDescriptionForm,
    resolver: yupResolver(
      yup.object().shape({
        nbrPersons: yup.number().required(t('required')).positive().integer(),
        houseAgeY: yup.number().required(t('required')).positive().integer(),
      })
    ),
  });

  const onSubmit = (data: HomeDescriptionForm) => {
    mutateInstallationData({
      homeDescription: {
        ...installationData?.homeDescription,
        nbrPersons: data.nbrPersons,
        houseAgeY: data.houseAgeY,
      } as Simulation['homeDescription'],
    });
    navigate(nextRoute);
  };

  return (
    <AnimatedPage>
      <h1 className="text-lg font-semibold text-black">{t('information-about-household')}</h1>
      <h2 className="mt-2 text-sm font-medium text-black ">{t('how-many-people-in-your-house')}</h2>
      <ListSelect
        aria-label={t('how-many-people-in-your-house')}
        // 1, 2, 3, 4, more than 4
        items={Array.from({ length: 5 }, (_, i) => ({
          name: i === 4 ? t('more-than-people', { count: i }) : t('people', { count: i + 1 }),
          id: String(i + 1),
        }))}
        selectedKey={String(watch('nbrPersons'))}
        onSelect={(id: string) => {
          setValue('nbrPersons', Number(id), { shouldValidate: true });
        }}
        errorMessage={formState.errors.nbrPersons?.message}
      />
      <h2 className="mt-2 text-sm font-medium text-black ">{t('how-old-is-your-house')}</h2>

      <InfoCard>
        <p className="mb-2 text-xs font-medium text-black">{t('if-renovation-occured')}</p>
      </InfoCard>
      <ListSelect
        aria-label={t('how-old-is-your-house')}
        // Less than 5, from 5 to 20, from 20 to 40, more than 40
        items={[
          { name: t('less-than-5-years'), id: '5' },
          { name: t('from-5-to-20-years'), id: '20' },
          { name: t('from-20-to-40-years'), id: '40' },
          { name: t('more-than-40-years'), id: '41' },
        ]}
        selectedKey={String(watch('houseAgeY'))}
        onSelect={(id: string) => {
          setValue('houseAgeY', Number(id), { shouldValidate: true });
        }}
        errorMessage={formState.errors.houseAgeY?.message}
      />

      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={() => {
            navigate(previousRoute);
          }}
        />
        <Button
          label={t('next-step')}
          className={'mt-4 self-end bg-primary text-white'}
          onPress={() => {
            void handleSubmit(onSubmit)();
          }}
          PostIcon={(props) => <ArrowRight {...props} />}
        />
      </div>
    </AnimatedPage>
  );
};
export default HomeDescription;
