import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { type PropsWithChildren } from 'react';
import { queryClient } from '@/apis/queryClient';

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const QueryClientProvider = ({ children }: PropsWithChildren) => (
  <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
    {children}
  </PersistQueryClientProvider>
);

export default QueryClientProvider;
