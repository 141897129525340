import { type TFunction } from 'i18next';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { houseBg } from '@/assets';
import { Button } from '@/components';
import { CheckCircleFill, Pen } from '@/components/icons';
import { formatPrice } from '@/helpers';
import { useLocalProject } from '@/services/project';

const getItems = (t: TFunction) => [
  t('energy-auto-consumption'),
  t('no-extra-costs'),
  t('no-inital-investment'),
  t('possibility-of-rebuying-the-installation-in-10-years'),
];

const Item = ({ text }: { text: string }) => (
  <div className="flex items-center justify-center gap-2">
    <CheckCircleFill className="size-5 shrink-0  text-white" />
    <p className="text-nowrap text-center text-sm font-light text-white">{text}</p>
  </div>
);

const RentResultTileMobile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data } = useLocalProject();
  const firstLeasingOffer = data?.solarSolutions?.[0]?.leasingSolutions?.[0];
  return (
    <div className="flex w-full flex-col lg:hidden">
      <div className="justify-center bg-secondary p-2 text-center text-xs font-medium text-white">
        <Trans
          i18nKey="economize-until"
          values={{
            price: formatPrice(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              firstLeasingOffer?.economy?.economy30yearsWithEcoGesture ?? 0,
              'EUR'
            ),
            year: 30,
          }}
          components={[<span key="0" className="font-semibold text-white" />]}
        />
      </div>
      <div className="flex w-full justify-between gap-4 bg-white px-6 py-2">
        <div className="flex flex-col justify-center">
          <div className="text-xs font-medium text-terciary">
            {t('solar-panels', { count: firstLeasingOffer?.panelNbr })} |{' '}
            {firstLeasingOffer?.installationPowerWc} Wc
          </div>
          {firstLeasingOffer?.monthlyRate ? (
            <div className="mt-1 flex items-end gap-1">
              <div className="text-xl font-semibold text-black">
                {formatPrice(firstLeasingOffer?.monthlyRate, 'EUR')}
              </div>
              <div className="text-xs text-ghost">{t('per-month')}</div>
            </div>
          ) : null}
          {firstLeasingOffer?.deposit ? (
            <div className="text-xs text-ghost">
              +{formatPrice(firstLeasingOffer?.deposit, 'EUR')} {t('warranty-deposit')}
            </div>
          ) : null}
        </div>
        <Button
          label="Modifier"
          onPress={() => {
            navigate('/');
          }}
          PostIcon={(props) => <Pen {...props} />}
          className={'self-center border border-grey2 text-black'}
        />
      </div>
    </div>
  );
};

const RentResultTileDesktop = () => {
  const { t } = useTranslation();
  const { data } = useLocalProject();
  const firstLeasingOffer = data?.solarSolutions?.[0]?.leasingSolutions?.[0];
  const items = getItems(t);
  return (
    <div className="hidden flex-col place-self-start overflow-hidden rounded-xl border border-grey2 bg-white lg:flex">
      <div
        className="min-h-[170px]"
        style={{
          backgroundImage: `url(${houseBg})`,
          backgroundSize: 'cover',
          backgroundPosition: '0 70%',
        }}
      />
      <div className="flex flex-col justify-center p-6 text-center">
        <h2 className="text-base text-grey1">{t('your-investment')}</h2>
        <p className="text-base font-medium text-terciary">
          {t('solar-panels', { count: firstLeasingOffer?.panelNbr })} |{' '}
          {firstLeasingOffer?.installationPowerWc} Wc
        </p>
        <div className="flex flex-col items-center">
          {firstLeasingOffer?.monthlyRate ? (
            <div className="text-5xl font-semibold text-black max-md:text-4xl">
              {formatPrice(firstLeasingOffer?.monthlyRate, 'EUR')}
              <span className="text-sm font-normal text-ghost">{t('per-month')}</span>
            </div>
          ) : null}
          {firstLeasingOffer?.deposit ? (
            <div className="flex items-center justify-center gap-1 self-stretch text-ghost">
              <div className="text-lg">+{formatPrice(firstLeasingOffer?.deposit, 'EUR')}</div>
              <div className="text-sm">{t('warranty-deposit')}</div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex flex-col justify-center gap-4 bg-secondary p-6 text-center">
        <h2 className="text-lg text-white">
          <Trans
            i18nKey="economize-until"
            values={{
              price: formatPrice(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                firstLeasingOffer?.economy?.economy30yearsWithEcoGesture ?? 0,
                'EUR'
              ),
              year: 30,
            }}
            components={[<span key="0" className="font-semibold text-white" />]}
          />
        </h2>
        {items.map((item, index) => (
          <Item key={index} text={item} />
        ))}
      </div>
    </div>
  );
};

const RentResultTile = () => (
  <>
    <RentResultTileMobile />
    <RentResultTileDesktop />
  </>
);

export default RentResultTile;
