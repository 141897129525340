import { type SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14" fill="none" {...props}>
    <path
      fill="currentColor"
      d="m9.543 3.733.787-.787.766.766-.787.787a4.875 4.875 0 1 1-.766-.766Zm-3.043 7.6a3.79 3.79 0 1 0 0-7.58 3.79 3.79 0 0 0 0 7.58Zm-.542-6.5h1.083v3.25H5.958v-3.25ZM4.333 1.043h4.333v1.083H4.333V1.042Z"
    />
  </svg>
);
export default SvgComponent;
