import { type SVGProps } from 'react';
const CloseCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M9.5 16.5a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15Zm0-1.5a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0-7.06 2.121-2.122 1.061 1.061L10.56 9l2.122 2.121-1.061 1.061L9.5 10.06l-2.121 2.122-1.061-1.061L8.44 9 6.318 6.879l1.061-1.061L9.5 7.94Z"
    />
  </svg>
);
export default CloseCircle;
