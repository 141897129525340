import { type SVGProps } from 'react';
const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none" {...props}>
    <path
      fill="currentColor"
      d="m9.433 6.417-3.129-3.13.825-.824L11.666 7 7.13 11.537l-.825-.825 3.13-3.129H2.332V6.417h7.1Z"
    />
  </svg>
);
export default ArrowRight;
