import { type ReactNode, type ComponentProps } from 'react';
import { Button as AriaBtn } from 'react-aria-components';
import { cn } from '@/helpers';

const Button = (
  props: ComponentProps<typeof AriaBtn> & {
    label: string;
    PreIcon?: (props: { className: string }) => ReactNode;
    PostIcon?: (props: { className: string }) => ReactNode;
  }
) => (
  <AriaBtn
    {...props}
    aria-label={props.label}
    className={cn(
      'px-4 py-3 rounded-lg text-xs font-medium flex flex-row gap-x-2 items-center justify-center',
      {
        'opacity-30': props.isDisabled,
      },
      props?.className
    )}>
    {props?.PreIcon && <props.PreIcon className="size-4" />}
    {props?.label}
    {props?.PostIcon && <props.PostIcon className="size-4" />}
  </AriaBtn>
);
export default Button;
