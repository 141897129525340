import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { CovertType, ProductType } from '@/apis';
import { fibro, slate, steel, tile } from '@/assets';
import { ListSelect } from '@/components';
import { ArrowRight } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button, ImageListSelect, TextField } from '@/components/ui';
import { type Simulation, useLocalProject } from '@/services/project';

type CovertForm = {
  garden: Simulation['homeDescription']['garden'];
  heatedSurfaceM2: Simulation['homeDescription']['heatedSurfaceM2'];
  covertTypeKey?: Simulation['supportBuilding']['covertTypeKey'];
};

const getDefaultValues = (installationData?: Simulation) => ({
  garden: installationData?.homeDescription?.garden,
  heatedSurfaceM2: installationData?.homeDescription?.heatedSurfaceM2,
  covertTypeKey: installationData?.supportBuilding?.covertTypeKey,
});

const Covert = ({
  nextRoute,
  previousRoute1,
  previousRoute2,
}: {
  nextRoute: string;
  previousRoute1: string;
  previousRoute2: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { installationData, mutateInstallationData } = useLocalProject();
  const defaultValues = getDefaultValues(installationData);
  const knownConsumption = installationData?.electricConsumption?.consumptionUnit !== undefined;

  const { watch, setValue, formState, handleSubmit } = useForm<CovertForm>({
    defaultValues,
    values: defaultValues as CovertForm,
    resolver: yupResolver(
      yup.object().shape({
        garden: yup.boolean().required(t('required-select')),
        heatedSurfaceM2: yup.number().required(t('required')).positive().min(10),
        // If simulationdData.productType is ProductType.STANDARD, then covertTypeKey is required
        covertTypeKey:
          installationData?.supportBuilding?.productType === ProductType.STANDARD
            ? yup.string().required(t('required-select')).oneOf(Object.values(CovertType))
            : yup.string().oneOf(Object.values(CovertType)),
      })
    ),
  });

  const onSubmit = (data: CovertForm) => {
    mutateInstallationData({
      homeDescription: {
        ...installationData?.homeDescription,
        garden: data.garden,
        heatedSurfaceM2: data.heatedSurfaceM2,
      },
      supportBuilding: {
        ...installationData?.supportBuilding,
        covertTypeKey: data.covertTypeKey,
      },
    });
    navigate(nextRoute);
  };
  return (
    <AnimatedPage>
      <h2 className="mt-2 text-sm font-medium text-black ">{t('do-you-have-a-garden')}</h2>
      <ListSelect
        aria-label={t('do-you-have-a-garden')}
        items={[
          { name: t('yes'), id: 'true' },
          { name: t('no'), id: 'false' },
        ]}
        selectedKey={String(watch('garden'))}
        onSelect={(id: string) => {
          setValue('garden', id === 'true', { shouldValidate: true });
        }}
        errorMessage={formState.errors.garden?.message}
      />
      <TextField
        aria-label={t('what-is-the-heated-area')}
        placeholder={t('i-fill-the-complete-area')}
        type="number"
        value={String(watch('heatedSurfaceM2'))}
        onChange={(value) => {
          setValue('heatedSurfaceM2', Number(value), { shouldValidate: true });
        }}
        unit={'m²'}
        aria-errormessage={formState.errors.heatedSurfaceM2?.message}
      />

      {installationData?.supportBuilding?.productType === ProductType.STANDARD ? (
        <>
          <h2 className="mt-2 text-sm font-medium text-black ">
            {t('what-type-of-roof-covering')}
          </h2>
          <ImageListSelect
            aria-label={t('what-type-of-roof-covering')}
            items={[
              { name: t('slate'), id: CovertType.ARDOISES, img: slate },
              { name: t('tiles'), id: CovertType.TUILES, img: tile },
              { name: t('steel'), id: CovertType.BACS_ACIER, img: steel },
              { name: t('fibro'), id: CovertType.PLAQUES_FIBRO_CIMENT, img: fibro },
            ]}
            selectedKey={watch('covertTypeKey') as string}
            onSelect={(id: string) => {
              setValue('covertTypeKey', id as CovertForm['covertTypeKey'], {
                shouldValidate: true,
              });
            }}
            errorMessage={formState.errors.covertTypeKey?.message}
          />
        </>
      ) : null}
      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={() => {
            knownConsumption ? navigate(previousRoute2) : navigate(previousRoute1);
          }}
        />
        <Button
          label={t('next-step')}
          className={'mt-4 self-end bg-primary text-white'}
          onPress={() => {
            void handleSubmit(onSubmit)();
          }}
          PostIcon={(props) => <ArrowRight {...props} />}
        />
      </div>
    </AnimatedPage>
  );
};
export default Covert;
