import { type SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M9.166 7.167H12.5l-4 4-4-4h3.333V2.5h1.333v4.667Zm-6 6h10.667V8.5h1.333v5.333a.667.667 0 0 1-.666.667h-12a.666.666 0 0 1-.667-.667V8.5h1.333v4.667Z"
    />
  </svg>
);
export default SvgComponent;
