import { type SVGProps } from 'react';
const Aim = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
    <path
      fill="currentColor"
      d="M8.5 1.5A6.5 6.5 0 1 0 15 8a6.507 6.507 0 0 0-6.5-6.5ZM9 13.477V11.5a.5.5 0 0 0-1 0v1.977A5.508 5.508 0 0 1 3.023 8.5H5a.5.5 0 0 0 0-1H3.023A5.508 5.508 0 0 1 8 2.523V4.5a.5.5 0 1 0 1 0V2.523A5.508 5.508 0 0 1 13.977 7.5H12a.5.5 0 0 0 0 1h1.977A5.508 5.508 0 0 1 9 13.477Z"
    />
  </svg>
);
export default Aim;
