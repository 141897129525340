import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { useState, type Key, useEffect } from 'react';
import { ListBox, ListBoxItem } from 'react-aria-components';
import { useTranslation } from 'react-i18next';
import { TextField } from '@/components';
import { useClickOutside } from '@/helpers';

const AddressField = ({
  placeholder,
  defaultValue,
  onSelect,
  errorMessage,
}: {
  placeholder: string;
  defaultValue?: google.maps.places.AutocompletePrediction;
  onSelect: (address?: google.maps.places.AutocompletePrediction) => void;
  errorMessage?: string;
}) => {
  const { t } = useTranslation();
  const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [selected, setSelected] = useState<google.maps.places.AutocompletePrediction | undefined>(
    defaultValue
  );
  const ref = useClickOutside(() => {
    setSuggestions([]);
  });

  const places = useMapsLibrary('places');

  const handleSearch = async (value: string) => {
    if (!places || !value) {
      return;
    }
    const service = new places.AutocompleteService();
    await service.getPlacePredictions(
      {
        input: value,
        componentRestrictions: { country: 'fr' },
        types: ['address'],
      },
      (predictions, status) => {
        if (status === places.PlacesServiceStatus.OK && predictions) {
          setSuggestions(predictions);
        }
      }
    );
  };

  // Update the selected address when the default value changes
  useEffect(() => {
    setSelected(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <div className="relative">
      <TextField
        placeholder={placeholder}
        aria-labelledby={t('address')}
        value={selected?.description}
        onChange={(value) => {
          setSelected(undefined);
          void handleSearch(value);
        }}
        aria-errormessage={errorMessage}
      />
      <ListBox
        ref={ref}
        aria-label={t('address')}
        onSelectionChange={(id) => {
          setSelected(
            suggestions.find((s) => s.place_id === (id as Set<Key>)?.values().next().value)
          );
          onSelect(suggestions.find((s) => s.place_id === (id as Set<Key>)?.values().next().value));
          setSuggestions([]);
        }}
        items={suggestions.map((s) => ({ id: s.place_id, name: s.description }))}
        selectionMode="single"
        className="absolute top-full z-10 rounded-b-lg bg-white shadow-2xl">
        {(item) => (
          <ListBoxItem
            {...item}
            aria-label={item.name}
            className="border-b border-gray-200 p-2"
            key={item.id}>
            <span>{item.name}</span>
          </ListBoxItem>
        )}
      </ListBox>
      {errorMessage && (
        <span className="absolute top-full ml-1 text-xxs leading-none text-red-500">
          {errorMessage}
        </span>
      )}
    </div>
  );
};
export default AddressField;
