import { GoogleMapsContext } from '@vis.gl/react-google-maps';
import { useContext, useEffect, useMemo, useRef, useCallback } from 'react';
import { rotatePath } from './rotatePath';

export function Rectangle(props: {
  height: number;
  width: number;
  center?: google.maps.LatLngLiteral;
  angle?: number;
  onPathChange?: (path: google.maps.LatLngLiteral[]) => void;
}) {
  const map = useContext(GoogleMapsContext)?.map;
  const polygon = useRef<google.maps.Polygon | null>(null);
  const lastAngle = useRef(props.angle);

  const pointsFromProps = useMemo(() => {
    if (props.center && props.height && props.width) {
      return [
        { lat: props.center.lat - props.height / 2, lng: props.center.lng - props.width / 2 },
        { lat: props.center.lat - props.height / 2, lng: props.center.lng + props.width / 2 },
        { lat: props.center.lat + props.height / 2, lng: props.center.lng + props.width / 2 },
        { lat: props.center.lat + props.height / 2, lng: props.center.lng - props.width / 2 },
      ];
    } else {
      return [];
    }
  }, [props.center, props.height, props.width, props.angle]);

  const updatePolygon = useCallback(() => {
    if (!map || !props.center) return;

    if (!polygon.current) {
      polygon.current = new google.maps.Polygon({
        fillColor: '#fff',
        strokeWeight: 2,
        fillOpacity: 0.2,
        strokeColor: '#00a6d6',
        strokeOpacity: 1,
        clickable: false,
      });
      polygon.current.setMap(map);
    }

    const newPath = rotatePath(pointsFromProps, props.angle ?? 1, props.center, map);
    console.log({ pointsFromProps, angle: props.angle ?? 1, center: props.center });
    polygon.current.setPath(newPath);
    // Call onPathChange outside of the render cycle
    props.onPathChange?.(newPath);
    lastAngle.current = props.angle;
  }, [
    map,
    pointsFromProps,
    props.angle,
    props.center,
    props.height,
    props.width,
    props.onPathChange,
  ]);

  useEffect(() => {
    updatePolygon();

    return () => {
      if (polygon.current) {
        polygon.current.setMap(null);
        polygon.current = null;
      }
    };
  }, [updatePolygon]);

  return null;
}
