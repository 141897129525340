import { useTranslation } from 'react-i18next';
import { logoWhite } from '@/assets';
import { Button } from '@/components';
import { Phone } from '@/components/icons';

const Hero = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center gap-y-6">
      <a href="https://www.soleriel.fr" target="_blank" rel="noreferrer">
        <img src={logoWhite} alt="Logo" className="h-5" />
      </a>
      <h1 className=" max-w-[340px] text-center text-4xl font-semibold text-white">
        {t('buying-renting-of-solar-panels')}
      </h1>
      <div className=" flex flex-row items-center justify-center rounded-xl bg-white/15 p-2">
        <span className="p-2 text-center text-base font-medium text-white">
          {t('got-a-question')}
        </span>
        <Button
          label="09 80 80 40 57"
          PreIcon={(props) => <Phone {...props} />}
          onPress={() => window.open(`tel:${t('soleriel-phone-number')}`)}
          className="bg-white text-black"
        />
      </div>
    </div>
  );
};
export default Hero;
