import { type SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M18.666 26.666v2.667h-16v-2.667h16ZM19.448.914l10.37 10.37-1.885 1.889-1.413-.472-3.303 3.298 7.543 7.543-1.886 1.885-7.54-7.542-3.206 3.205.377 1.51-1.886 1.885-10.371-10.37 1.886-1.886 1.507.376 8.392-8.39-.47-1.414L19.448.914Z"
    />
  </svg>
);
export default SvgComponent;
