import { yupResolver } from '@hookform/resolvers/yup';
import { type Key } from 'react-aria-components';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { EquipmentKey, HeatingSystemKey, WaterHeatingSystemKey } from '@/apis';
import { ListSelect } from '@/components';
import { ArrowRight } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button, MultiSelect } from '@/components/ui';
import { type Simulation, useLocalProject } from '@/services/project';

type EquipmentsForm = {
  equipmentKeys: Simulation['homeDescription']['equipmentKeys'];
  heatingSystemKey: Simulation['homeDescription']['heatingSystemKey'];
  waterHeatingSystemKey: Simulation['homeDescription']['waterHeatingSystemKey'];
};

const getDefaultValues = (installationData?: Simulation) => ({
  equipmentKeys: installationData?.homeDescription?.equipmentKeys ?? [],
  heatingSystemKey: installationData?.homeDescription?.heatingSystemKey,
  waterHeatingSystemKey: installationData?.homeDescription?.waterHeatingSystemKey,
});

const Equipments = ({ nextRoute, previousRoute }: { nextRoute: string; previousRoute: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { installationData, mutateInstallationData } = useLocalProject();
  const defaultValues = getDefaultValues(installationData);

  const { watch, setValue, formState, handleSubmit } = useForm<EquipmentsForm>({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        equipmentKeys: yup
          .array()
          .required()
          .of(yup.string().required(t('required')).oneOf(Object.values(EquipmentKey))),
        heatingSystemKey: yup
          .string()
          .required(t('required'))
          .oneOf(Object.values(HeatingSystemKey)),
        waterHeatingSystemKey: yup
          .string()
          .required(t('required'))
          .oneOf(Object.values(WaterHeatingSystemKey)),
      })
    ),
  });

  const onSubmit = (data: EquipmentsForm) => {
    mutateInstallationData({
      homeDescription: {
        ...installationData?.homeDescription,
        ...data,
      } as Simulation['homeDescription'],
    });
    navigate(nextRoute);
  };
  return (
    <AnimatedPage>
      <h1 className="text-lg font-semibold text-black ">{t('your-equipments')}</h1>
      <h2 className="mt-2 text-sm font-medium text-black ">
        {t('select-every-equipment-you-own')}
      </h2>
      <MultiSelect
        aria-label={t('select-every-equipment-you-own')}
        items={[
          { name: t('pool'), id: EquipmentKey.SWIMMING_POOL },
          { name: t('freezer'), id: EquipmentKey.FREEZER },
          { name: t('air-conditioning'), id: EquipmentKey.AIR_CONDITIONING },
        ]}
        selectedKeys={watch('equipmentKeys')}
        onSelect={(ids: Key[]) => {
          setValue('equipmentKeys', ids as EquipmentsForm['equipmentKeys'], {
            shouldValidate: true,
          });
        }}
        errorMessage={formState.errors.equipmentKeys?.message}
      />
      <h2 className="mt-2 text-sm font-medium text-black">{t('what-type-of-heating')}</h2>
      <ListSelect
        aria-label={t('what-type-of-heating')}
        items={[
          {
            name: t('electric'),
            description: t('radiator-or-floor-heating'),
            id: HeatingSystemKey.ELECTRIC,
          },
          {
            name: t('heat-pump'),
            description: t('it-includes-reversible-clim'),
            id: HeatingSystemKey.HEAT_PUMP,
          },
          { name: t('mix'), description: t('electric+other'), id: HeatingSystemKey.MIXED },
          { name: t('other'), id: HeatingSystemKey.OTHER },
        ]}
        selectedKey={watch('heatingSystemKey')}
        onSelect={(id: string) => {
          setValue('heatingSystemKey', id as EquipmentsForm['heatingSystemKey'], {
            shouldValidate: true,
          });
        }}
        errorMessage={formState.errors.heatingSystemKey?.message}
      />
      <h2 className="mt-2 text-sm font-medium text-black">{t('what-type-of-water-heater')}</h2>
      <ListSelect
        aria-label={t('what-type-of-water-heater')}
        items={[
          { name: t('electric'), id: WaterHeatingSystemKey.ELECTRIC },
          {
            name: t('thermodynamic'),
            description: t('using-heater-pump'),
            id: WaterHeatingSystemKey.THERMODYNAMIC,
          },
          { name: t('other'), id: WaterHeatingSystemKey.OTHER },
        ]}
        selectedKey={watch('waterHeatingSystemKey')}
        onSelect={(id: string) => {
          setValue('waterHeatingSystemKey', id as EquipmentsForm['waterHeatingSystemKey'], {
            shouldValidate: true,
          });
        }}
        errorMessage={formState.errors.waterHeatingSystemKey?.message}
      />

      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={() => {
            navigate(previousRoute);
          }}
        />
        <Button
          label={t('next-step')}
          className={'mt-4 self-end bg-primary text-white'}
          onPress={() => {
            void handleSubmit(onSubmit)();
          }}
          PostIcon={(props) => <ArrowRight {...props} />}
        />
      </div>
    </AnimatedPage>
  );
};
export default Equipments;
