export function rotatePath(
  points: google.maps.LatLngLiteral[],
  angle: number,
  center: google.maps.LatLngLiteral,
  map: google.maps.Map
): google.maps.LatLngLiteral[] {
  const projection = map.getProjection();
  if (!projection) {
    console.error('Map projection not available');
    return points;
  }

  const centerPoint = projection.fromLatLngToPoint(new google.maps.LatLng(center.lat, center.lng));
  if (!centerPoint) {
    console.error('Unable to project center point');
    return points;
  }

  const rotatedPoints = points.map((point) => {
    const latLng = new google.maps.LatLng(point.lat, point.lng);
    const pointXY = projection.fromLatLngToPoint(latLng);
    if (!pointXY) {
      console.error('Unable to project point', point);
      return point;
    }

    // Translate point to origin
    const x = pointXY.x - centerPoint.x;
    const y = pointXY.y - centerPoint.y;

    // Rotate point
    const angleRad = (angle * Math.PI) / 180;
    const rotatedX = x * Math.cos(angleRad) - y * Math.sin(angleRad);
    const rotatedY = x * Math.sin(angleRad) + y * Math.cos(angleRad);

    // Translate point back
    const newPoint = new google.maps.Point(rotatedX + centerPoint.x, rotatedY + centerPoint.y);
    const newLatLng = projection.fromPointToLatLng(newPoint);

    if (!newLatLng) {
      console.error('Unable to project back to LatLng', newPoint);
      return point;
    }

    return { lat: newLatLng.lat(), lng: newLatLng.lng() };
  });

  return rotatedPoints;
}
