import { useEffect, useState } from 'react';
import { SqsPdfAction } from '@/apis';
import { useContractGenerated, useLeasingDealInfo } from '@/apis/location/hooks';
import { useGetPdfGenerationStatus } from '@/apis/simulation/hooks';

export const usePdf = (projectId: string | undefined, action: SqsPdfAction) => {
  const [refetchInterval, setRefetchInterval] = useState(5000);
  const { data: locationProjectInfo } = useLeasingDealInfo(projectId!, {
    enabled: Boolean(projectId) && action === SqsPdfAction.GENERATE_LEASING_PDF,
  });
  const { mutate: tellContractIsGenerated, isPending, isSuccess, isIdle } = useContractGenerated();
  const { data } = useGetPdfGenerationStatus(projectId!, action, {
    refetchOnMount: true,
    enabled: Boolean(projectId),
    refetchOnWindowFocus: true,
    refetchInterval,
  });
  const tellingContractIsGenerated = isIdle || isPending || isSuccess;
  const pdfUrl = data?.pdfGenerated;
  const isPdfGenerated = Boolean(pdfUrl);
  const isPdfSigned = locationProjectInfo?.isAlreadySignedContract ?? undefined;

  useEffect(() => {
    if (isPdfGenerated) {
      setRefetchInterval(0);
    }
    if (
      isPdfGenerated &&
      !isPdfSigned &&
      action === SqsPdfAction.GENERATE_LEASING_PDF &&
      !tellingContractIsGenerated
    ) {
      tellContractIsGenerated(projectId!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPdfGenerated, isPdfSigned, action, projectId]);

  return {
    isPdfGenerated,
    isPdfSigned,
    pdfUrl,
  };
};
