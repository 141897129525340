import { sendAlert } from '@/apis';
import * as Sentry from '@sentry/react';
import { track } from '@vercel/analytics';

const callDiscordWebhook = async (message: string, error: Record<string, string>) => {
  console.log('callDiscordWebhook', message, error);
  await sendAlert({ message, error });
};

export const trackError = async (message: string, error: any) => {
  if (!import.meta.env.PROD) {
    console.error(error);
    return;
  }
  try {
    Sentry.captureException(message, error);
    track(message, error);
    await callDiscordWebhook(message, error);
  } catch (error) {
    console.error(error);
  }
};
