import { type ComponentProps } from 'react';
import { ListBox, ListBoxItem, type Key, type Selection } from 'react-aria-components';
import { cn } from '@/helpers';
type Item = { name: string; id: string; description?: string };

const ListSelect = (
  props: ComponentProps<typeof ListBox<Item>> & {
    selectedKey?: string;
    onSelect: (id: string) => void;
    selectedBgColor?: string;
    selectedBorderColor?: string;
    errorMessage?: string;
  }
) => (
  // Transform Set to simple string
  <div className="relative">
    <ListBox
      {...props}
      selectedKeys={new Set([props.selectedKey]) as Set<Key>}
      onSelectionChange={(id: Selection) => {
        props.onSelect((id as Set<Key>)?.values().next().value as string);
      }}
      selectionMode={'single'}
      className="flex flex-col gap-y-2">
      {(item: Item) => (
        <ListBoxItem
          {...item}
          data-testid={item.id}
          aria-label={item.name}
          className={({ isSelected, isFocused, isHovered }) =>
            cn(
              'rounded-lg border border-solid  border-grey3 p-4 text-sm flex flex-col font-normal outline-none cursor-pointer text-black',
              {
                'bg-primary text-white border-primary': isSelected ?? isFocused ?? isHovered,
              },
              {
                'border-red-500 border-2': props?.errorMessage,
              },
              isSelected && props.selectedBgColor,
              isSelected && props.selectedBorderColor
            )
          }>
          <p className="text-sm font-medium">{item.name}</p>
          {item?.description && <p className={cn('text-xs opacity-70')}>{item.description}</p>}
        </ListBoxItem>
      )}
    </ListBox>
    {props?.errorMessage && (
      <span className="absolute top-full ml-1 mt-2 text-xxs leading-none text-red-500">
        {props?.errorMessage}
      </span>
    )}
  </div>
);
export default ListSelect;
