import { forwardRef } from 'react';
import { Label } from 'react-aria-components';

const DateField = forwardRef<
  HTMLInputElement,
  {
    'aria-errormessage'?: string;
    'aria-label': string;
    isRequired?: boolean;
    defaultValue: string;
    value?: string;
    onChange: (value: string) => void;
    name: string;
  }
>(({ isRequired, value, ...props }, ref) => (
  <div className="relative text-sm">
    <Label className=" text-sm font-medium">
      {props?.['aria-label']} {isRequired && <span className="text-red-500">*</span>}
    </Label>
    <div className="mt-2 flex w-fit  flex-row gap-2  rounded-lg border border-grey2 bg-white px-3 py-[10px] text-black">
      <input
        {...props}
        ref={ref}
        name={props.name}
        type="date"
        defaultValue={value}
        onChange={(e) => {
          props?.onChange?.(e?.target?.value);
        }}
      />
    </div>
    {props?.['aria-errormessage'] && (
      <span className="absolute top-full ml-1 text-xxs leading-none text-red-500">
        {props?.['aria-errormessage']}
      </span>
    )}
  </div>
));
export default DateField;
