/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import { type TFunction } from 'i18next';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SqsPdfAction, type Quotation } from '@/apis';
import { useCreateDeal } from '@/apis/location/hooks';
import { Button, DownloadStudy } from '@/components';
import {
  ArrowRight,
  CheckCircle,
  CheckCircleFill,
  Download,
  LoaderCircle,
} from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { formatPrice } from '@/helpers';
import { usePdf } from '@/services/pdf';
import { useLocalProject } from '@/services/project';

const getItemsForLeasing = (quotation: Quotation, t: TFunction) => [
  t('leasing-feature-item-1', {
    count: quotation?.offerKit.panelNbr ?? 0,
  }),
  t('leasing-feature-item-2', {
    value: quotation?.installationPowerWc,
  }),
  t('leasing-feature-item-3'),
  t('leasing-feature-item-4'),
  t('leasing-feature-item-5'),
  t('leasing-feature-item-6'),
  t('leasing-feature-item-7'),
  t('leasing-feature-item-8'),
  t('leasing-feature-item-9'),
  t('leasing-feature-item-10'),
];

const getItemsForCash = (quotation: Quotation, t: TFunction) => [
  t('cash-feature-item-1', {
    count: quotation?.offerKit.panelNbr ?? 0,
  }),
  t('cash-feature-item-2', {
    value: quotation?.installationPowerWc,
  }),
  t('cash-feature-item-3'),
  t('cash-feature-item-4'),
  t('cash-feature-item-5'),
  t('cash-feature-item-6'),
  t('cash-feature-item-7'),
];

const Item = ({ text }: { text: string }) => (
  <div className="flex items-center gap-2">
    <CheckCircleFill className="size-5 shrink-0 text-terciary" />
    <p className="text-black">{text}</p>
  </div>
);

const CashResults = ({ data, projectId }: { data: Quotation; projectId: string }) => {
  const { t } = useTranslation();

  const items = getItemsForCash(data, t);

  return (
    <AnimatedPage className="flex-1">
      <div className="flex w-full max-w-[600px] flex-col gap-y-8 self-center overflow-hidden rounded-xl bg-white pb-8">
        <div className="flex w-full flex-1 justify-center bg-secondary px-2 py-4">
          <h2 className="text-lg text-white">
            <Trans
              i18nKey="economize-until"
              values={{
                price: formatPrice(data?.economy?.economy30yearsWithEcoGesture ?? 0, 'EUR'),
                year: 30,
              }}
              components={[<span key="0" className="font-semibold text-white" />]}
            />
          </h2>
        </div>
        <div className="flex flex-col items-center gap-y-4 px-8">
          <div className="rounded-full border border-secondary px-3 py-2">
            <h3 className="text-lg text-secondary">{t('buy-offer')}</h3>
          </div>

          <div className="flex flex-col items-center">
            <div className="text-6xl font-semibold text-black max-md:text-4xl">
              {formatPrice(data?.totalTTC - data?.autoConsumptionBonus, 'EUR')}
            </div>
            {data?.autoConsumptionBonus > 0 && (
              <div className="flex items-center justify-center gap-1 self-stretch text-ghost">
                <div className="text-lg">
                  {t('minus-autoconso', {
                    price: formatPrice(data?.autoConsumptionBonus, 'EUR'),
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-y-6 rounded-lg bg-grey3 p-6 text-black">
            {items.map((item, index) => (
              <Item key={index} text={item} />
            ))}
            <Button
              label={t('call-a-contractor-at')}
              onPress={() => {
                void 0;
              }}
              className={'justify-center bg-black !p-4 text-center text-white'}
            />
          </div>
          <DownloadStudy projectId={projectId} />
        </div>
      </div>
    </AnimatedPage>
  );
};

const LeasingResults = ({
  data,
  projectId,
}: {
  data: Quotation;
  projectId: string;
  cashPrice?: number | null;
  onChange: () => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync: createDeal } = useCreateDeal();
  const { isPdfGenerated, pdfUrl } = usePdf(projectId, SqsPdfAction.GENERATE_TEMPORARY_LEASING_PDF);

  const items = getItemsForLeasing(data, t);
  return (
    <AnimatedPage className="flex-1 items-stretch justify-stretch">
      <div className="flex w-full max-w-[600px] flex-1 flex-col gap-y-8 self-center overflow-hidden rounded-xl bg-white pb-8">
        <div className="flex w-full justify-center bg-primary px-2 py-4">
          <h2 className="text-lg text-white">
            <Trans
              i18nKey="economize-until"
              values={{
                price: formatPrice(data?.economy?.economy30yearsWithEcoGesture ?? 0, 'EUR'),
                year: 30,
              }}
              components={[<span key="0" className="font-semibold text-white" />]}
            />
          </h2>
        </div>
        <div className="flex flex-col items-center gap-y-4 px-8">
          <div className="rounded-full border border-primary px-3 py-2">
            <h3 className="text-lg text-primary">{t('location-offer')}</h3>
          </div>

          <div className="flex flex-col items-center">
            <div className="relative text-6xl font-semibold text-black max-md:text-4xl">
              {formatPrice(data?.monthlyRate ?? 0, 'EUR')}
              <span className="absolute bottom-[20px] left-full text-nowrap text-xl font-light text-black/80">
                {t('per-month')}
              </span>
            </div>
            <div className="flex items-center justify-center gap-1 self-stretch text-ghost">
              <div className="text-lg">+{formatPrice(data?.deposit ?? 0, 'EUR')}</div>
              <div className="text-sm">{t('warranty-deposit')}</div>
            </div>
          </div>
          <div className="flex flex-col gap-y-6 rounded-lg bg-grey3 p-6 text-black">
            {items.map((item, index) => (
              <Item key={index} text={item} />
            ))}
            <Button
              label={t('finalize-my-leasing-project')}
              onPress={() => {
                navigate('/location');
                void createDeal(projectId);
              }}
              PostIcon={ArrowRight}
              className={'justify-center bg-primary !p-4 text-center text-white'}
            />
          </div>
          <Button
            label={
              !isPdfGenerated
                ? t('your-contract-is-being-generated')
                : t('download-your-temporary-leasing-contract')
            }
            onPress={() => {
              window.open(pdfUrl as unknown as string, '_blank');
            }}
            PreIcon={(props) =>
              !isPdfGenerated ? <LoaderCircle {...props} /> : <Download {...props} />
            }
            isDisabled={!isPdfGenerated}
            className={'justify-center border border-black/80 bg-white !p-3 text-center text-black'}
          />
        </div>
      </div>
    </AnimatedPage>
  );
};

const Results = () => {
  const { t } = useTranslation();
  const { data } = useLocalProject();
  const solarSolution = data?.solarSolutions?.[0];
  const [isRent, setIsRent] = useState(true);

  const leasingSolution = solarSolution?.leasingSolutions?.[0];
  const cashSolution = solarSolution?.cashSolutions?.[0];
  const carportSolution = solarSolution?.carPortSolutions?.[0];

  return (
    <div className="flex w-full flex-col items-center justify-center gap-y-8">
      <CheckCircle className="size-12 text-white" />
      <h1 className="max-w-[555px] text-center text-4xl font-bold text-white">
        {t('results-of-your-simulation')}
      </h1>
      <p className="max-w-[512px] text-center text-base text-white">
        {t('here-is-the-optimal-solution-for-you')}
      </p>
      <div className="flex flex-col justify-center gap-4 lg:flex-row">
        {isRent && leasingSolution && (
          <div className="flex flex-1 flex-col items-stretch justify-stretch">
            <LeasingResults
              data={leasingSolution}
              projectId={data?._id ?? ''}
              cashPrice={cashSolution?.totalTTC}
              onChange={() => {
                setIsRent(false);
              }}
            />{' '}
          </div>
        )}

        {cashSolution ? (
          <div className="flex flex-1 flex-col items-stretch justify-stretch">
            <CashResults data={cashSolution} projectId={data?._id ?? ''} />
          </div>
        ) : null}
        {carportSolution ? (
          <div className="flex flex-1 flex-col items-stretch justify-stretch">
            <CashResults data={carportSolution} projectId={data?._id ?? ''} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default Results;
