import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { getQueryKey } from './simulation/queries';
import { trackError } from '@/services/error';

const _handleError = (...params: any[]) => {
  try {
    void trackError('API call issue', params);
  } catch (error) {
    console.error(error);
  }
};

/**
 * Handle errors from API and display snackbar
 */
const handleQueryError: QueryCache['config']['onError'] = (...params) => {
  try {
    _handleError(...params);
  } catch (error) {
    console.error(error);
  }
  return false;
};

/**
 * Handle errors from API and display snackbar
 * @param error
 * @param query
 * @returns
 */
const handleMutationError: MutationCache['config']['onError'] = (...params) => {
  try {
    _handleError(...params);
  } catch (error) {
    console.error(error);
  }
  return false;
};

export const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: handleMutationError,
  }),
  queryCache: new QueryCache({
    onError: handleQueryError,
  }),
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24 * 30, // 30 days
      retry: (failureCount, error: Error) => {
        try {
          const errMessage = JSON.parse(error?.message.replace('Error: Error: ', ''));
          if (errMessage?.status === 404) return false;
          if (errMessage?.status === 401) return false;
          if (errMessage?.status === 403) return false;
          if (errMessage?.status === 418) return false;
          else if (failureCount < 2) return true;
          else return false;
        } catch (error) {
          return false;
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

export const setQueryData = <T>(
  queryClient: QueryClient,
  queryKey: Parameters<typeof getQueryKey>,
  data: T
) => {
  if (!data || (data as any[])?.length === 0) return;
  queryClient.setQueryData(getQueryKey(...queryKey), (oldData: T) =>
    (data as any[]).length ? data : { ...oldData, ...data }
  );
};
