import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FunnelOperation } from '@/apis/location';
import { Button } from '@/components';
import { Phone, Sad } from '@/components/icons';

// t("error-contract-not-signed")
// t('error-payment-only-failed')
// t('error-payment-failed')
// t('error-mandat-failed')
// t('error-solvency-failed')
const MESSAGE_BY_OPERATION = {
  [FunnelOperation.CONTRACT_SIGNATURE]: 'error-contract-not-signed',
  [FunnelOperation.PAYMENT_ONLY]: 'error-payment-only-failed',
  [FunnelOperation.PAYMENT_FULL]: 'error-payment-failed',
  [FunnelOperation.MANDATE_ONLY]: 'error-mandat-failed',
  [FunnelOperation.SOLVENCY_CHECK]: 'error-solvency-failed',
};

const ErrorBoundary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const errorOperation = params.get('op') as FunnelOperation;
  const errorMessage = t(MESSAGE_BY_OPERATION[errorOperation]);
  return (
    <div className="flex w-full flex-col items-center justify-center gap-y-8 rounded-xl  p-4 ">
      <Sad className="size-60 self-center text-grey1/20" />
      <h1 className="max-w-[340px] text-center text-xl font-semibold text-grey1">
        {t('error-page-title')}
      </h1>
      <span className="p-2 text-center text-sm text-grey1">{errorMessage}</span>
      {errorOperation !== FunnelOperation.SOLVENCY_CHECK && (
        <span className="p-2 text-center text-sm text-grey1">{t('error-page-description')}</span>
      )}
      <div className="flex w-full justify-center gap-x-2">
        {errorOperation !== FunnelOperation.SOLVENCY_CHECK ? (
          <Button
            label={t('do-the-form-again')}
            onPress={() => {
              navigate('/');
            }}
            className={' bg-primary text-white'}
          />
        ) : (
          <Button
            label={t('go-back-home')}
            onPress={() => {
              window.localStorage.clear();
              window.open('https://www.soleriel.fr');
            }}
            className={' bg-primary text-white'}
          />
        )}
        <Button
          className=" bg-grey3 text-black"
          label="09 80 80 40 57"
          PreIcon={(props) => <Phone {...props} />}
          onPress={() => window.open(`tel:${t('soleriel-phone-number')}`)}
        />
      </div>
    </div>
  );
};

export default ErrorBoundary;
