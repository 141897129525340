import { motion } from 'framer-motion';
import { type PropsWithChildren } from 'react';
import { cn } from '@/helpers';

const AnimatedPage = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <motion.div
    className={cn('flex w-full flex-col gap-y-4', className)}
    initial={{ x: '10vw', opacity: 0, filter: 'blur(5px)' }}
    animate={{ x: '0vw', opacity: 1, filter: 'blur(0px)' }}
    exit={{ x: '-10vw', opacity: 0, filter: 'blur(5px)' }}
    transition={{
      type: 'spring',
      stiffness: 200,
      damping: 26,
      duration: 0.1,
    }}>
    {children}
  </motion.div>
);
export default AnimatedPage;
