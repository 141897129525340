// Provider to handle error messages with Snackbar

import { useEffect, useState, type PropsWithChildren } from 'react';
import { LoadingModal } from '@/components';

const showLoadingModal = (message: string) => {
  document.dispatchEvent(
    new CustomEvent<{ message: string }>('loadingModal', {
      detail: {
        message,
      },
    })
  );
};

const hideLoadingModal = () => {
  document.dispatchEvent(new CustomEvent('loadingModal', { detail: { message: '' } }));
};

export const LoadingModalWrapper = ({ children }: PropsWithChildren) => {
  const [loadingModalMessage, setLoadingModalMessage] = useState<string | null>(null);
  const [loadingModalVisible, setLoadingModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (loadingModalMessage) {
      setLoadingModalVisible(true);
    } else {
      setLoadingModalVisible(false);
    }
  }, [loadingModalMessage]);

  const handleEvent = (event: Event) => {
    const customEvent = event as CustomEvent;
    setLoadingModalMessage(customEvent.detail.message as string);
  };

  useEffect(() => {
    document.addEventListener('loadingModal', handleEvent as EventListener);
    // Remove event listener on unmount
    return () => {
      document.removeEventListener('loadingModal', handleEvent as EventListener);
    };
  }, []);

  return (
    <>
      {children}
      <LoadingModal isOpen={loadingModalVisible}>
        <h1 className="text-lg font-semibold text-primary">{loadingModalMessage}</h1>
      </LoadingModal>
    </>
  );
};

export const useLoadingModal = () => ({
  showLoadingModal,
  hideLoadingModal,
});
