import { useTranslation } from 'react-i18next';
import { Button } from '@/components';
import { Phone } from '@/components/icons';
import { cn } from '@/helpers';

const QuestionTile = ({
  showNumber,
  className,
  btnClassName,
}: {
  showNumber?: boolean;
  className?: string;
  btnClassName?: string;
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        'flex w-full flex-col items-center justify-center gap-6 rounded-xl border border-grey2 p-6',
        className
      )}>
      <h2 className="text-xl font-semibold">{t('got-a-question')}</h2>
      <p className="text-sm ">{t('your-project-manager-is-here-to-help')}</p>
      <Button
        className={cn(btnClassName ?? 'bg-secondary text-white')}
        label={showNumber ? t('soleriel-phone-number') : ''}
        PreIcon={(props) => <Phone {...props} />}
        onPress={() => window.open(`tel:${t('soleriel-phone-number')}`)}
      />
    </div>
  );
};
export default QuestionTile;
