import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ErrorWrapper from './providers/ErrorWrapper';
import Routes from './routes';
import {
  AnalyticsProvider,
  BootstrapWrapper,
  LoadingModalWrapper,
  MapProvider,
  QueryClientProvider,
  RouteProvider,
  SnackbarWrapper,
} from '@/providers';

function App() {
  return (
    <ErrorWrapper>
      <QueryClientProvider>
        <RouteProvider>
          <AnalyticsProvider>
            <BootstrapWrapper>
              <MapProvider>
                <SnackbarWrapper>
                  <LoadingModalWrapper>
                    <Routes />
                  </LoadingModalWrapper>
                </SnackbarWrapper>
              </MapProvider>
            </BootstrapWrapper>
          </AnalyticsProvider>
        </RouteProvider>

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorWrapper>
  );
}

export default App;
