/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/unbound-method */
import { Map as GoogleMap } from '@vis.gl/react-google-maps';
import { RidgePoints } from './RidgePoints';

const RidgeMap = ({
  center,
  roofPoints,
  defaultAzimuth,
  onCalculateOrientation,
}: {
  center: google.maps.LatLngLiteral;
  roofPoints: google.maps.LatLngLiteral[];
  defaultAzimuth: number;
  onCalculateOrientation?: ({
    azimuth,
    azimuthCardinal,
  }: {
    azimuth: number;
    azimuthCardinal: string;
  }) => void;
}) =>
  center ? (
    <>
      <GoogleMap
        key={`${center.lat}-${center.lng}`}
        className="size-full min-h-96 rounded-xl"
        defaultCenter={center}
        defaultZoom={20}
        disableDefaultUI={true}
        mapTypeId={'hybrid'}
        styles={[
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
        ]}
        mapTypeControl={false}
        keyboardShortcuts={false}
        tilt={0}
        zoomControl={true}
        scrollwheel={false}
        disableDoubleClickZoom={true}
        fullscreenControl={false}>
        <RidgePoints
          defaultAzimuth={defaultAzimuth}
          roofPoints={roofPoints}
          onCalculateOrientation={onCalculateOrientation}
        />
      </GoogleMap>
    </>
  ) : null;
export default RidgeMap;
