import { type ReactNode, type SVGProps } from 'react';

const Document = (
  props: SVGProps<SVGSVGElement> & {
    title: string;
    Icon: (props: any) => ReactNode;
  }
) => (
  <div className="flex min-w-[216px] flex-col items-center justify-start gap-4 rounded-t-md bg-white p-6">
    <props.Icon className="size-8 shrink-0 text-terciary" />
    <h2 className="text-xl font-semibold text-secondary first-letter:capitalize">{props.title}</h2>
    <div className="flex w-full flex-1 flex-col items-center gap-1">
      <div className="h-3 w-full rounded-full bg-grey3 " />
      <div className="h-3 w-[90%] rounded-full bg-grey3 " />
      <div className="h-3 w-full rounded-full bg-grey3 " />
      <div className="h-3 w-[95%] rounded-full bg-grey3 " />
      <div className="h-3 w-full rounded-full bg-grey3 " />
      <div className="h-3 w-[90%] rounded-full bg-grey3 " />
    </div>
  </div>
);
export default Document;
