import { useEffect, useState, type ComponentProps } from 'react';
import { ListBox, ListBoxItem, type Key, type Selection } from 'react-aria-components';
import { cn } from '@/helpers';
type Item = { name: string; id: string; description?: string };

const MultiSelect = (
  props: ComponentProps<typeof ListBox<Item>> & {
    selectedKeys: string[];
    onSelect: (id: Key[]) => void;
    errorMessage?: string;
  }
) => {
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set<string>());
  useEffect(() => {
    props.onSelect([...selectedKeys]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys]);
  return (
    <div className="relative">
      <ListBox
        {...props}
        selectedKeys={selectedKeys}
        onSelectionChange={(keys: Selection) => {
          setSelectedKeys(keys);
        }}
        selectionMode={'multiple'}
        className="flex flex-col gap-y-2">
        {(item: Item) => (
          <ListBoxItem
            {...item}
            aria-label={item.name}
            className={({ isSelected, isFocused, isHovered }) =>
              cn(
                'rounded-lg border border-solid  border-grey3 p-4 text-sm flex flex-col font-normal text-black outline-none cursor-pointer',
                {
                  'bg-primary text-white border-primary': isSelected ?? isFocused ?? isHovered,
                },
                {
                  'border-red-500': props?.errorMessage,
                }
              )
            }>
            <p className="text-sm font-medium">{item.name}</p>
            {item?.description && <p className={cn('text-xxs ')}>{item.description}</p>}
          </ListBoxItem>
        )}
      </ListBox>
      {props?.errorMessage && (
        <span className="absolute top-full ml-1 text-xxs leading-none text-red-500">
          {props?.errorMessage}
        </span>
      )}
    </div>
  );
};
export default MultiSelect;
