import { useTranslation } from 'react-i18next';
import { Auction, CheckCircle, Info, NewsPaper } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button, Document, InfoCard } from '@/components/ui';
import { formatPrice } from '@/helpers';
import { useLocalProject } from '@/services/project';

const Final = () => {
  const { t } = useTranslation();
  const { data } = useLocalProject();
  const firstLeasingOffer = data?.solarSolutions?.[0]?.leasingSolutions?.[0];

  return (
    <AnimatedPage>
      <div className="flex flex-col items-center justify-center">
        <CheckCircle className="size-9 text-terciary" />
        <h2 className="text-2xl font-semibold text-terciary">{t('your-order-is-validated')}</h2>
        <p className="text-base text-grey1">
          {t('solar-panels', { count: firstLeasingOffer?.panelNbr })} |{' '}
          {firstLeasingOffer?.installationPowerWc} Wc
        </p>
        {firstLeasingOffer?.monthlyRate ? (
          <div className="text-base font-medium text-grey1">
            {formatPrice(firstLeasingOffer?.monthlyRate, 'EUR')} {t('per-month')}
          </div>
        ) : null}
      </div>
      <hr className="w-full border-grey2" />
      <div className="flex flex-col items-center justify-center gap-6">
        <p className="text-xl font-medium text-black">{t('and-now')}</p>
        <InfoCard level="error" border={false}>
          <p className="text-sm font-medium text-black">{t('need-document-info')}</p>
        </InfoCard>
      </div>
      <div className="flex w-full flex-col items-center justify-center gap-10 rounded-lg border border-grey2 bg-grey3 lg:flex-row">
        <div className="flex max-h-40 justify-center overflow-hidden pt-4 lg:max-h-none lg:w-1/2">
          <Document Icon={Auction} title={t('mortgage-status')} />
        </div>
        <div className="flex flex-col items-center justify-center gap-1 p-4 ">
          <div className="flex flex-col justify-center">
            <div className="flex size-8 items-center justify-center self-center whitespace-nowrap rounded-full bg-grey2 text-sm font-semibold text-grey1">
              1
            </div>
            <div className="mt-2 w-full text-lg font-medium text-black">{t('mortgage-status')}</div>
            <div className="mt-1 w-full text-base text-zinc-400">{t('less-than-3-months')}</div>
          </div>

          <Button
            className="mt-4 w-full bg-white text-center text-black"
            label={t('how-to-obtain')}
            PostIcon={(props) => <Info {...props} />}
            onPress={() => {
              void 0;
            }}
          />
        </div>
      </div>

      <div className="flex w-full flex-col items-center justify-center gap-10 rounded-lg border border-grey2 bg-grey3 lg:flex-row">
        <div className="flex w-1/2 flex-col items-center justify-center gap-1 p-4">
          <div className="flex flex-col items-center justify-center">
            <div className="flex size-8 items-center justify-center self-center whitespace-nowrap rounded-full bg-grey2 text-sm font-semibold text-grey1">
              2
            </div>
            <div className="mt-2 text-center text-lg font-medium text-black">
              {t('civil-lease-for-each-owner')}
            </div>
            <div className="mt-1 w-full text-center text-base text-zinc-400">
              {t('we-sent-a-copy-for-each-owner')}
            </div>
          </div>
        </div>
        <div className="flex max-h-40 justify-center overflow-hidden pt-4 lg:max-h-none lg:w-1/2">
          <Document Icon={NewsPaper} title={t('civil-lease')} />
        </div>
      </div>
      <div className="flex flex-col pb-6">
        <div className="self-center text-xl font-semibold text-terciary">
          {t('send-us-your-documents')}
        </div>
        <div className="mt-10 flex justify-center gap-5 px-5 max-md:flex-wrap">
          <div className="my-auto flex flex-1 flex-col justify-center">
            <div className="self-center text-lg font-medium text-black">{t('by-email')}</div>
            <div className="mt-1 text-center text-sm text-zinc-400">
              {t('document-soleriel-email')}
            </div>
          </div>
          <div className="h-[73px] w-px shrink-0 border border-solid border-gray-200 bg-gray-200" />
          <div className="flex flex-1 flex-col justify-center">
            <div className="self-center text-lg font-medium text-black">{t('by-postal-mail')}</div>
            <div className="mt-1 flex flex-col items-center justify-center text-center text-sm text-zinc-400">
              <span>{t('soleriel-address-line1')}</span>
              <span>{t('soleriel-address-line2')}</span>
              <span>{t('soleriel-address-line3')}</span>
            </div>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
};
export default Final;
