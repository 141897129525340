import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { type DeepPartial, type InstallationData, type Project } from '@/apis/simulation';
import { useCreateProject, useGetProject } from '@/apis/simulation/hooks';
import { useSnackbar } from '@/providers';
import { useLoadingModal } from '@/providers/LoadingModalWrapper';

export type Simulation = InstallationData;

const useGetProjectFromUrl = (cb: (data: Project) => void) => {
  const { showLoadingModal, hideLoadingModal } = useLoadingModal();
  const { showSnackbar, hideSnackbar } = useSnackbar();
  const [params] = useSearchParams();
  const projectIdInUrl = params.get('projectId');

  const projectData = useGetProject(projectIdInUrl ?? '', {
    enabled: Boolean(projectIdInUrl),
  });
  useEffect(() => {
    if (projectData.isLoading) {
      showLoadingModal('Chargement du projet...');
    }
    if (projectData.isSuccess || projectData.isError) {
      hideLoadingModal();
      showSnackbar('Projet n°' + projectIdInUrl + ' chargé avec succès');
    }
    if (projectData.isError) {
      hideSnackbar();
      showSnackbar('Erreur lors du chargement du projet');
    }
  }, [
    projectData.isLoading,
    projectData.isSuccess,
    projectData.isError,
    showLoadingModal,
    hideLoadingModal,
    showSnackbar,
    hideSnackbar,
    projectIdInUrl,
  ]);
  useEffect(() => {
    if (projectData.data) {
      cb(projectData.data);
    }
  }, [cb, projectData.data]);
};

/**
 * Save and update project data in local storage
 * @returns
 */
export const useLocalProject = () => {
  const localProjectStorageKey = 'localProjectData';

  const queryClient = useQueryClient();
  const localProject = useQuery<Project>({
    queryKey: [localProjectStorageKey],
    queryFn: async () => {
      const localStorageData = window.localStorage.getItem(localProjectStorageKey);
      const data: Project = localStorageData ? JSON.parse(localStorageData) : null;
      return data;
    },
  });
  const mutate = useCallback(
    (data: Partial<Project>) => {
      queryClient.setQueryData([localProjectStorageKey], (oldData: Project) => {
        const newData = {
          ...oldData,
          ...data,
        };
        window.localStorage.setItem(localProjectStorageKey, JSON.stringify(newData));
        return newData;
      });
    },
    [queryClient]
  );
  const mutateInstallationData = (data: DeepPartial<InstallationData>) => {
    queryClient.setQueryData([localProjectStorageKey], (oldData: Project) => {
      const newData = {
        ...oldData,
        installationData: {
          ...oldData?.installationData,
          ...data,
        },
      };
      window.localStorage.setItem(localProjectStorageKey, JSON.stringify(newData));
      return newData;
    });
    return {
      data: localProject.data,
      installationData: localProject.data?.installationData,
      mutate,
      mutateInstallationData,
    };
  };

  useGetProjectFromUrl(mutate);
  return {
    data: localProject.data,
    installationData: localProject.data?.installationData,
    mutate,
    mutateInstallationData,
  };
};

/**
 * Return the project data if it exists in backend and save it in localStorage
 * @returns
 */
export const useInitProject = () => {
  const localProjectData = useLocalProject();
  const projectData = useGetProject(localProjectData.data?._id ?? '', {
    enabled: false,
  });

  const init = async () => {
    if (!localProjectData.data?._id) {
      return;
    }
    const savedProject = await projectData.refetch();
    if (savedProject?.data?._id) {
      localProjectData.mutate(savedProject.data);
    }
  };

  return init;
};

/** Submit simulationData to the backend
 * @returns
 */
export const useSubmitProject = () => {
  const [isSubmited, setIsSubmited] = useState(false);
  const { mutate: mutateLocalProjectData, data } = useLocalProject();
  const {
    mutateAsync: createProject,
    data: project,
    isPending: isCreatingProject,
  } = useCreateProject();

  const submit = async ({ simulationInput }: { simulationInput: InstallationData }) => {
    setIsSubmited(false);
    const response = await createProject({
      simulationInput,
      persons: data?.persons ?? [],
    });

    if (response) {
      mutateLocalProjectData(response);
    }
    setIsSubmited(true);
    return response;
  };
  return {
    submit,
    data: project,
    isSubmited,
    isPending: isCreatingProject,
  };
};
