import { type QueryKey } from '@tanstack/react-query';
import type * as queries from './queries';
import {
  type CreateProjectRequest,
  type CreateProjectResponse,
  type GetPdfGenerationStatusRequest,
  type GetPdfGenerationStatusResponse,
  type Project,
  type UpdateProjectLeasingContractRequest,
  type UpdateProjectLeasingContractResponse,
  type UpdateProjectPersonsRequest,
  type UpdateProjectPersonsResponse,
} from './models';

type QueryName = keyof typeof queries;

const API_URL = '/api/simulation';

const fetcher = async <R, V = object>(
  query: string,
  variables?: V,
  method: RequestInit['method'] = 'GET',
  isProtected: boolean = false
) =>
  await fetch(`${API_URL}/${query}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'x-protected': isProtected ? 'true' : 'false',
    },
    ...(variables ? { body: JSON.stringify(variables) } : {}),
  }).then(async (response) => {
    try {
      const json = await response.json().catch(() => ({}));
      if (response.ok) {
        return json as R;
      }
      throw new Error(JSON.stringify({ status: response.status, message: json }));
    } catch (error) {
      return await Promise.reject(error);
    }
  });

export const getQueryKey = (queryName: QueryName, ...variables: any[]) =>
  [queryName, ...variables] as QueryKey;

export const createProject = async (data: CreateProjectRequest) =>
  await fetcher<CreateProjectResponse, CreateProjectRequest>(`project`, data, 'POST');

export const getProject = async (projectId: string) =>
  await fetcher<Project>(`project/${projectId}`);

export const updateProjectPersons = async (projectId: string, data: UpdateProjectPersonsRequest) =>
  await fetcher<UpdateProjectPersonsResponse, UpdateProjectPersonsRequest>(
    `project/${projectId}/updatePersons`,
    data,
    'POST'
  );

export const updateProjectLeasingContract = async (
  projectId: string,
  data: UpdateProjectLeasingContractRequest
) =>
  await fetcher<UpdateProjectLeasingContractResponse, UpdateProjectLeasingContractRequest>(
    `project/${projectId}/updateLeasingContract`,
    data,
    'POST'
  );

export const getPdfGenerationStatus = async (
  projectId: string,
  data: GetPdfGenerationStatusRequest
) =>
  await fetcher<GetPdfGenerationStatusResponse, GetPdfGenerationStatusRequest>(
    `project/${projectId}/getPdfGenerationStatus?action=${data.action}`
  );

export const sendAlert = async (data: any) =>
  await fetcher<any, any>(`sendAlert`, data, 'POST', true);
