import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ConsumptionUnit, ElectricPurposeKey } from '@/apis';
import { InfoCard, ListSelect } from '@/components';
import { ArrowRight } from '@/components/icons';
import { AnimatedPage } from '@/components/layouts';
import { Button, TextField } from '@/components/ui';
import { type Simulation, useLocalProject } from '@/services/project';

type ConsumptionForm = {
  electricPurpose?: Simulation['electricPurpose'];
  annualElectricConsumption?: Simulation['electricConsumption']['annualElectricConsumption'];
  consumptionUnit?: Simulation['electricConsumption']['consumptionUnit'] | 'undefined';
};

const MIN_CONSUMPTION_KWH = 2400;
const MIN_CONSUMPTION_EURO = 1000;

const useDefaultValues = () => {
  const { installationData, mutateInstallationData } = useLocalProject();
  return {
    defaultValues: {
      electricPurpose: installationData?.electricPurpose,
      consumptionUnit: installationData?.electricConsumption?.consumptionUnit,
      annualElectricConsumption: installationData?.electricConsumption?.annualElectricConsumption,
    },
    mutateInstallationData,
  };
};

/**
 *
 * @param nextRoute1 - next route if consumptionUnit is undefined
 * @param nextRoute2 - next route if consumptionUnit is defined
 * @param previousRoute - previous route
 * @returns
 */
const Consumption = ({
  nextRoute1,
  nextRoute2,
  previousRoute,
}: {
  nextRoute1: string;
  nextRoute2: string;
  previousRoute: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { defaultValues, mutateInstallationData } = useDefaultValues();

  const { watch, setValue, formState, handleSubmit } = useForm<ConsumptionForm>({
    defaultValues,
    values: defaultValues,
    // @ts-expect-error - cant make it work
    resolver: yupResolver(
      yup.object().shape({
        electricPurpose: yup
          .string()
          .required(t('required-select'))
          .oneOf(Object.values(ElectricPurposeKey)),
        consumptionUnit: yup
          .string()
          .oneOf(['undefined', ...Object.values(ConsumptionUnit)])
          .when('electricPurpose', {
            is: ElectricPurposeKey.AUTO_CONSUMPTION,
            then: (schema) => schema.required(t('required-select')),
          }),

        annualElectricConsumption: yup.number().when('consumptionUnit', {
          is: 'undefined',
          otherwise: (schema) =>
            schema.when('electricPurpose', {
              is: ElectricPurposeKey.AUTO_CONSUMPTION,
              then: (schema) =>
                schema
                  .required(t('required'))
                  .when('consumptionUnit', {
                    is: ConsumptionUnit.KWH,
                    then: (schema) =>
                      schema.min(
                        MIN_CONSUMPTION_KWH,
                        `${t('unrealistic-consuption')}. ${t('check-your-consuption-value')}`
                      ),
                  })
                  .when('consumptionUnit', {
                    is: ConsumptionUnit.EURO,
                    then: (schema) =>
                      schema.min(
                        MIN_CONSUMPTION_EURO,
                        `${t('unrealistic-consuption')}. ${t('check-your-consuption-value')}`
                      ),
                  }),
            }),
        }),
      })
    ),
  });

  const onSubmit = (data: ConsumptionForm) => {
    mutateInstallationData({
      electricPurpose: data.electricPurpose,
      electricConsumption: {
        annualElectricConsumption: data.annualElectricConsumption ?? 0,
        consumptionUnit: data.consumptionUnit === 'undefined' ? undefined : data.consumptionUnit,
      },
    });
    if (
      data.consumptionUnit === 'undefined' &&
      data?.electricPurpose === ElectricPurposeKey.AUTO_CONSUMPTION
    ) {
      navigate(nextRoute1);
    } else {
      navigate(nextRoute2);
    }
  };

  return (
    <AnimatedPage>
      <h1 className="text-lg font-semibold text-black">{t('your-energy-consuption')}</h1>
      <h2 className="text-sm font-medium text-black ">{t('how-will-you-use-your-panels')}</h2>
      <InfoCard>
        <p className="mb-2 text-xs font-medium text-black">{t('autoconsuption-explanation')}</p>
        <p className="text-xs font-medium text-black">{t('resale-explanation')}</p>
      </InfoCard>
      <ListSelect
        aria-label={t('how-will-you-use-your-panels')}
        items={[
          {
            name: t('autoconsuption'),
            description: t('autoconsuption-description'),
            id: ElectricPurposeKey.AUTO_CONSUMPTION,
          },
          {
            name: t('resale'),
            description: t('resale-description'),
            id: ElectricPurposeKey.RESELL_ONLY,
          },
        ]}
        selectedKey={watch('electricPurpose')}
        onSelect={(id) => {
          setValue('electricPurpose', id as ConsumptionForm['electricPurpose'], {
            shouldValidate: true,
          });
        }}
        errorMessage={formState.errors.electricPurpose?.message}
      />
      {watch('electricPurpose') === ElectricPurposeKey.AUTO_CONSUMPTION && (
        <motion.div
          initial={{ y: '10px' }}
          animate={{ y: '0px' }}
          exit={{ y: '10px' }}
          className="flex flex-col gap-y-4">
          <h2 className="mt-2 text-sm font-medium text-black ">
            {t('your-annual-electric-consuption')}
          </h2>
          <ListSelect
            aria-label={t('your-annual-electric-consuption')}
            items={[
              { name: t('i-dont-know-it'), id: 'undefined' },
              { name: t('i-fill-my-consuption-in-kwh'), id: ConsumptionUnit.KWH },
              { name: t('i-fill-my-consuption-in-euro'), id: ConsumptionUnit.EURO },
            ]}
            selectedKey={watch('consumptionUnit') as ConsumptionUnit}
            onSelect={(id: string) => {
              setValue('consumptionUnit', id as ConsumptionForm['consumptionUnit'], {
                shouldValidate: true,
              });
            }}
            errorMessage={formState.errors.consumptionUnit?.message}
          />
          {watch('consumptionUnit') !== ('undefined' as ConsumptionUnit) && (
            <motion.div initial={{ y: '10px' }} animate={{ y: '0px' }}>
              <TextField
                aria-label={t('annual-electric-consuption')}
                placeholder={
                  watch('consumptionUnit') === ConsumptionUnit.EURO
                    ? t('i-fill-my-annual-bill')
                    : t('i-fill-my-annual-consuption')
                }
                type="number"
                defaultValue={String(watch('annualElectricConsumption'))}
                onChange={(value) => {
                  setValue('annualElectricConsumption', Number(value), {
                    shouldValidate: true,
                  });
                }}
                unit={watch('consumptionUnit') === ConsumptionUnit.KWH ? 'kWh' : '€'}
                isDisabled={!watch('consumptionUnit')}
                aria-errormessage={formState.errors?.annualElectricConsumption?.message}
              />
            </motion.div>
          )}
        </motion.div>
      )}

      <div className="flex w-full justify-between">
        <Button
          label={t('previous')}
          className={'mt-4 self-end bg-grey3 text-black'}
          onPress={() => {
            navigate(previousRoute);
          }}
        />
        <Button
          label={t('next-step')}
          className={'mt-4 self-end bg-primary text-white'}
          onPress={() => {
            void handleSubmit(onSubmit)();
          }}
          PostIcon={(props) => <ArrowRight {...props} />}
        />
      </div>
    </AnimatedPage>
  );
};
export default Consumption;
