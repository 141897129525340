import { type ComponentProps } from 'react';
import { type Key, ListBox, ListBoxItem, type Selection } from 'react-aria-components';
import { cn } from '@/helpers';

const ImageListSelect = (
  props: ComponentProps<typeof ListBox<{ name: string; id: string; img: string }>> & {
    selectedKey?: string;
    onSelect: (id: string) => void;
    errorMessage?: string;
  }
) => (
  <div className="relative">
    <ListBox
      {...props}
      selectionMode="single"
      selectedKeys={new Set([props.selectedKey]) as Set<Key>}
      onSelectionChange={(id: Selection) => {
        props.onSelect((id as Set<Key>)?.values().next().value as string);
      }}
      className="grid grid-cols-2 gap-2">
      {(item: { name: string; img: string }) => (
        <ListBoxItem
          aria-label={item.name}
          className={({ isSelected, isFocused, isHovered }) =>
            cn(
              'flex  flex-col justify-center overflow-hidden rounded-lg border border-solid border-grey3 text-sm font-normal text-black outline-none',
              {
                'bg-primary text-white border-primary': isSelected ?? isFocused ?? isHovered,
              },
              {
                'border-red-500 border-2': props?.errorMessage,
              }
            )
          }>
          <div
            className={cn('h-20 w-full')}
            style={{
              backgroundImage: `url(${item.img})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}></div>
          <span className="p-2 text-center text-xs">{item.name}</span>
        </ListBoxItem>
      )}
    </ListBox>
    {props?.errorMessage && (
      <span className="absolute top-full ml-1 mt-2 text-xxs leading-none text-red-500">
        {props?.errorMessage}
      </span>
    )}
  </div>
);
export default ImageListSelect;
