export function cn(...classNames: any[]) {
  const result: string[] = [];

  const extractClassName = (item: string | Record<string, any>) => {
    if (typeof item === 'string') {
      result.push(item.trim());
    } else if (typeof item === 'object' && item !== null && !(item instanceof Array)) {
      Object.keys(item).forEach((key: string) => {
        if (item[key]) result.push(key.trim());
      });
    }
  };

  for (const c of classNames) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (c instanceof Array) for (const k of c) extractClassName(k);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    else extractClassName(c);
  }

  return result.join(' ');
}
