import { type PropsWithChildren, useEffect } from 'react';
import { useInitProject } from '@/services/project';

const BootstrapWrapper = ({ children }: PropsWithChildren) => {
  const init = useInitProject();
  useEffect(() => {
    // Get server data if it exists
    void init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default BootstrapWrapper;
