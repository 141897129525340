import { GoogleMapsContext } from '@vis.gl/react-google-maps';
import { useContext, useEffect, useRef } from 'react';

export function Polygon(props: {
  path: google.maps.LatLngLiteral[];
  center?: google.maps.LatLngLiteral;
  onPathChange?: (path: google.maps.LatLngLiteral[]) => void;
  options?: google.maps.PolygonOptions;
}) {
  const map = useContext(GoogleMapsContext)?.map;
  const polygon = useRef(
    new google.maps.Polygon({
      fillColor: '#fff',
      strokeWeight: 2,
      fillOpacity: 0.2,
      strokeColor: '#00a6d6',
      strokeOpacity: 1,
      clickable: false,
      zIndex: 8,
      ...props.options,
    })
  );

  useEffect(() => {
    polygon.current.setMap(map!);
    polygon.current.setPath(props.path);

    return () => {
      polygon.current.setMap(null);
    };
  }, [props.path, map]);

  return null;
}
