import { type SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="currentColor"
      d="M9.334 9.502v5.165H2.667a5.333 5.333 0 0 1 6.667-5.165ZM8 8.667c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4Zm4 2.667v-2h1.334v2h2v1.333h-2v2H12v-2h-2v-1.333h2Z"
    />
  </svg>
);
export default SvgComponent;
