import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import Document from '../Document/Document';
import {
  ArrowRightUp,
  Bank,
  CheckCircle,
  CloseCircle,
  LoaderCircle,
  Phone,
} from '@/components/icons';

const SepaSignature = ({
  signatureStatus,
  mandatUrl,
}: {
  signatureStatus: 'signed' | 'not-signed' | undefined;
  depositStatus: 'deposited' | 'not-deposited';
  mandatUrl: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex w-full flex-col items-center justify-center gap-10 rounded-lg border border-grey2 bg-grey3 lg:flex-row">
      <div
        className="flex max-h-40 justify-center overflow-hidden pt-4 lg:max-h-none
lg:w-1/2">
        <Document Icon={Bank} title={t('direct-debit-mandat')} />
      </div>
      <div className="flex  flex-col items-center justify-center gap-1 p-4 ">
        {/*  <h2 className="text-base font-medium text-black/60">{t('warranty-deposit-of-260')}</h2>
        {depositStatus === 'deposited' ? (
          <>
            <p className="flex items-center gap-1 text-base font-medium text-[#52C100]">
              {t('paid')}
              <CheckCircle className="size-5 text-[#52C100]" />
            </p>
          </>
        ) : (
          <p className="flex items-center gap-1 text-base font-medium text-[#FF2020]">
            {t('not-paid')}
            <CloseCircle className="size-5 text-[#FF2020]" />
          </p>
        )} */}
        <h2 className="text-base font-medium text-black/60">{t('direct-debit-mandat')}</h2>

        {signatureStatus === 'signed' ? (
          <>
            <p className="flex items-center gap-1 text-base font-medium text-[#52C100]">
              {t('contract-signed')}
              <CheckCircle className="size-5 text-[#52C100]" />
            </p>
          </>
        ) : signatureStatus === 'not-signed' ? (
          <>
            <p className="flex items-center gap-1 text-base font-medium text-[#FF2020]">
              {t('contract-not-signed')}
              <CloseCircle className="size-5 text-[#FF2020]" />
            </p>
            <Button
              className="mt-4 w-full bg-black text-center text-white"
              label={t('sign-mandat')}
              PostIcon={(props) => <ArrowRightUp {...props} />}
              onPress={() => {
                mandatUrl();
              }}
            />
          </>
        ) : (
          <>
            <LoaderCircle className="size-5 text-primary" />
          </>
        )}
        <Button
          className="mt-4 w-full border border-grey2 bg-transparent text-center text-black"
          label={t('got-a-question-call-us')}
          PostIcon={(props) => <Phone {...props} />}
          onPress={() => {
            window.open(`tel:${t('soleriel-phone-number')}`);
          }}
        />
      </div>
    </div>
  );
};
export default SepaSignature;
