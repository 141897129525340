import { useEffect } from 'react';

const solerielHeadQuarter = { lat: 49.067819411465315, lng: 1.4254904767037633 };

export const useCalculateTravelFromSoleriel = (
  center?: google.maps.LatLngLiteral | null,
  onCalculate?: (props: { travelDistance: number; travelDuration: number }) => void
) => {
  useEffect(() => {
    if (!center || solerielHeadQuarter) {
      return;
    }
    const distanceService = new google.maps.DistanceMatrixService();
    void distanceService.getDistanceMatrix(
      {
        origins: [solerielHeadQuarter], // Siège Soleriel
        destinations: [center],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      (response, status) => {
        if (status === google.maps.DistanceMatrixStatus.OK) {
          onCalculate?.({
            travelDistance: response?.rows[0].elements[0].distance.value ?? 0,
            travelDuration: response?.rows[0].elements[0].duration.value ?? 0,
          });
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);
};
