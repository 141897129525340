import { type SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      fill="currentColor"
      d="M2.667 26.666h26.666v2.667H2.667v-2.667Zm2.666-10.667H8v9.334H5.333v-9.334ZM12 16h2.667v9.334H12v-9.334Zm5.333 0H20v9.334h-2.667v-9.334ZM24 16h2.667v9.334H24v-9.334ZM2.667 9.333 16 2.666l13.333 6.667v5.333H2.667V9.333ZM16 10.666a1.334 1.334 0 1 0 0-2.667 1.334 1.334 0 0 0 0 2.667Z"
    />
  </svg>
);
export default SvgComponent;
