import { type QueryKey } from '@tanstack/react-query';
import type * as queries from './queries';
import { type LocationProjectResponse } from './models';

type QueryName = keyof typeof queries;

const API_URL = '/api/location';

const fetcher = async <R, V = object>(
  query: string,
  variables?: V,
  method: RequestInit['method'] = 'GET'
) =>
  await fetch(`${API_URL}/${query}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    ...(variables ? { body: JSON.stringify(variables) } : {}),
  }).then(async (response) => {
    try {
      const json = await response.json().catch(() => ({}));
      if (response.ok) {
        return json as R;
      }
      throw new Error(JSON.stringify({ status: response.status, message: json }));
    } catch (error) {
      return await Promise.reject(error);
    }
  });

export const getQueryKey = (queryName: QueryName, ...variables: any[]) =>
  [queryName, ...variables] as QueryKey;

// All those are to be used only for location project
export const createDeal = async (projectId: string) =>
  await fetcher<LocationProjectResponse>(`project/${projectId}/create-deal`);

export const warmup = async () => {
  await fetcher<any>(`warmup`);
};

export const startFunnel = async (projectId: string) =>
  await fetcher<LocationProjectResponse>(`project/${projectId}/start-funnel`);

export const contractGenerated = async (projectId: string) =>
  await fetcher<LocationProjectResponse>(`project/${projectId}/contract-generated`);

export const leasingDealInfo = async (projectId: string) =>
  await fetcher<LocationProjectResponse>(`project/${projectId}/leasing-deal`);

export const signatureUrl = (projectId: string) => `${API_URL}/project/${projectId}/signature-url`;

export const mandatUrl = (projectId: string) => `${API_URL}/project/${projectId}/mandat-url`;

export const solvencyUrl = (projectId: string) => `${API_URL}/project/${projectId}/solvency-url`;

export const mortgage = async (queryString: string) =>
  await fetcher<LocationProjectResponse>(`/mortgage${queryString}`);
