import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect, useState } from 'react';

export const useCenter = (placeId?: string, defaultCenter?: google.maps.LatLngLiteral) => {
  const places = useMapsLibrary('places');

  const [center, setCenter] = useState<google.maps.LatLngLiteral | null>(defaultCenter ?? null);

  useEffect(() => {
    if (!places) {
      return;
    }
    const service = new places.PlacesService(document.createElement('div'));
    placeId &&
      service.getDetails(
        {
          placeId,
        },
        (place, status) => {
          if (status === places.PlacesServiceStatus.OK && place) {
            const center = {
              lat: place?.geometry?.location?.lat() ?? 0,
              lng: place?.geometry?.location?.lng() ?? 0,
            };
            setCenter(center);
          }
        }
      );
  }, [placeId, places]);
  return center;
};
