import { RotateLeft, RotateRight } from '@/components/icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '../Button/Button';
import { Arrow } from './Arrow';
import { CircleMarker } from './CircleMarker';
import { Rectangle } from './Rectangle';
import { useGetAzimuth } from './useGetAzimuth';

const azimuthToHeading = (azimuth: number) => {
  // Convert PVGIS azimuth to compass heading (0 to 360 degrees)
  let heading = azimuth + 270;
  // Normalize the heading to ensure it is within 0 to 360 degrees
  if (heading < 0) {
    heading += 360;
  } else if (heading >= 360) {
    heading -= 360;
  }
  return heading;
};

/**
 * Render the chosen shelter with proper size and orientation
 * @returns
 */
export const SolarShelter = ({
  defaultCenter,
  defaultAzimuth,
  height,
  width,
  onPointChange,
  onAzimuthChange,
}: {
  defaultCenter: google.maps.LatLngLiteral;
  defaultAzimuth: number;
  height?: number;
  width?: number;
  onPointChange?: (center: google.maps.LatLngLiteral) => void;
  onAzimuthChange?: (azimuth: { azimuth: number; azimuthCardinal: string }) => void;
}) => {
  const getAzimut = useGetAzimuth();
  const initialized = useRef(false);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>(defaultCenter);
  const [angle, setAngle] = useState<number>(0);
  const [path, setPath] = useState<google.maps.LatLngLiteral[]>([]);

  // Convert height from meters to degrees
  const h = height ? height / 111111 : 1;
  // Convert width from meters to degrees
  const w = width ? width / 111111 : 1;
  // Draw a rectangle with height and width. This rectangle is draggable and rotateable
  const arrowPath = useMemo(() => {
    if (path.length < 3 || !center) return [];
    return [
      {
        lat: path[1].lat + (path[2].lat - path[1].lat) / 2,
        lng: path[1].lng + (path[2].lng - path[1].lng) / 2,
      },
      center,
    ];
  }, [path, center]);

  useEffect(() => {
    if (!initialized.current && defaultAzimuth && center) {
      console.log(defaultAzimuth, azimuthToHeading(defaultAzimuth));
      setAngle(azimuthToHeading(defaultAzimuth));
      initialized.current = true;
    }
  }, [defaultAzimuth, center]);

  const handleRotate = useCallback(
    (direction: 'left' | 'right') => {
      if (angle === null) return;
      setAngle((prev) => (prev as number) + (direction === 'left' ? -3 : 3));
      if (path.length < 3) {
        return;
      }
      const azimuth = getAzimut([path[2], path[1]]);
      azimuth && azimuth.azimuth !== 90 && onAzimuthChange?.(azimuth);
    },
    [angle, getAzimut, onAzimuthChange, path]
  );

  const updatePath = useCallback(
    (newPath: google.maps.LatLngLiteral[]) => {
      setPath(newPath);
      console.log('Path changed', newPath);
    },
    [setPath]
  );
  return (
    <>
      <div className="absolute right-1 top-1 flex items-center gap-x-2">
        <Button
          className="bg-primary text-white"
          label={''}
          PreIcon={RotateLeft}
          onPress={() => handleRotate('left')}
        />
        <Button
          className="bg-primary text-white"
          label={''}
          PreIcon={RotateRight}
          onPress={() => handleRotate('right')}
        />
      </div>

      {arrowPath.length > 0 && <Arrow path={arrowPath} />}
      <CircleMarker
        center={center}
        draggable
        onDragEnd={(newCenter) => {
          onPointChange?.(newCenter);
        }}
        onDrag={setCenter}
        options={{
          fillColor: 'transparent',
          fillOpacity: 0,
          strokeColor: 'transparent',
          radius: 20,
        }}
      />

      {center && w && h && (
        <Rectangle angle={angle} width={w} height={h} center={center} onPathChange={updatePath} />
      )}
    </>
  );
};
