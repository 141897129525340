import { type SVGProps } from 'react';
const CheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    {...props}>
    <path
      fill="currentColor"
      d="M24 44C12.954 44 4 35.046 4 24S12.954 4 24 4s20 8.954 20 20-8.954 20-20 20Zm0-4a16 16 0 1 0 0-32 16 16 0 0 0 0 32Zm-1.994-8-8.486-8.486 2.828-2.828 5.658 5.658L33.318 15.03l2.83 2.828L22.006 32Z"
    />
  </svg>
);
export default CheckCircle;
