import { GoogleMapsContext } from '@vis.gl/react-google-maps';
import { useContext, useEffect, useRef } from 'react';

export function Line(props: { path: google.maps.LatLngLiteral[] }) {
  const map = useContext(GoogleMapsContext)?.map;
  const line = useRef(
    new google.maps.Polyline({
      strokeColor: '#f39200',
      strokeOpacity: 1,
      strokeWeight: 3,
      zIndex: 10,
    })
  );
  useEffect(() => {
    line.current.setMap(map!);
    line.current.setPath(props.path);
    return () => {
      line.current.setMap(null);
    };
  }, [props.path, map]);

  return null;
}
