import { type PropsWithChildren } from 'react';
import { cn } from '@/helpers';

const InfoCard = ({
  children,
  level = 'success',
  border = true,
}: PropsWithChildren<{
  level?: 'info' | 'success' | 'warning' | 'error';
  border?: boolean;
}>) => (
  <div
    className={cn('flex flex-row items-center gap-x-3 rounded-lg border-solid bg-grey3 p-4', {
      'border-2': border,
    })}>
    <div
      className={cn('w-5 h-5 p-2  rounded-full flex items-center justify-center text-white', {
        'bg-info': level === 'info',
        'bg-primary': level === 'warning',
        'bg-error': level === 'error',
        'bg-success': level === 'success',
      })}>
      !
    </div>
    <div className="flex-1 flex-col">{children}</div>
  </div>
);
export default InfoCard;
