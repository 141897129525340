import { GoogleMapsContext } from '@vis.gl/react-google-maps';
import { useContext, useEffect, useRef, useState } from 'react';

export function CircleMarker(props: {
  center: google.maps.LatLngLiteral;
  draggable?: boolean;
  onDrag?: (center: google.maps.LatLngLiteral) => void;
  onDragEnd?: (center: google.maps.LatLngLiteral) => void;
  onClick?: () => void;
  options?: google.maps.CircleOptions;
}) {
  const [interacted, setInteracted] = useState(false);
  const map = useContext(GoogleMapsContext)?.map;
  const defaultOptions = {
    fillColor: 'white',
    fillOpacity: 1,
    strokeColor: '#0087a9',
    strokeOpacity: 0.5,
    zIndex: 11,
    radius: window.innerWidth < 768 ? 2 : 1,
  };
  const circle = useRef<google.maps.Circle>(
    new google.maps.Circle({
      ...defaultOptions,
      ...props?.options,
    })
  );

  useEffect(() => {
    if (circle?.current?.getCenter?.()) return;
    circle.current.setMap(map!);
    circle.current.setCenter(props.center);

    circle.current.setDraggable(Boolean(props.draggable));
    circle.current.addListener('drag', () => {
      const pos = circle.current.getCenter()?.toJSON();
      props.onDrag && pos && props.onDrag(pos);
      setInteracted(true);
    });
    circle.current.addListener('dragend', () => {
      const pos = circle.current.getCenter()?.toJSON();
      setInteracted(false);
      props.onDragEnd && pos && props.onDragEnd(pos);
    });

    circle.current.addListener('click', () => {
      props.onClick && props.onClick();
      setInteracted(true);
    });

    return () => {
      circle?.current.setMap(null);
      circle?.current.unbindAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    let pulseInterval: NodeJS.Timeout | undefined;

    if (!interacted) {
      let increasing = true;
      let opacity = 0.5;
      let { radius } = defaultOptions;

      pulseInterval = setInterval(() => {
        if (increasing) {
          opacity += 0.05;
          radius += 0.02;
          if (opacity >= 1 || radius >= defaultOptions.radius + 2) {
            increasing = false;
          }
        } else {
          opacity -= 0.05;
          radius -= 0.02;
          if (opacity <= 0.5 || radius <= defaultOptions.radius) {
            increasing = true;
          }
        }
        if (circle.current) {
          circle.current.setOptions({ strokeOpacity: opacity, radius });
        }
      }, 100);
    } else if (pulseInterval) {
      clearInterval(pulseInterval);
      // Reset to default options after first interaction
      if (circle.current) {
        circle.current.setOptions({
          strokeColor: defaultOptions.strokeColor,
          strokeOpacity: defaultOptions.strokeOpacity,
          radius: defaultOptions.radius,
        });
      }
    }

    return () => {
      if (pulseInterval) {
        clearInterval(pulseInterval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interacted]);

  return null;
}
