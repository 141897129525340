import {
  computeDestinationPoint,
  getBounds,
  getCenter,
  getDistance,
  getGreatCircleBearing,
  isPointInPolygon,
} from 'geolib';

type Point = {
  latitude: number;
  longitude: number;
};

type Rectangle = {
  width: number; // meters
  height: number; // meters
};

type Polygon = {
  points: Point[];
};

/**
 * Finds the longest side of a polygon and returns its bearing in degrees and the points that form this side.
 *
 * @param {Polygon} polygon - The polygon
 * @returns {{ bearing: number, points: [Point, Point] }} The bearing of the longest side and the points
 */
function getLongestSide(polygon: Polygon): { bearing: number; points: [Point, Point] } {
  let longestDistance = 0;
  let bearing = 0;
  let longestPoints: [Point, Point] = [polygon.points[0], polygon.points[1]];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < polygon.points.length; i++) {
    const p1 = polygon.points[i];
    const p2 = polygon.points[(i + 1) % polygon.points.length];

    const distance = getDistance(p1, p2);
    if (distance > longestDistance) {
      longestDistance = distance;
      bearing = getGreatCircleBearing(p1, p2);
      longestPoints = [p1, p2];
    }
  }

  return { bearing, points: longestPoints };
}

/**
 * Rotates a point around a center by a given angle.
 * @param {Point} point - The point to rotate
 * @param {Point} center - The center to rotate around
 * @param {number} angle - The angle to rotate in degrees
 * @returns {Point} - The rotated point
 */
function rotatePoint(point: Point, center: Point, angle: number): Point {
  // Compute the new point after rotation using geolib
  return computeDestinationPoint(
    center,
    getDistance(center, point),
    getGreatCircleBearing(center, point) + angle
  );
}

/**
 * Translates a point by the given latitude and longitude offsets.
 * @param {Point} point - The point to translate
 * @param {number} offsetLat - The latitude offset
 * @param {number} offsetLng - The longitude offset
 * @returns {Point} - The translated point
 */
function translatePoint(point: Point, offsetLat: number, offsetLng: number): Point {
  return {
    latitude: point.latitude + offsetLat,
    longitude: point.longitude + offsetLng,
  };
}

/**
 * Rotates a polygon around its centroid by a given angle.
 * @param {Polygon} polygon - The polygon to rotate
 * @param {number} angle - The angle to rotate in degrees
 * @returns {Polygon} - The rotated polygon
 */
function rotatePolygon(polygon: Polygon, angle: number): Polygon {
  const centroid = getCenter(polygon.points);

  const rotatedPoints = polygon.points.map((point) => rotatePoint(point, centroid as Point, angle));
  // Translate the polygon to the left of 10 meters
  const translatedPoints = rotatedPoints.map((point) =>
    translatePoint(point, 13 / 111111, 23 / 111111)
  );
  return { points: translatedPoints };
}

/**
 * Generate a grid of rectangles inside a polygon. Rectangles are aligned with the longest side of the polygon.
 *
 * @param {Polygon} insidePolygon - The polygon to fill with rectangles
 * @param {Rectangle} rect - The size of the rectangles in meters
 * @returns {Object} An object containing the array of rectangles
 */
export function generateGrid(insidePolygon: Polygon, rect: Rectangle, maxRectNbr = 20) {
  if (insidePolygon.points.length < 3) {
    return { rectangles: [], colNbr: 0, lineNbr: 0 };
  }

  const { bearing: longestSideBearing } = getLongestSide(insidePolygon);
  const rotationAngle = (90 - longestSideBearing) % 360;

  // Rotate the polygon to make its longest side vertical
  const rotatedPolygon = rotatePolygon(insidePolygon, rotationAngle);

  // Calculate bounding box for the rotated polygon
  const bounds = getBounds(rotatedPolygon.points);

  const latStep = rect.height / 111111; // Convert height in meters to degrees latitude
  const lngStep =
    rect.width / (111111 * Math.cos(((bounds.minLat + bounds.maxLat) / 2) * (Math.PI / 180))); // Convert width in meters to degrees longitude based on average latitude

  const rectangles: Point[][] = [];
  let lineNbr = 0;
  let maxColsInAnyRow = 0;

  // Iterate over the rotated polygon's bounding box and place axis-aligned rectangles
  for (let lat = bounds.minLat; lat <= bounds.maxLat; lat += latStep) {
    let currentRowColNbr = 0;
    for (let lng = bounds.minLng; lng <= bounds.maxLng; lng += lngStep) {
      if (rectangles.length >= maxRectNbr) {
        break;
      }
      const rectCenter = { latitude: lat, longitude: lng };

      // Define the 4 corners of the rectangle (axis-aligned in the rotated space)
      const halfHeightDeg = rect.height / 2 / 111111;
      const halfWidthDeg = rect.width / 2 / (111111 * Math.cos(lat * (Math.PI / 180)));

      const rectangleCorners = [
        {
          latitude: rectCenter.latitude - halfHeightDeg,
          longitude: rectCenter.longitude - halfWidthDeg,
        },
        {
          latitude: rectCenter.latitude - halfHeightDeg,
          longitude: rectCenter.longitude + halfWidthDeg,
        },
        {
          latitude: rectCenter.latitude + halfHeightDeg,
          longitude: rectCenter.longitude + halfWidthDeg,
        },
        {
          latitude: rectCenter.latitude + halfHeightDeg,
          longitude: rectCenter.longitude - halfWidthDeg,
        },
      ];

      // Check if all corners are inside the rotated polygon
      if (rectangleCorners.every((corner) => isPointInPolygon(corner, rotatedPolygon.points))) {
        currentRowColNbr += 1;
        // Rotate the corners back to the original orientation
        const originalCorners = rectangleCorners;
        rectangles.push(originalCorners);
      }
    }
    if (currentRowColNbr > 0) {
      lineNbr += 1; // Increment row count for each non-empty row
      if (currentRowColNbr > maxColsInAnyRow) {
        maxColsInAnyRow = currentRowColNbr; // Track the maximum columns in any row
      }
    }
  }

  return { rectangles, rotatedPolygon, colNbr: maxColsInAnyRow, lineNbr };
}

const calculatePanelNumber = ({
  roofPoints,
  maxPanelNbr = 20,
  panel,
  roofAngle,
}: {
  roofPoints: google.maps.LatLngLiteral[];
  maxPanelNbr?: number;
  panel: { height: number; width: number };
  roofAngle: number;
}) => {
  const { rectangles, rotatedPolygon, colNbr, lineNbr } = generateGrid(
    { points: roofPoints.map((p) => ({ latitude: p.lat, longitude: p.lng })) },
    {
      height: panel.height * Math.cos((roofAngle * Math.PI) / 180),
      width: panel.width,
    },
    maxPanelNbr
  );

  return { panelNumber: rectangles.length, rectangles, rotatedPolygon, colNbr, lineNbr };
};

export default calculatePanelNumber;
