import { Outlet, useLocation } from 'react-router-dom';
import { Header, LocationStepsTile, ProgressBar, QuestionTile, RentResultTile } from '@/components';

const getStepByUrl = (url: string) => {
  if (url === '/location' || url.includes('/location/step-1')) return 0;
  if (url.includes('/location/step-2')) return 1;
  if (url.includes('/location/step-3')) return 2;
  if (url.includes('/location/step-4')) return 3;
  if (url.includes('/location/step-5')) return 4;
  if (url.includes('/location/step-6')) return 5;
  if (url.includes('/location/step-7')) return 6;
  if (url.includes('/location/step-8')) return 7;
  if (url.includes('/location/step-9')) return 8;
  if (url.includes('/location/step-10')) return 9;

  return 0;
};

const getLocationStepByUrl = (url: string) => {
  if (url === '/location') return 1;
  if (url.includes('/location/step-1')) return 1;
  if (url.includes('/location/step-2')) return 1;
  if (url.includes('/location/step-3')) return 2;
  if (url.includes('/location/step-4')) return 2;
  if (url.includes('/location/step-5')) return 2;
  if (url.includes('/location/step-6')) return 3;

  return 0;
};

const LocationLayout = () => {
  const location = useLocation();
  const currentStep = getStepByUrl(location.pathname);
  const locationStep = getLocationStepByUrl(location.pathname);
  const isError = location.pathname.includes('error');
  return (
    <>
      <div className="flex h-screen flex-col items-center overflow-y-scroll bg-grey3 pb-12 lg:gap-y-8 ">
        <Header />
        <div className="hidden w-full flex-row justify-center gap-4 px-4 lg:flex">
          <div className="flex w-2/3 flex-col gap-y-4 overflow-y-scroll">
            <div className="flex flex-col gap-y-4  rounded-2xl border border-grey2 bg-white p-6">
              {!isError && <ProgressBar totalSteps={5} currentStep={currentStep} />}
              <Outlet />
            </div>
            {!isError && <LocationStepsTile selected={locationStep} />}
            <QuestionTile />
          </div>
          <RentResultTile />
        </div>
        <div className="flex w-full flex-col gap-y-4 overflow-y-scroll lg:hidden">
          <RentResultTile />
          <div className="flex flex-col gap-y-4 px-2">
            <div className="flex flex-col gap-y-4  rounded-2xl border border-grey2 bg-white p-6">
              {!isError && <ProgressBar totalSteps={5} currentStep={currentStep} />}
              <Outlet />
            </div>
            {!isError && <LocationStepsTile selected={locationStep} />}
            <QuestionTile />
          </div>
        </div>
      </div>
    </>
  );
};
export default LocationLayout;
