import { Outlet } from 'react-router-dom';
import { houseBg } from '@/assets';
import { Header } from '@/components';

const ResultLayout = () => (
  <div className="flex h-screen flex-col overflow-x-hidden">
    <Header />
    <div
      className="flex flex-1 flex-col px-6 py-14"
      style={{
        backgroundImage: `url(${houseBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <Outlet />
    </div>
  </div>
);
export default ResultLayout;
