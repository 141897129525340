/* eslint-disable func-names */
// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e: { preventDefault: () => void }) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e: { keyCode?: any; preventDefault: () => void }) {
  if ((keys as Record<number, number>)[e.keyCode]) {
    e && preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
  window.addEventListener(
    'load',
    () => {},
    Object.defineProperty({}, 'passive', {
      get: function () {
        supportsPassive = true;
      },
    })
  );
} catch (e) {}

const wheelOpt = supportsPassive ? { passive: false } : false;
const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

export function disableScroll() {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
export function enableScroll() {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(
    wheelEvent,
    (e) => {
      preventDefault(e);
    },
    wheelOpt as EventListenerOptions
  );
  window.removeEventListener('touchmove', preventDefault, wheelOpt as EventListenerOptions);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}
